import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import Cargando from "../comunes/Cargando";
import Button from "react-bootstrap/Button";
import { useForm } from "react-hook-form";
import { Alert, Col, Form } from "react-bootstrap";
import { getErrorMessage, handleError } from "services/api";
import { FaFileWord, FaSave, FaTimes, FaUndo } from "react-icons/fa";
import { TiDelete, TiInputCheckedOutline } from "react-icons/ti";
import { VscOpenPreview } from "react-icons/vsc";
import { IoMdArrowRoundBack } from "react-icons/io";
import { RiMailSendLine } from "react-icons/ri";
import { FcWorkflow } from "react-icons/fc";
import CertMasterService from "services/CertMasterService";
import ErrorMessage from "components/comunes/ErrorMessage";
import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";
import { saveAs } from 'file-saver';
import { dateToCode, dateToLocalDateString, padLeft } from "utiles/FormatHelper";
import { Acciones, EstadoCertTransaccion, FormatoCertTransaccion, NormaCertMaster, traerColorEstadoCertTransaccion, traerEstadosCertTransaccion, traerFormatoCertTransaccion, traerMediosTransporte } from "utiles/Constantes";
import CertTransaccionService from "services/CertTransaccionService";
import ArchivoSelector from "components/comunes/ArchivoSelector";
import { isAllowed } from "hooks/Auth";
import { AuthContext } from "context/AuthContext";
import DatePicker from "components/comunes/DatePicker";
import CertTransaccionEstados from "./CertTransaccionEstados";
import Visor from "components/comunes/Visor";

export default function CertTransaccionForm({
    edicionId = 0,
    cerrar,
    consulta = false,
    paisId = 0,
    operadorId = 0,
    formato
}) {
    const [loading, setLoading] = useState(false);
    const [procesando, setProcesando] = useState(false);
    const [id, setId] = useState(edicionId);
    const [Formato, setFormato] = useState(formato);
    const [anulado, setAnulado] = useState(false);
    const [estado, setEstado] = useState(EstadoCertTransaccion.EnElaboracion);
    const { register, errors, handleSubmit, control, reset, setError, getValues, setValue } = useForm();
    const [mensaje, setMensaje] = useState("");
    const [readOnly, setReadOnly] = useState(consulta);
    const [listaCertMaster, setListaCertMaster] = useState([{ id: 0, numcertificado: "Cargando.." }]);
    const { user } = useContext(AuthContext);
    const estados = traerEstadosCertTransaccion();
    const formatos = traerFormatoCertTransaccion();
    const mediosTransporte = traerMediosTransporte();
    const [mostrarHistEstados, setMostrarHistEstados] = useState(false);
    const [mostrarBorrador, setMostrarBorrador] = useState(false);
    const [urlBorrador, setUrlBorrador] = useState(null);
    const [listoEnvio, setListoEnvio] = useState(false);
    const [corregido, setCorregido] = useState(false);
    const [notas, setNotas] = useState("");

    useEffect(() => {
        let cancelToken = axios.CancelToken.source();
        if (id === 0) {
            reset({ pais_id: paisId, cert_master_id: 0, formato: formato, distribucion: true });
            let norma = '';
            if (Formato === FormatoCertTransaccion.COI)
                norma = NormaCertMaster.ComunidadEconomiaEuropea;
            else if (Formato === FormatoCertTransaccion.FD2)
                norma = NormaCertMaster.EstadosUnidosAmerica;
            else if (Formato === FormatoCertTransaccion.FD3)
                norma = NormaCertMaster.Nacional;

            CertMasterService.traerListaVigentePorOperadorId(operadorId, norma, cancelToken)
                .then((lista) => {
                    if (lista !== undefined) {
                        if (lista.length === 0) {
                            alert('No tiene Certificado Máster Vigente para el formato ' + Formato);
                            cerrar(false);
                        }
                        else {
                            setListaCertMaster(lista);                            
                        }
                    }
                })
                .catch((err) => {
                    if (!axios.isCancel(err)) {
                        handleError(err);
                        setLoading(false);
                        setReadOnly(true);
                        setMensaje(getErrorMessage(err));
                    }
                });

        }
        else {
            setMensaje('Cargando Certificado...');
            setLoading(true);
            if (!isAllowed(user, Acciones.Transacciones.CertTransaccion.Editar))
                setReadOnly(true);
            CertTransaccionService.traerCertTransaccion(edicionId, cancelToken)
                .then((result) => {
                    if (result !== undefined) {
                        if (result !== undefined) {
                            reset(result);
                            setListaCertMaster([result.cert_master]);
                            setFormato(result.formato);
                            setEstado(result.estado);
                            setNotas(result.notas);
                            setCorregido(result.corregido);
                            setListoEnvio(result.listo_envio);
                            switch (result.estado) {
                                case EstadoCertTransaccion.EnElaboracion:
                                    if (!isAllowed(user, Acciones.Transacciones.CertTransaccion.Enviar)) {
                                        setReadOnly(true);
                                    }
                                    break;
                                case EstadoCertTransaccion.Enviado:
                                    if (!isAllowed(user, Acciones.Transacciones.CertTransaccion.GenerarBorrador)) {
                                        setReadOnly(true);
                                    }
                                    break;
                                case EstadoCertTransaccion.EnBorador:
                                    if (!isAllowed(user, Acciones.Transacciones.CertTransaccion.Confirmar)) {
                                        setReadOnly(true);
                                    }
                                    break;
                                case EstadoCertTransaccion.Confirmado:
                                    if (!isAllowed(user, Acciones.Transacciones.CertTransaccion.Emitir)) {
                                        setReadOnly(true);
                                    }
                                    break;
                                case EstadoCertTransaccion.Anulado:
                                    setReadOnly(true);
                                    setAnulado(true);
                                    break;
                                case EstadoCertTransaccion.Emitido:
                                    setReadOnly(true);
                                    break;
                                default:
                            }
                        } else {
                            setMensaje(`Certificado con Id '${edicionId}' no ha sido encontrado`);
                            setReadOnly(true);
                        }
                    }

                    setMensaje('');
                    setLoading(false);
                })
                .catch((err) => {
                    if (!axios.isCancel(err)) {
                        handleError(err);
                        setLoading(false);
                        setReadOnly(true);
                        setMensaje(getErrorMessage(err));
                    }
                });
        }
        return () => {
            cancelToken.cancel("Carga de Operador cancelada por el usuario.");
        };
    }, [reset, edicionId, paisId, id, operadorId, user]);


    const guardar = async (data) => {
        try {
            if (data.cert_master_id === 0) {
                setMensaje('Seleccione un Certificado Máster');
                return null;
            }
            setProcesando(true);
            setMensaje("Guardando...");
            let newCert = null;
            if (id !== 0) {
                data.id = id;
                newCert = await CertTransaccionService.modificar(data);
            } else {
                data.pais_id = paisId;
                data.operador_id = operadorId;
                data.formato = formato;
                newCert = await CertTransaccionService.agregar(data);
                if (!!newCert) setId(newCert.id);
            }

            setMensaje("");
            return newCert;
        }
        catch (err) {
            setMensaje(getErrorMessage(err));
            if (err?.response?.data) {
                for (let field in err.response.data) {
                    setError(field, { message: err.response.data[field] });
                }
            }
        }
        finally {
            setProcesando(false);
        }
    };
    const generarCertificado = async (certificado) => {
        try {
            if (!certificado) return;

            if (Formato !== FormatoCertTransaccion.COI) {
                setProcesando(true);
                setMensaje("Generando Certificado...");
                let plantilla = process.env.PUBLIC_URL + `/${Formato}.docx`;

                PizZipUtils.getBinaryContent(plantilla, async function (
                    error,
                    content
                ) {
                    if (error) {
                        throw error;
                    }

                    var zip = new PizZip(content);
                    var doc = new Docxtemplater().loadZip(zip);

                    // valores calculados
                    if (certificado.fechaemision)
                        certificado.fechaemision = dateToCode(certificado.fechaemision);

                    certificado.num1 = padLeft(certificado.num1, 3);
                    certificado.num2 = padLeft(certificado.num2, 3);
                    doc.setData(certificado);
                    try {
                        // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
                        doc.render();
                    } catch (error) {
                        setProcesando(false);
                        setMensaje("");
                        // The error thrown here contains additional information when logged with JSON.stringify (it contains a properties object containing all suberrors).
                        function replaceErrors(key, value) {
                            if (value instanceof Error) {
                                return Object.getOwnPropertyNames(value).reduce(function (
                                    error,
                                    key
                                ) {
                                    error[key] = value[key];
                                    return error;
                                },
                                    {});
                            }
                            return value;
                        }
                        console.log(JSON.stringify({ error: error }, replaceErrors));

                        if (error.properties && error.properties.errors instanceof Array) {
                            const errorMessages = error.properties.errors
                                .map(function (error) {
                                    return error.properties.explanation;
                                })
                                .join("\n");
                            console.log("errorMessages", errorMessages);
                            // errorMessages is a humanly readable message looking like this :
                            // 'The tag beginning with "foobar" is unopened'
                        }

                        throw error;
                    }
                    var out = doc.getZip().generate({
                        type: "blob",
                        mimeType:
                            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    }); //Output the document using Data-URI

                    saveAs(out, `${certificado.numcertificado}.docx`);

                    setMensaje("Certificado generado con éxito.");

                    setProcesando(false);

                    //Cierra pantalla de edición
                    cerrar(true);
                });
            }
            else {
                alert('El cerficado COI se genera fuera del sistema.')
            }

        }
        catch (err) {
            alert('El certificado COI se genera fuera del sistema.');
        }
    };
    const abrirVisorBorrador = async () => {
        if (Formato === FormatoCertTransaccion.COI) {
            alert('No se muestra borrador de COI');
            return;
        }
        setProcesando(true);
        setMensaje('Creando borrador...')
        let certTransaccion = await CertTransaccionService.traer(id);
        if (!!certTransaccion) {
            await generarBorrador(certTransaccion, true);
        }
        setProcesando(false);
    };
    const generarBorrador = async (certificado, visor = false) => {
        try {
            if (!certificado) return;

            setProcesando(true);
            if (certificado.formato !== FormatoCertTransaccion.COI) {
                setMensaje("Generando Borrador...");
                let plantilla = process.env.PUBLIC_URL + `/${certificado.formato}_BORRADOR.docx`;

                PizZipUtils.getBinaryContent(plantilla, async function (
                    error,
                    content
                ) {
                    if (error) {
                        throw error;
                    }

                    var zip = new PizZip(content);
                    var doc = new Docxtemplater().loadZip(zip);

                    // valores calculados
                    if (certificado.fechaemision)
                        certificado.fechaemision = dateToLocalDateString(certificado.fechaemision);

                    doc.setData(certificado);
                    try {
                        // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
                        doc.render();
                    } catch (error) {
                        setProcesando(false);
                        setMensaje("");
                        // The error thrown here contains additional information when logged with JSON.stringify (it contains a properties object containing all suberrors).
                        function replaceErrors(key, value) {
                            if (value instanceof Error) {
                                return Object.getOwnPropertyNames(value).reduce(function (
                                    error,
                                    key
                                ) {
                                    error[key] = value[key];
                                    return error;
                                },
                                    {});
                            }
                            return value;
                        }
                        console.log(JSON.stringify({ error: error }, replaceErrors));

                        if (error.properties && error.properties.errors instanceof Array) {
                            const errorMessages = error.properties.errors
                                .map(function (error) {
                                    return error.properties.explanation;
                                })
                                .join("\n");
                            console.log("errorMessages", errorMessages);
                            // errorMessages is a humanly readable message looking like this :
                            // 'The tag beginning with "foobar" is unopened'
                        }

                        throw error;
                    }
                    var blob = doc.getZip().generate({
                        type: "blob",
                        mimeType:
                            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    }); //Output the document using Data-URI


                    if (!!visor) {
                        let reader = new FileReader();

                        reader.onload = function () {
                            const previewFIlePathUrl = reader.result;

                            setUrlBorrador(previewFIlePathUrl);
                            setMostrarBorrador(true);
                            setMensaje('');
                        };

                        reader.readAsDataURL(blob);

                        return;
                    }

                    // //se envía a borrador
                    // await CertTransaccionService.borrador(certificado.id);

                    saveAs(blob, `${certificado.formato}_${certificado.cod_pais}_${certificado.cod_operador}_Borrador.docx`);

                    setMensaje("Borrador Generado.");

                    setProcesando(false);

                    //Cierra pantalla de edición
                    cerrar(true);
                });
            }
            else {
                //sólo se marca como emitido si está en elaboración
                await CertTransaccionService.borrador(certificado.id);

                setMensaje("Actualizado estado a En Borrador.");

                setProcesando(false);

                //Cierra pantalla de edición
                cerrar(true);
            }

        }
        catch (err) {
            setMensaje(getErrorMessage(err));
            if (err?.response?.data) {
                for (let field in err.response.data) {
                    setError(field, { message: err.response.data[field] });
                }
            }

            setProcesando(false);
        }
    };
    const onSubmit = async (data, e) => {
        let certTransaccion;
        try {
            switch (e.target.id) {
                case 'btnGuardar':
                case 'svgGuardar':
                    
                    if (!! await guardar(data)) {
                        if (estado === EstadoCertTransaccion.Confirmado
                            && !!data.certificado_id && !!data.fechaemision) {
                            await emitir(data.certificado_id);
                            alert('Se ha actualizado el estado del Certififcado a \'Emitido\'');
                        }
                        if (estado === EstadoCertTransaccion.Enviado) {
                            if(!!data.borrador_id && (!notas || !!corregido) ){
                               //se envía a borrador
                               await pasarBorrador(data);
                            }
                        }
                        cerrar(true);
                    }
                    break;
                case 'btnEnviar':
                case 'svgEnviar':
                    if (!window.confirm('¿Está seguro que desea enviar a Biolatina?')) return;

                    certTransaccion = await guardar(data);
                    if (!!certTransaccion) {
                        if(estado === EstadoCertTransaccion.EnElaboracion)
                            await enviar(certTransaccion);
                        else if(estado === EstadoCertTransaccion.EnBorador)
                            await corregirBorrador();
                        cerrar(true);
                    }
                    break;
                case 'btnRegresarEE':
                case 'svgRegresarEE':
                    if (!window.confirm('¿Está seguro que desea regresarlo a enlaboración?')) return;

                    //si está regresando es porque hay correcciones pendientes.
                    data.corregido = false;

                    certTransaccion = await guardar(data);
                    if (!!certTransaccion) {
                        regresarEE(certTransaccion);
                        cerrar(true);
                    }
                    break;
                case 'btnGenerarBorrador':
                case 'svgGenerarBorrador':
                    if (!!getValues('notas') && !getValues('corregido')) {
                        alert('Debe confirmar que ya se han completado las correcciones');
                        return;
                    }

                    if (!window.confirm('¿Está seguro que desea Generar el Borrador?')) return;

                    certTransaccion = await guardar(data);
                    if (!!certTransaccion) {
                        generarBorrador(certTransaccion);
                        cerrar(true);
                    }
                    break;
                case 'btnConfirmar':
                case 'svgConfirmar':
                    if (!window.confirm('¿Está seguro que desea Confirmar el Borrado?')) return;

                    certTransaccion = await guardar(data);
                    if (!!certTransaccion) {
                        confirmar(certTransaccion);
                    }
                    break;
                case 'btnGenerar':
                case 'svgGenerar':
                    // si no se ha generado el número, se procede a generar
                    if (data.numcertificado === '' || data.numcertificado === undefined) {
                        data.id = id;
                        certTransaccion = await generarNumcertificado(data);
                    }

                    // si es COI no se genera el documento
                    if (Formato === FormatoCertTransaccion.COI) {
                        alert('El número de certificado ya se ha generado.');
                        return;
                    }

                    // si todavía no se ha guardado el certificado, se guarda y obtiene la última versión
                    if (!certTransaccion) {
                        certTransaccion = await guardar(data);
                    }

                    // si se logró obtener el certificado  se genera
                    if (!!certTransaccion) {
                        generarCertificado(certTransaccion);
                    }
                    break;
                default:
            }
        }
        catch (error) {
            console.error(error);
        }
    };

    const anular = async (e) => {
        try {
            e.preventDefault();
            setProcesando(true);
            let razon = window.prompt('Razón de la anulación:');
            if (razon) {
                setMensaje('Anulando...');
                setValue('estado', EstadoCertTransaccion.Anulado);
                setValue('razonnulacion', razon);

                //intenta guardar
                if (await CertTransaccionService.anular(id, razon)) {
                    cerrar(true);
                }
                else {
                    //si no logra guardar, deshace los cambios
                    setValue('estado', EstadoCertTransaccion.EnElaboracion);
                    setValue('razonnulacion', '');
                    setMensaje('No se pudo anular,  consulte con el adminsitrador.');
                }
            }
        }
        catch (err) {
            setMensaje(getErrorMessage(err));
            //deshace los cambios
            setValue('estado', EstadoCertTransaccion.EnElaboracion);
            setValue('razonnulacion', '');
        }
        finally {
            setProcesando(false);
        }
    }
    
    const corregirBorrador = async () => {
        try {
            setProcesando(true);
            let correccion = window.prompt('Describa la corrección a realizar:');
            if (correccion) {
                setMensaje('Enviando a corrección...');

                //intenta enviar
                if (await CertTransaccionService.corregirBorrador(id, correccion)) {
                    cerrar(true);
                }
                else {
                    //si no logra enviar, avisa
                    setMensaje('No se pudo enviar a corrección,  consulte con el adminsitrador.');
                }
            }
        }
        catch (err) {
            setMensaje(getErrorMessage(err));
            //deshace los cambios
            setValue('estado', EstadoCertTransaccion.EnElaboracion);
            setValue('razonnulacion', '');
        }
        finally {
            setProcesando(false);
        }
    }
    const enviar = async (cert) => {
        try {
            setProcesando(true);

            setMensaje('Enviando...');

            //intenta enviar
            await CertTransaccionService.enviar(cert.id);

        }
        catch (err) {
            handleError(err);
            setMensaje(getErrorMessage(err));
        }
        finally {
            setProcesando(false);
        }
    }
    const pasarBorrador = async (cert) => {
        try {
            setProcesando(true);

            setMensaje('Pasando a Borrador...');

            await CertTransaccionService.borrador(cert.id);
            alert('Se ha actualizado el estado del Certififcado a \'En Borrador\'');

        }
        catch (err) {
            handleError(err);
            setMensaje(getErrorMessage(err));
        }
        finally {
            setProcesando(false);
        }
    }
    const regresarEE = async (cert) => {
        try {
            setProcesando(true);

            setMensaje('Regresando a En Elaboración...');

            //intenta enviar
            await CertTransaccionService.regresarEE(cert.id);

        }
        catch (err) {
            handleError(err);
            setMensaje(getErrorMessage(err));
        }
        finally {
            setProcesando(false);
        }
    }
    const confirmar = async (cert) => {
        try {
            setProcesando(true);

            setMensaje('Confirmando...');

            //intenta confirmar
            await CertTransaccionService.confirmar(cert.id);
            cerrar(true);

        }
        catch (err) {
            handleError(err);
            setMensaje(getErrorMessage(err));
        }
        finally {
            setProcesando(false);
        }
    }
    const generarNumcertificado = async (cert) => {
        try {
            setProcesando(true);

            setMensaje('Generando número de Certificado...');

            //intenta Emitir
            cert = await CertTransaccionService.generarNumcertificado(cert);

            setMensaje(`Número de Certificado '${cert.numcertificado}'generado con éxito`);
            setValue('numcertificado', cert.numcertificado);
            return cert;
        }
        catch (err) {
            handleError(err);
            setMensaje(getErrorMessage(err));
        }
        finally {
            setProcesando(false);
        }
    }
    const emitir = async (archivo_id) => {
        try {
            setProcesando(true);

            setMensaje('Cambiando estado a Emitido...');

            //intenta Emitir
            await CertTransaccionService.emitir(id, archivo_id);
            cerrar(true);
        }
        catch (err) {
            handleError(err);
            setMensaje(getErrorMessage(err));
        }
        finally {
            setProcesando(false);
        }
    }
    const validarPeso = (v) => {
        if (listoEnvio && parseInt(v) <= 0) return "Debe ser mayor que cero (0).";
    }
    // console.log({
    //     estado,
    //     notas,
    //     listoEnvio,
    //     corregido
    // })
    return (
        <>
            {!!notas && notas.length > 0  &&  estado === EstadoCertTransaccion.EnElaboracion &&
                <>
                    <Alert variant="warning">
                        {notas.split('\n').map((item, i) => {
                            return <p key={i}>{item}</p>;
                        })}
                    </Alert>
                </>}
            <Form>
                <Form.Row>
                    <Col xs={12} md={2}>
                        <Form.Group controlId="formato">
                            <Form.Label>Formato:</Form.Label>
                            <Form.Control
                                name="formato"
                                as="select"
                                ref={register()}
                                disabled={true}
                                size="sm"
                            >
                                {Object.keys(formatos).map((e) => <option key={e} value={e}>{formatos[e]}</option>)}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={4}>
                        <Form.Group controlId="cert_master_id">
                            <Form.Label>Certificado Máster:</Form.Label>
                            <Form.Control
                                name="cert_master_id"
                                as="select"
                                ref={register({ required: listoEnvio && "Seleccione un Certificado" })}
                                disabled={readOnly}
                                size="sm"
                            >
                                {listaCertMaster.map((c) => <option key={c.id} value={c.id}>{c.numcertificado}</option>)}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={4}>
                        <Form.Group controlId="numcertificado">
                            <Form.Label>Número de Certificado:</Form.Label>
                            <Form.Control
                                name="numcertificado"
                                type="text"
                                ref={register()}
                                readOnly={true}
                                size="sm"
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={2}>
                        <Form.Group controlId="estado">
                            <Form.Label>Estado:</Form.Label>
                            <Form.Control
                                name="estado"
                                as="select"
                                ref={register()}
                                disabled={true}
                                size="sm"
                               
                            >
                                {Object.keys(estados).map((e) => <option key={e} value={e}>{estados[e]}</option>)}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col xs={12} md={6}>
                        <Form.Group controlId="nombrevendedor">
                            <Form.Label>Nombre del verdedor o comercializador:</Form.Label>

                            <Form.Control
                                name="nombrevendedor"
                                maxLength={500}
                                type="text"
                                ref={register({ required: listoEnvio && 'Ingrese el Nombre del verdedor o comercializador', maxLength: 500 })}
                                isInvalid={!!errors.nombrevendedor}
                                readOnly={readOnly}
                                size="sm"
                            />
                            <ErrorMessage error={errors.nombrevendedor} />
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                        <Form.Group controlId="direccionvendedor">
                            <Form.Label>Dirección del vendedor comercializador:</Form.Label>
                            <Form.Control
                                name="direccionvendedor"
                                maxLength={500}
                                type="text"
                                ref={register({ required: listoEnvio && 'Ingrese la Dirección del vendedor comercializador', maxLength: 500 })}
                                isInvalid={!!errors.direccionvendedor}
                                readOnly={readOnly}
                                size="sm"
                            />
                            <ErrorMessage error={errors.direccionvendedor} />
                        </Form.Group>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col xs={12} md={6}>
                        <Form.Group controlId="nombreproductor">
                            <Form.Label>Nombre del  productor o fabricante:</Form.Label>

                            <Form.Control
                                name="nombreproductor"
                                maxLength={500}
                                type="text"
                                ref={register({ required: listoEnvio && 'Ingrese el Nombre del  productor o fabricante', maxLength: 500 })}
                                isInvalid={!!errors.nombreproductor}
                                readOnly={readOnly}
                                size="sm"
                            />
                            <ErrorMessage error={errors.nombreproductor} />
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                        <Form.Group controlId="direccionproductor">
                            <Form.Label>Dirección del productor o fabricante:</Form.Label>
                            <Form.Control
                                name="direccionproductor"
                                maxLength={500}
                                type="text"
                                ref={register({ required: listoEnvio && 'Ingrese la Dirección del productor o fabricante', maxLength: 500 })}
                                isInvalid={!!errors.direccionproductor}
                                readOnly={readOnly}
                                size="sm"
                            />
                            <ErrorMessage error={errors.direccionproductor} />
                        </Form.Group>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col xs={12} md={6}>
                        <Form.Group controlId="nombrecomprador">
                            <Form.Label>Nombre de comprador o importador:</Form.Label>

                            <Form.Control
                                name="nombrecomprador"
                                maxLength={500}
                                type="text"
                                ref={register({ required: listoEnvio && 'Ingrese el Nombre de comprador o importador', maxLength: 500 })}
                                isInvalid={!!errors.nombrecomprador}
                                readOnly={readOnly}
                                size="sm"
                            />
                            <ErrorMessage error={errors.nombrecomprador} />
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                        <Form.Group controlId="direccioncomprador">
                            <Form.Label>Dirección de comprador o importador:</Form.Label>

                            <Form.Control
                                name="direccioncomprador"
                                maxLength={500}
                                type="text"
                                ref={register({ required: listoEnvio && 'Ingrese la Dirección de comprador o importador', maxLength: 500 })}
                                isInvalid={!!errors.direccioncomprador}
                                readOnly={readOnly}
                                size="sm"
                            />
                            <ErrorMessage error={errors.direccioncomprador} />
                        </Form.Group>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col xs={12} md={6}>
                        <Form.Group controlId="nombreconsignatario">
                            <Form.Label>Nombre del consignatario:</Form.Label>

                            <Form.Control
                                name="nombreconsignatario"
                                maxLength={500}
                                type="text"
                                ref={register({ required: listoEnvio && 'Ingrese el Nombre del consignatario', maxLength: 500 })}
                                isInvalid={!!errors.nombreconsignatario}
                                readOnly={readOnly}
                                size="sm"
                            />
                            <ErrorMessage error={errors.nombreconsignatario} />
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                        <Form.Group controlId="direccionconsignatario">
                            <Form.Label>Dirección del consignatario:</Form.Label>

                            <Form.Control
                                name="direccionconsignatario"
                                maxLength={500}
                                type="text"
                                ref={register({ required: listoEnvio && 'Ingrese la Dirección del consignatario', maxLength: 500 })}
                                isInvalid={!!errors.direccionconsignatario}
                                readOnly={readOnly}
                                size="sm"
                            />
                            <ErrorMessage error={errors.direccionconsignatario} />
                        </Form.Group>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col xs={12} md={6}>
                        <Form.Group controlId="nombreorgcertproduccion">
                            <Form.Label>Nombre del organismo de control que certifico la producción:</Form.Label>

                            <Form.Control
                                name="nombreorgcertproduccion"
                                maxLength={500}
                                type="text"
                                ref={register({ required: listoEnvio && 'Ingrese el Nombre del organismo de control que certifico la producción', maxLength: 500 })}
                                isInvalid={!!errors.nombreorgcertproduccion}
                                readOnly={readOnly}
                                size="sm"
                            />
                            <ErrorMessage error={errors.nombreorgcertproduccion} />
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                        <Form.Group controlId="direccionorgcertproduccion">
                            <Form.Label>Dirección del organismo de control que certifico la producción :</Form.Label>

                            <Form.Control
                                name="direccionorgcertproduccion"
                                maxLength={500}
                                type="text"
                                ref={register({ required: listoEnvio && 'Ingrese la Dirección del organismo de control que certifico la producción', maxLength: 500 })}
                                isInvalid={!!errors.direccionorgcertproduccion}
                                readOnly={readOnly}
                                size="sm"
                            />
                            <ErrorMessage error={errors.direccionorgcertproduccion} />
                        </Form.Group>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col xs={12} md={4}>
                        <Form.Group controlId="paisciudadenvio">
                            <Form.Label>País o ciudad de envío del cargamento:</Form.Label>

                            <Form.Control
                                name="paisciudadenvio"
                                maxLength={500}
                                type="text"
                                ref={register({ required: listoEnvio && 'Ingrese el País o ciudad de envío', maxLength: 50 })}
                                isInvalid={!!errors.paisciudadenvio}
                                readOnly={readOnly}
                                size="sm"
                            />
                            <ErrorMessage error={errors.paisciudadenvio} />
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={4}>
                        <Form.Group controlId="paisciudaddestino">
                            <Form.Label>País o ciudad de destino del cargamento:</Form.Label>

                            <Form.Control
                                name="paisciudaddestino"
                                maxLength={50}
                                type="text"
                                ref={register({ required: listoEnvio && 'Ingrese el País o ciudad de destino', maxLength: 50 })}
                                isInvalid={!!errors.paisciudaddestino}
                                readOnly={readOnly}
                                size="sm"
                            />
                            <ErrorMessage error={errors.paisciudaddestino} />
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={4}>
                        <Form.Group controlId="numerobl">
                            <Form.Label>Número de BL:</Form.Label>

                            <Form.Control
                                name="numerobl"
                                maxLength={50}
                                type="text"
                                ref={register({ required: listoEnvio && 'Ingrese el Número de BL', maxLength: 50 })}
                                isInvalid={!!errors.numerobl}
                                readOnly={readOnly}
                                size="sm"
                            />
                            <ErrorMessage error={errors.numerobl} />
                        </Form.Group>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col xs={12} md={4}>
                        <Form.Group controlId="denominacionproducto">
                            <Form.Label>Denominación comercial de producto:</Form.Label>

                            <Form.Control
                                name="denominacionproducto"
                                maxLength={50}
                                type="text"
                                ref={register({ required: listoEnvio && 'Ingrese la Denominación comercial del producto', maxLength: 50 })}
                                isInvalid={!!errors.denominacionproducto}
                                readOnly={readOnly}
                                size="sm"
                            />
                            <ErrorMessage error={errors.denominacionproducto} />
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={4}>
                        <Form.Group controlId="periodocultivo">
                            <Form.Label>Período de cultivo:</Form.Label>

                            <Form.Control
                                name="periodocultivo"
                                maxLength={500}
                                type="text"
                                ref={register({ required: listoEnvio && 'Ingrese el Período', maxLength: 500 })}
                                isInvalid={!!errors.periodocultivo}
                                readOnly={readOnly}
                                size="sm"
                            />
                            <ErrorMessage error={errors.periodocultivo} />
                        </Form.Group>
                    </Col>
                    {(Formato === FormatoCertTransaccion.FD2 || Formato === FormatoCertTransaccion.FD3) &&
                        <Col xs={12} md={4}>
                            <Form.Group controlId="numguiaremision">
                                <Form.Label>Nº Guía de remisión:</Form.Label>

                                <Form.Control
                                    name="numguiaremision"
                                    maxLength={50}
                                    type="text"
                                    ref={register({ required: listoEnvio && 'Ingrese el N° de Guía de remisión', maxLength: 50 })}
                                    isInvalid={!!errors.numguiaremision}
                                    readOnly={readOnly}
                                    size="sm"
                                />
                                <ErrorMessage error={errors.numguiaremision} />
                            </Form.Group>
                        </Col>}
                </Form.Row>
                <Form.Row>
                    <Col xs={12} md={4}>
                        <Form.Group controlId="numfactura">
                            <Form.Label>Número Factura comercial:</Form.Label>

                            <Form.Control
                                name="numfactura"
                                maxLength={50}
                                type="text"
                                ref={register({ required: listoEnvio && 'Ingrese el Número de Factura', maxLength: 50 })}
                                isInvalid={!!errors.numfactura}
                                readOnly={readOnly}
                                size="sm"
                            />
                            <ErrorMessage error={errors.numfactura} />
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={4}>
                        <Form.Group controlId="codigocontenedor">
                            <Form.Label>Código de contenedor:</Form.Label>

                            <Form.Control
                                name="codigocontenedor"
                                maxLength={50}
                                type="text"
                                ref={register({ required: listoEnvio && 'Ingrese el Código del contenedor', maxLength: 50 })}
                                isInvalid={!!errors.codigocontenedor}
                                readOnly={readOnly}
                                size="sm"
                            />
                            <ErrorMessage error={errors.codigocontenedor} />
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={4}>
                        <Form.Group controlId="sellosprecintos">
                            <Form.Label>Sellos/Precintos:</Form.Label>

                            <Form.Control
                                name="sellosprecintos"
                                maxLength={50}
                                type="text"
                                ref={register({ required: listoEnvio && 'Ingrese Sellos/Precintos', maxLength: 50 })}
                                isInvalid={!!errors.sellosprecintos}
                                readOnly={readOnly}
                                size="sm"
                            />
                            <ErrorMessage error={errors.sellosprecintos} />
                        </Form.Group>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col xs={12} md={4}>
                        <Form.Group controlId="lote">
                            <Form.Label>Lote :</Form.Label>

                            <Form.Control
                                name="lote"
                                maxLength={50}
                                type="text"
                                ref={register({ required: listoEnvio && 'Ingrese el Lote', maxLength: 50 })}
                                isInvalid={!!errors.lote}
                                readOnly={readOnly}
                                size="sm"
                            />
                            <ErrorMessage error={errors.lote} />
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={4}>
                        <Form.Group controlId="vessel">
                            <Form.Label>Vessel:</Form.Label>

                            <Form.Control
                                name="vessel"
                                maxLength={50}
                                type="text"
                                ref={register({ required: listoEnvio && 'Ingrese Vessel', maxLength: 50 })}
                                isInvalid={!!errors.vessel}
                                readOnly={readOnly}
                                size="sm"
                            />
                            <ErrorMessage error={errors.vessel} />
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={4}>
                        <Form.Group controlId="descripcionsacos">
                            <Form.Label>Descripción de los sacos:</Form.Label>

                            <Form.Control
                                name="descripcionsacos"
                                maxLength={500}
                                type="text"
                                ref={register({ required: listoEnvio && 'Ingrese la Descripción de los sacos', maxLength: 500 })}
                                isInvalid={!!errors.descripcionsacos}
                                readOnly={readOnly}
                                size="sm"
                            />
                            <ErrorMessage error={errors.descripcionsacos} />
                        </Form.Group>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col xs={12} md={4}>
                        <Form.Group controlId="pesobruto">
                            <Form.Label>Peso bruto, Kg:</Form.Label>

                            <Form.Control
                                name="pesobruto"
                                type="number"
                                ref={register({
                                    required: listoEnvio && "Ingrese el Peso Bruto.",
                                    maxLength: 9,
                                    valueAsNumber: true,
                                    validate: validarPeso
                                })}
                                isInvalid={!!errors.pesobruto}
                                readOnly={readOnly}
                                size="sm"
                            />
                            <ErrorMessage error={errors.pesobruto} />
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={4}>
                        <Form.Group controlId="pesoneto">
                            <Form.Label>Peso neto, Kg:</Form.Label>

                            <Form.Control
                                name="pesoneto"
                                type="number"
                                ref={register({
                                    required: listoEnvio && "Ingrese el Peso Neto.",
                                    maxLength: 9,
                                    valueAsNumber: true,
                                    validate: validarPeso
                                })}
                                isInvalid={!!errors.pesoneto}
                                readOnly={readOnly}
                                size="sm"
                            />
                            <ErrorMessage error={errors.pesoneto} />
                        </Form.Group>
                    </Col>
                </Form.Row>

                {Formato === FormatoCertTransaccion.COI &&
                    <>
                        <Form.Row>
                            <Col xs={12} md={4}>
                                <Form.Group controlId="mediotransporte">
                                    <Form.Label>Medio de transporte:</Form.Label>
                                    <Form.Control
                                        name="mediotransporte"
                                        as="select"
                                        ref={register({ required: listoEnvio && 'Seleccione el Medio de Transporte' })}
                                        disabled={readOnly}
                                        size="sm"
                                    >
                                        {Object.keys(mediosTransporte).map((e) => <option key={e} value={e}>{mediosTransporte[e]}</option>)}
                                    </Form.Control>
                                    <ErrorMessage error={errors.mediotransporte} />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={8}>
                                <Form.Group controlId="paisexportacion">
                                    <Form.Label>País de exportación:</Form.Label>

                                    <Form.Control
                                        name="paisexportacion"
                                        maxLength={50}
                                        type="text"
                                        ref={register({ required: listoEnvio && 'Ingrese el país de exportación', maxLength: 50 })}
                                        isInvalid={!!errors.paisexportacion}
                                        readOnly={readOnly}
                                        size="sm"
                                    />
                                    <ErrorMessage error={errors.paisexportacion} />
                                </Form.Group>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col xs={12} md={4}>
                                <Form.Group controlId="codigocn">
                                    <Form.Label>Código CN:</Form.Label>

                                    <Form.Control
                                        name="codigocn"
                                        maxLength={50}
                                        type="text"
                                        ref={register({ required: listoEnvio && 'Ingrese el Código CN', maxLength: 50 })}
                                        isInvalid={!!errors.codigocn}
                                        readOnly={readOnly}
                                        size="sm"
                                    />
                                    <ErrorMessage error={errors.codigocn} />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={8}>
                                <Form.Group controlId="correo">
                                    <Form.Label>Correo:</Form.Label>

                                    <Form.Control
                                        name="correo"
                                        maxLength={50}
                                        type="text"
                                        ref={register({ maxLength: 100 })}
                                        isInvalid={!!errors.correo}
                                        readOnly={readOnly}
                                        size="sm"
                                    />
                                    <ErrorMessage error={errors.correo} />
                                </Form.Group>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col xs={12} md={4}>
                                <Form.Group controlId="puntoentrada">
                                    <Form.Label>Punto de entrada:</Form.Label>

                                    <Form.Control
                                        name="puntoentrada"
                                        maxLength={50}
                                        type="text"
                                        ref={register({ required: listoEnvio && 'Ingrese el Punto de entrada', maxLength: 50 })}
                                        isInvalid={!!errors.puntoentrada}
                                        readOnly={readOnly}
                                        size="sm"
                                    />
                                    <ErrorMessage error={errors.puntoentrada} />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={8}>
                                <Form.Group controlId="direccionpuntoentrada">
                                    <Form.Label>Dirección de punto de entrada:</Form.Label>

                                    <Form.Control
                                        name="direccionpuntoentrada"
                                        maxLength={50}
                                        type="text"
                                        ref={register({ required: listoEnvio && 'Ingrese la Dirección de punto de entrada', maxLength: 50 })}
                                        isInvalid={!!errors.direccionpuntoentrada}
                                        readOnly={readOnly}
                                        size="sm"
                                    />
                                    <ErrorMessage error={errors.direccionpuntoentrada} />
                                </Form.Group>
                            </Col>
                        </Form.Row>

                        <Form.Row>
                            <Col xs={12} md={6}>
                                <Form.Group controlId="direccionpuntoentrada">
                                    <Form.Label>Número de cuenta currier internacional al que se cargará el pago :</Form.Label>

                                    <Form.Control
                                        name="numcuentacurrier"
                                        maxLength={50}
                                        type="text"
                                        ref={register({ maxLength: 100 })}
                                        isInvalid={!!errors.numcuentacurrier}
                                        readOnly={readOnly}
                                        size="sm"
                                    />
                                    <ErrorMessage error={errors.numcuentacurrier} />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Group controlId="otrascertificaciones">
                                    <Form.Label>Otras certifciaciones: NOP. JAS Bio Suisse, IBD:</Form.Label>

                                    <Form.Control
                                        name="otrascertificaciones"
                                        maxLength={50}
                                        type="text"
                                        ref={register({ maxLength: 100 })}
                                        isInvalid={!!errors.otrascertificaciones}
                                        readOnly={readOnly}
                                        size="sm"
                                    />
                                    <ErrorMessage error={errors.otrascertificaciones} />
                                </Form.Group>
                            </Col>
                        </Form.Row>
                    </>
                }
                {Formato === FormatoCertTransaccion.FD3 &&
                    <>
                        <Form.Row>
                            <Col xs={12} md={4}>
                                <Form.Group controlId="nombreconductor">
                                    <Form.Label>Nombre del conductor:</Form.Label>

                                    <Form.Control
                                        name="nombreconductor"
                                        maxLength={50}
                                        type="text"
                                        ref={register({ required: listoEnvio && 'Ingrese el Nombre del conductor', maxLength: 50 })}
                                        isInvalid={!!errors.nombreconductor}
                                        readOnly={readOnly}
                                        size="sm"
                                    />
                                    <ErrorMessage error={errors.nombreconductor} />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={4}>
                                <Form.Group controlId="numlicconductor">
                                    <Form.Label>Número de licencia del conductor:</Form.Label>

                                    <Form.Control
                                        name="numlicconductor"
                                        maxLength={50}
                                        type="text"
                                        ref={register({ required: listoEnvio && 'Ingrese la licencia del conductor', maxLength: 50 })}
                                        isInvalid={!!errors.numlicconductor}
                                        readOnly={readOnly}
                                        size="sm"
                                    />
                                    <ErrorMessage error={errors.numlicconductor} />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={4}>
                                <Form.Group controlId="placavehiculo">
                                    <Form.Label>Placa del vehículo:</Form.Label>

                                    <Form.Control
                                        name="placavehiculo"
                                        maxLength={50}
                                        type="text"
                                        ref={register({ required: listoEnvio && 'Ingrese la Placa del vehículo', maxLength: 50 })}
                                        isInvalid={!!errors.placavehiculo}
                                        readOnly={readOnly}
                                        size="sm"
                                    />
                                    <ErrorMessage error={errors.placavehiculo} />
                                </Form.Group>
                            </Col>
                        </Form.Row>
                    </>
                }
                <Form.Row>
                    <Col xs={12} md={4}>
                        <Form.Group controlId="factura_id">
                            <Form.Label>Factura:</Form.Label>
                            <ArchivoSelector
                                control={control}
                                name="factura_id"
                                readOnly={readOnly}
                                required={listoEnvio ? "Ingrese la Factura" : ""}
                                isInvalid={!!errors.factura_id}
                            />
                            <ErrorMessage error={errors.factura_id} />
                        </Form.Group>

                    </Col>
                    <Col xs={12} md={4}>
                        <Form.Group controlId="marcado_saco_id">
                            <Form.Label>Etiqueta:</Form.Label>
                            <ArchivoSelector
                                control={control}
                                name="marcado_saco_id"
                                readOnly={readOnly}
                                required={listoEnvio ? "Ingrese el Etiqueta" : ""}
                                isInvalid={!!errors.marcado_saco_id}
                            />
                            <ErrorMessage error={errors.marcado_saco_id} />
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={4}>
                        <Form.Group controlId="borrador_bl_id">
                            <Form.Label>Borrador del BL :</Form.Label>
                            <ArchivoSelector
                                control={control}
                                name="borrador_bl_id"
                                readOnly={readOnly}
                                required={listoEnvio ? "Ingrese el Borrador del BL " : ""}
                                isInvalid={!!errors.borrador_bl_id}
                            />
                            <ErrorMessage error={errors.borrador_bl_id} />
                        </Form.Group>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col xs={12} md={4}>
                        <Form.Group controlId="remision_id">
                            <Form.Label>Remisión:</Form.Label>
                            <ArchivoSelector
                                control={control}
                                name="remision_id"
                                readOnly={readOnly}
                                required={false}
                                isInvalid={!!errors.remision_id}
                            />
                            <ErrorMessage error={errors.remision_id} />
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={4}>
                        <Form.Group controlId="productores_id">
                            <Form.Label>Lista de productores:</Form.Label>
                            <ArchivoSelector
                                control={control}
                                name="productores_id"
                                readOnly={readOnly}
                                required={listoEnvio ? "Ingrese la Lista de productores" : ""}
                                isInvalid={!!errors.productores_id}
                            />
                            <ErrorMessage error={errors.productores_id} />
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={4}>
                        <Form.Group controlId="tclocal_id">
                            <Form.Label>TC Local :</Form.Label>
                            <ArchivoSelector
                                control={control}
                                name="tclocal_id"
                                readOnly={readOnly}
                                isInvalid={!!errors.tclocal_id}
                            />
                            <ErrorMessage error={errors.tclocal_id} />
                        </Form.Group>
                    </Col>

                </Form.Row>
                {anulado &&
                    <Form.Row>
                        <Col xs={12} md={12}>
                            <Form.Group controlId="razonnulacion">
                                <Form.Label>Razón de la Anulación:</Form.Label>
                                <Form.Control
                                    name="razonanulacion"
                                    as="textarea"
                                    maxLength={1000}
                                    rows={2}
                                    ref={register({ required: listoEnvio && 'Ingrese la razón de la anulación', maxLength: 1000 })}
                                    isInvalid={!!errors.razonanulacion}
                                    readOnly={readOnly}
                                />
                                <ErrorMessage error={errors.razonanulacion} />
                            </Form.Group>
                        </Col>
                    </Form.Row>}

                <Form.Row>
                    <Col xs={12} md={12}>
                        <Form.Group controlId="listo_envio">
                            <Form.Check
                                name="listo_envio"
                                type="switch"
                                label="Se ha completado el ingreso de todos los datos requerido."
                                ref={register()}
                                disabled={readOnly
                                    || estado !== EstadoCertTransaccion.EnElaboracion
                                    || !isAllowed(user, Acciones.Transacciones.CertTransaccion.Enviar)}
                                size="sm"
                                onChange={(e) => setListoEnvio(e.target.checked)}
                            />
                        </Form.Group>
                    </Col>
                </Form.Row>
                {/* {estado !== EstadoCertTransaccion.EnElaboracion && */}
                <Form.Row>
                    <Col xs={12} md={12}>
                        <Form.Group controlId="notas">
                            <Form.Label>Notas para correcciones:</Form.Label>
                            <Form.Control
                                name="notas"
                                as="textarea"
                                maxLength={1000}
                                rows={2}
                                ref={register()}
                                isInvalid={!!errors.notas}
                                onChange={(e) => setNotas(e.target.value)}
                                readOnly={readOnly
                                    || estado !== EstadoCertTransaccion.Enviado
                                    || !isAllowed(user, Acciones.Transacciones.CertTransaccion.GenerarBorrador)}
                            />
                            <ErrorMessage error={errors.notas} />
                        </Form.Group>
                    </Col>
                </Form.Row>
                {  estado !== EstadoCertTransaccion.EnElaboracion &&
                    <Form.Row>
                        <Col xs={12} md={12}>
                            <Form.Group controlId="corregido">
                                <Form.Check
                                    name="corregido"
                                    type="switch"
                                    label="Se han completado todas las correcciones"
                                    ref={register()}
                                    disabled={readOnly
                                        || !notas 
                                        || estado !== EstadoCertTransaccion.Enviado
                                        || !isAllowed(user, Acciones.Transacciones.CertTransaccion.GenerarBorrador)}
                                    size="sm"
                                    onChange={(e) => setCorregido(e.target.checked)}
                                />
                            </Form.Group>
                        </Col>
                    </Form.Row>}
                    {(estado !== EstadoCertTransaccion.EnElaboracion
                        && estado !== EstadoCertTransaccion.EnElaboracion  
                        && estado !== EstadoCertTransaccion.Confirmado
                        && estado !== EstadoCertTransaccion.Emitido  )
                        &&
                    <Form.Row>
                        <Col xs={12} md={4}>
                            <Form.Group controlId="borrador_id">
                                <Form.Label>Borrador:</Form.Label>
                                <ArchivoSelector
                                    control={control}
                                    name="borrador_id"
                                    readOnly={readOnly 
                                                || !isAllowed(user, Acciones.Transacciones.CertTransaccion.GenerarBorrador)
                                                || (!!notas && !corregido)}
                                />
                            </Form.Group>
                        </Col>
                        
                    </Form.Row>}
                {((estado === EstadoCertTransaccion.Confirmado && isAllowed(user, Acciones.Transacciones.CertTransaccion.Emitir))
                    || estado === EstadoCertTransaccion.Emitido) &&
                    <Form.Row>
                        <Col xs={12} md={4}>
                            <Form.Group controlId="certificado_id">
                                <Form.Label>Certificado firmado:</Form.Label>
                                <ArchivoSelector
                                    control={control}
                                    name="certificado_id"
                                    readOnly={readOnly}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={2}>
                            <Form.Group controlId="fechaemision">
                                <Form.Label>Fecha de Emisión:</Form.Label>
                                <DatePicker
                                    name="fechaemision"
                                    control={control}
                                    readOnly={readOnly}
                                    required='Ingrese la fecha de Emisión.'
                                    isInvalid={!!errors.fechaemision}
                                />
                                <ErrorMessage error={errors.fechaemision} />
                            </Form.Group>
                        </Col>
                    </Form.Row>}
                <Cargando activo={procesando || loading} mensaje={mensaje} />
                {mensaje && <Alert variant="info">{mensaje}</Alert>}
                {!(procesando || loading) && <>
                    <div className="float-left">
                        {!anulado
                            && listoEnvio
                            && (estado === EstadoCertTransaccion.EnElaboracion || estado === EstadoCertTransaccion.EnBorador)
                            && isAllowed(user, Acciones.Transacciones.CertTransaccion.Enviar) &&
                            <Button id="btnEnviar"
                                variant="primary"
                                type="button"
                                onClick={handleSubmit(onSubmit)}>
                                <RiMailSendLine id='svgEnviar' /> {(estado === EstadoCertTransaccion.EnElaboracion)? "Enviar": "Corregir Borrador"}
                            </Button>
                        }
                        {" "}
                        {!anulado
                            && estado === EstadoCertTransaccion.Enviado
                            && isAllowed(user, Acciones.Transacciones.CertTransaccion.GenerarBorrador) &&
                            <>
                                <Button id="btnRegresarEE" variant="secondary" type="button" onClick={handleSubmit(onSubmit)} >
                                    <IoMdArrowRoundBack id='svgRegresarEE' /> Regresar a En Elaboración
                                </Button>
                                {" "}
                                <Button id="btnGenerarBorrador" variant="primary" type="button" onClick={handleSubmit(onSubmit)} >
                                    <FaFileWord id='svgGenerarBorrador' /> Generar Borrador
                                </Button>
                            </>
                        }
                        {!anulado
                            && estado === EstadoCertTransaccion.EnBorador
                            && isAllowed(user, Acciones.Transacciones.CertTransaccion.Confirmar) &&
                            <>
                                <Button id="btnConfirmar" variant="primary" type="button" onClick={handleSubmit(onSubmit)} >
                                    <TiInputCheckedOutline id='svgConfirmar' /> Confirmar
                                </Button>
                            </>
                        }
                        {" "}
                        {/* {!anulado
                                && estado === EstadoCertTransaccion.EnBorador &&
                                <>
                                    <Button id="btnVerBorrador" variant="success" type="button" onClick={() => abrirVisorBorrador()} >
                                        <VscOpenPreview id='svgVerBorrador' /> Ver Borrador
                                    </Button>
                                    <Visor 
                                        mostrar={mostrarBorrador} 
                                        cerrar={() => setMostrarBorrador(false)} 
                                        tipo="docx" 
                                        url={urlBorrador}
                                        titulo="Borrador" />
                                </>
                            }
                            {" "} */}
                        {!anulado
                            && estado === EstadoCertTransaccion.Confirmado
                            && isAllowed(user, Acciones.Transacciones.CertTransaccion.Generar) &&
                            <>
                                <Button id="btnGenerar" variant="primary" type="button" onClick={handleSubmit(onSubmit)} >
                                    <FaFileWord id='svgGenerar' /> Generar Certificado
                                </Button>
                                {" "}
                            </>
                        }
                        {" "}
                        {id > 0
                            && isAllowed(user, Acciones.Transacciones.CertTransaccion.Emitir) &&
                            <>
                                <Button id="btnHistEstados"
                                    variant="secondary"
                                    type="button"
                                    onClick={(e) => setMostrarHistEstados(true)}>
                                    <FcWorkflow id='svgHistEstados' /> Hist. Estados
                                </Button>
                                <CertTransaccionEstados
                                    certId={id}
                                    mostrar={mostrarHistEstados}
                                    cerrar={() => setMostrarHistEstados(false)} />
                            </>
                        }
                        {" "}
                        {!anulado
                            && id > 0
                            && estado !== EstadoCertTransaccion.Enviado
                            && isAllowed(user, Acciones.Transacciones.CertTransaccion.Anular) &&
                            <Button id="btnAnular"
                                variant="danger"
                                type="button"
                                onClick={(e) => anular(e)}>
                                <TiDelete id='svgAnular' /> Anular
                            </Button>
                        }
                    </div>
                    <div className="float-right">
                        {!readOnly && <>
                            <Button id="btnGuardar" variant="primary" type="button" onClick={handleSubmit(onSubmit)} >
                                <FaSave id="svgGuardar" /> Guardar
                            </Button>{" "}
                            <Button variant="secondary" onClick={() => cerrar()}>
                                <FaUndo /> Cancelar
                            </Button>
                        </>}

                        {readOnly && (
                            <>
                                <Button variant="secondary" onClick={() => cerrar()}>
                                    <FaTimes /> Cerrar
                                </Button>
                            </>
                        )}
                    </div>
                </>
                }
            </Form>
        </>
    );
}
