import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Modal } from "react-bootstrap";

const Cargando = ({activo, mensaje='Cargando...'}) => {
    const onHide = () => {
      ;
    }
    return (
      <Modal centered size="sm" show={activo} onHide={onHide}>
        <Modal.Body>
        <div className="text-center">
          <div className="spinner-grow text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          {" " + mensaje}
        </div>
        </Modal.Body>
      </Modal>
    );
};

export default Cargando;