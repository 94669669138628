import { useContext, useEffect, useState } from 'react'
import PaisService from 'services/PaisService';
import { AuthContext } from "context/AuthContext";

export default function usePaises() {
    const [lista, setLista] = useState([]);
    const [paisId, setPaisId] = useState(null);
    const [loadingPaises, setLoadingPaises] = useState(true);
    const context = useContext(AuthContext);

    useEffect(() => {
        if(context.paises && context.paises.length > 0) {
            setLista(context.paises);
            if (context.paises.length > 0)
                setPaisId(context.paises[0].id);
            return;
        }
        setLoadingPaises(true);
        PaisService.traerLista()
            .then((result) => {
                if (result !== undefined) {
                    setLista(result);
                    if (result.length > 0)
                        setPaisId(result[0].id);
                    setLoadingPaises(false);
                }
            })
            .catch((err) => {
                console.error(err);
                setLoadingPaises(false);
            });
    }, []);

    return { paises: lista, paisId, setPaisId, loadingPaises }
}