import axios from 'axios';

const apiClient = axios.create({
    baseURL: 'https://api.biolatina.net/',// 'https://tsapi.biolatina.net',//'https://api.biolatina.net/',//'http://pezib.ezyro.com/','http://localhost:8000',
    withCredentials: true,
});

export function handleError(error){
    if(error.response && error.response.status === 401)//Unauthorized
    {
        alert("Por favor autentíquese.");
        localStorage.setItem("loggedIn", false)
        window.location.replace("/login")
    }
    else if (error.response && error.response.data && error.response.data.error)
        alert(error.response.data.error);
    else if(!error.__CANCEL__) {
        console.error(error)
    }
    else
        throw error;
}

export function getErrorMessage(error){
    if(error?.response?.data)
    {
        let errorMsg = ''
        for(let field in error.response.data)
        {
            errorMsg = errorMsg+ `${error.response.data[field]}\n`;
        }
        return errorMsg;
    }
    else if(error.message !== undefined)
        return error.message;
    
}
export default apiClient;