import axios from 'axios';
import { AuthContext } from 'context/AuthContext';
import { useContext, useEffect, useState } from 'react'
import OperadorService from 'services/OperadorService';

export default function useOperadores(paisId) {
    const [operadores, setOperadores] = useState([]);
    const [operadorId, setOperadorId] = useState(null);
    const [loadingOperador, setLoadingOperador] = useState(false);
    const context = useContext(AuthContext);

    useEffect(() => {
        if(context.operadores && context.operadores.length > 0) {
            setOperadores(context.operadores);
            if (context.operadores.length > 0)
                setOperadorId(context.operadores[0].id);
            return;
        }
        if(paisId !== null) {
            let cancelToken = axios.CancelToken.source(); 
            setLoadingOperador(true);
            OperadorService.traerListaPorPaisId(paisId, cancelToken)
                .then((result) => {
                    if (result !== undefined) {
                        result.sort(x => x.codigo)
                        setOperadores(result);
                        if (result.length > 0)
                            setOperadorId(result[0].id);
                        setLoadingOperador(false);
                    }
                })
                .catch((err) => {
                    console.error(err);
                    setLoadingOperador(false);
                });
            return () => {
                cancelToken.cancel("Carga de Operadores cancelada por el usuario: "+ paisId);
            };
        }
    }, [paisId]);

    return { operadores, operadorId, setOperadorId, loadingOperador }
}