import React, { useEffect, useState } from 'react';
import Busqueda from 'components/comunes/Busqueda';
import { Alert, Button, Modal, Table, Toast } from 'react-bootstrap';
import Cargando from 'components/comunes/Cargando';
import { handleError } from 'services/api';
import { FaTimes, FaUser } from 'react-icons/fa';


export default function UsuarioSelector({ activo, cerrar, traerLista, onSelect }) {
    const [lista, setLista] = useState([]);
    const [textoBusqueda, setTextoBusqueda] = useState("")
    const [isLoading, setIsLoading] = useState(false);
    const [mensaje, setMensaje] = useState("");
    const [toast, setToast] = useState("");

    const onSelected = (e, obj) => {
        try {
            e.preventDefault();
            hideMessage();
            let mensaje = onSelect(obj)
            // setMensaje(mensaje);

            setLista(lista.filter((user) => user.id !== obj.id));
            //cerrar();
        }
        catch (e) {
            setMensaje(e.message);
        }
    }

    //formulario para capturar el texto de búsqueda
    const busqueda = (
        <div className="row">
            <div className="col-sm-6">
                <Busqueda onBuscar={(texto) => {
                    console.log('buscando: ', texto);
                    if (texto !== textoBusqueda) {
                        setTextoBusqueda(texto);
                        setLista([]);
                    }
                }} />
            </div>
        </div>
    );

    //carga la página de productos, según el texto de búsqueda
    useEffect(() => {
        if (!activo) return;
        if (!textoBusqueda || textoBusqueda.length < 3) return;

        let abortController = new AbortController();
        setIsLoading(true);

        traerLista(textoBusqueda, abortController)
            .then((resultado) => {
                if (!abortController.signal.aborted) {
                    setLista(resultado);
                    setIsLoading(false);
                }
            })
            .catch((err) => {
                handleError(err)
                setIsLoading(false);
            });

        //Cancela la invovación cuando cierren el selector
        return () => abortController.abort();
    }, [textoBusqueda]);

    useEffect(() => {
        if (activo) {
            setTextoBusqueda('');
            setLista([]);
        }
    }, [activo]);
    const hideMessage = () => setMensaje('');

    //si ya se tienen los registros cargados se renderizan
    return (<>
        <Modal show={activo} onHide={cerrar} size="lg">
            <Modal.Header closeButton>
                <h4>
                    <FaUser /> Selector de Usuarios
                </h4>
            </Modal.Header>
            <Modal.Body>
                {busqueda}
                <Cargando activo={isLoading} />
                {!!mensaje && <Alert variant="danger">{mensaje}</Alert>}
                <div className="table-responsive">
                    <Table size="lg" striped bordered condensed={true} responsive hover>
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>Siglas</th>
                                <th>Correo</th>
                                <th>Descripción</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                lista.map((item) => {
                                    return (
                                        <tr
                                            key={item.Id}
                                            style={{ cursor: "pointer" }}
                                            onClick={(e) => onSelected(e, item)}>
                                            <td>{item.name}</td>
                                            <td> {item.code}</td>
                                            <td> {item.email}</td>
                                            <td> {item.info}</td>
                                        </tr>)
                                })
                            }
                            <tr>
                                <td colSpan={5} style={{ padding: 15 }}>
                                    {(!isLoading && lista.length === 0 && textoBusqueda && textoBusqueda.length > 2) &&
                                        <strong>No hay usuarios que coincidan con el criterio '{textoBusqueda}'</strong>
                                    }
                                    {(!isLoading && lista.length === 0 && !(textoBusqueda && textoBusqueda.length > 2)) &&
                                        <strong>Ingrese un texto para buscar.</strong>
                                    }
                                    {isLoading && <strong>Cargando usuarios según criterio '{textoBusqueda}'</strong>}
                                </td>
                            </tr>

                        </tbody>
                    </Table>
                </div>
                <div className="row">
                    <div className="col-sm-6">
                        Mostrando {lista.length} Usuarios.
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={cerrar}>
                    <FaTimes /> Cerrar
                </Button>
            </Modal.Footer>
        </Modal>
    </>);
}