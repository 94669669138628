import React from 'react'

export const AuthContext = React.createContext({
  user: {
    id: 0,
    name: '',
    email: '',
    roles: [],
    rights: []
  },
  paises: [],
  operadores: []
});
