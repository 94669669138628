import React from "react";
import { Navbar, Nav, NavLink } from "react-bootstrap";
import { useIsMobile } from "hooks/DeviceUtils";
import { FaBars, FaSignInAlt, FaSignOutAlt } from "react-icons/fa";
import logo from 'images/logo.png'


function MainBarNav(props) {
  const isMobile = useIsMobile();
  
  const authLink = props.loggedIn ? (
    <NavLink href="#" onClick={props.logout}>
      Cerrar Sesión <FaSignOutAlt />
    </NavLink>
  ) : (
    <NavLink href="/login">
      Iniciar Sesión <FaSignInAlt />
    </NavLink>
  );

  return (
    <>
     {/* <Navbar expand="lg" bg="light" variant="light"></Navbar> */}
      <Navbar expand="lg" bg="dark" variant="dark">
        <Navbar.Brand href="/"><img src={logo} height="32px" alt='Biolatina' /></Navbar.Brand>
        <Nav className="mr-auto"></Nav>
        <Nav > {authLink}</Nav>
        { (isMobile &&  props.loggedIn) && (
          <Navbar.Brand>
            <div
              style={{ cursor: "pointer", marginLeft: 10 }}
              onClick={() => props?.handleToggleSidebar(true)}
            >
              <FaBars /> 
            </div>
          </Navbar.Brand>
        )}
      </Navbar>
    </>
  );
}

export default MainBarNav;
