import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import Cargando from "../comunes/Cargando";
import Button from "react-bootstrap/Button";
import { useForm } from "react-hook-form";
import RolService from "services/RolService";
import { Alert, Col, Form } from "react-bootstrap";
import { getErrorMessage, handleError } from "services/api";
import { FaSave, FaTimes, FaUndo } from "react-icons/fa";
import { dateToLocalString } from "utiles/FormatHelper";
import { AuthContext } from "context/AuthContext";
import { hasRole } from "hooks/Auth";
import { Roles } from "utiles/Constantes";

export default function RolForm({ rolId = 0, cerrar, consulta = false }) {
  const [loading, setLoading] = useState(false);
  const [procesando, setProcesando] = useState(false);
  const { register, errors, handleSubmit, reset } = useForm();
  const [message, setMessage] = useState("");
  const [readOnly, setReadOnly] = useState(consulta);
  const { user } = useContext(AuthContext)

  useEffect(() => {
    let cancelToken = axios.CancelToken.source();
    if (rolId === 0) reset({ nombre: "", activo: false });
    else {
      setLoading(true);

      RolService.traer(rolId, cancelToken)
        .then((result) => {
          if (result !== undefined) {
            setLoading(false);
            if (result !== undefined) {
              if (result.created_at) result.created_at = dateToLocalString(result.created_at)
              if (result.updated_at) result.updated_at = dateToLocalString(result.updated_at)
              reset(result);
            }
            else {
              setMessage(`Rol con Id '${rolId}' no ha sido encontrado`);
              setReadOnly(true);
            }
          }
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            handleError(err);
            setLoading(false);
            setReadOnly(true);
            setMessage(getErrorMessage(err));
          }
        });
    }
    return () => {
      cancelToken.cancel("Carga de Rol cancelada por el usuario.");
    };
  }, [reset, rolId]);

  const onGuardar = (data) => {
    let guardar = RolService.agregar;
    if (rolId !== 0) {
      data.id = rolId;
      guardar = RolService.modificar;
    }
    setProcesando(true);
    setMessage("");

    guardar(data)
      .then((exito) => {
        if (exito === true) {
          cerrar(true);
          reset({ nombre: "", activo: false });
        } else setProcesando(false);
      })
      .catch((err) => {
        setProcesando(false);
        setMessage(getErrorMessage(err));
      });
  };
  if (loading)
    return (
      <>
        {<Alert variant="info">Cargando...</Alert>}
        <Cargando activo={loading} />
      </>
    );

  return (
    <>
      <Form onSubmit={handleSubmit(onGuardar)}>
        {hasRole(user, Roles.Admin) &&
          <Form.Group controlId="formBasicText">
            <Form.Label>Cód. Interno:</Form.Label>
            <Form.Control
              name="cod_interno"
              type="text"
              ref={register({ required: "Ingrese el código interno" })}
              isInvalid={!!errors.cod_interno}
              readOnly={readOnly}
            />
            {errors.cod_interno && (
              <small className="text-danger form-text">
                {errors.cod_interno?.message}
              </small>
            )}
          </Form.Group>
        }
        <Form.Group controlId="formBasicText">
          <Form.Label>Nombre:</Form.Label>
          <Form.Control
            name="nombre"
            type="text"
            ref={register({ required: "Ingrese el nombre" })}
            isInvalid={!!errors.nombre}
            readOnly={readOnly}
          />
          {errors.nombre && (
            <small className="text-danger form-text">
              {errors.nombre?.message}
            </small>
          )}
        </Form.Group>
        <Form.Group controlId="formBasicCheckbox">
          <Form.Check
            type="checkbox"
            name="activo"
            label="Activo"
            ref={register}
          />
        </Form.Group>
        {rolId > 0 && (
          <Form.Group controlId="formBitacora">
            <Form.Row>
              <Col xs={12} md={6}>
                <Form.Label>Creación:</Form.Label>
                <Form.Control
                  name="created_at"
                  type="text"
                  readOnly={true}
                  ref={register({})}
                />
              </Col>
              <Col xs={12} md={6}>
                <Form.Label>Última actualización:</Form.Label>
                <Form.Control
                  name="updated_at"
                  type="text"
                  readOnly={true}
                  ref={register()}
                />
              </Col>
            </Form.Row>
          </Form.Group>
        )}
        <Cargando activo={procesando} />
        {message && <Alert variant="danger">{message}</Alert>}
        {!procesando && (
          <div className="float-right">
            {!readOnly && (
              <>
                <Button variant="primary" type="submit">
                  <FaSave />
                  Guardar
                </Button>{" "}
                <Button variant="secondary" onClick={() => cerrar()}>
                  <FaUndo /> Cancelar
                </Button>
              </>
            )}

            {readOnly && (
              <>
                <Button variant="secondary" onClick={() => cerrar()}>
                  <FaTimes /> Cancelar
                </Button>
              </>
            )}
          </div>
        )}
      </Form>
    </>
  );
}
