import React from "react";

function IsMobile() {
  return (window.innerWidth < 770);//
}

function calcContentDimensions() {
  let margins = 20
  let topMenuHeight = 145
  let sideBarWidth = 280;
  if (IsMobile())
    return {
      height: window.innerHeight - topMenuHeight - margins, 
      width: window.innerWidth - margins
    };
  else
    return {
      height: window.innerHeight - topMenuHeight - margins,
      width: window.innerWidth - sideBarWidth - margins
    };
}

export function useContentDimensions() {
  const [dimensions, setDimensions] = React.useState(calcContentDimensions)
  React.useEffect(() => {
    function handleResize() {
      setDimensions(calcContentDimensions)
    }

    window.addEventListener('resize', handleResize)

    return _ => {
      window.removeEventListener('resize', handleResize)
    }
  })
  return dimensions;
}

export function useIsMobile() {
  const [isMobile, setIsMobile] = React.useState(IsMobile)
  React.useEffect(() => {
    function handleResizeIsMobile() {
      setIsMobile(IsMobile())
    }

    window.addEventListener('resize', handleResizeIsMobile)

    return _ => {
      window.removeEventListener('resize', handleResizeIsMobile)
    }
  })
  return isMobile;
}