import Cargando from "components/comunes/Cargando";
import React from "react";
import { Card, Container, Form, Button, Row, Col } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import apiClient from "../../services/api";

const Login = (props) => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [toHome, setToHome] = React.useState(false);
  const [authError, setAuthError] = React.useState(false);
  const [unknownError, setUnknownError] = React.useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    setAuthError(false);
    setUnknownError(false);
    setLoading(true);
    apiClient.get("/sanctum/csrf-cookie").then((response) => {
      apiClient
        .post("/login", {
          email: email,
          password: password,
        })
        .then((response) => {
          if (response.status === 204) {
            props.login();
            setToHome(true);       
          }
          setLoading(false);
        })
        .catch((error) => {
          if (error.response && error.response.status === 422) {
            setAuthError(true);
          } else {
            setUnknownError(true);
            console.error(error);
          }
          setLoading(false);
        });
    });
  };
  if (toHome === true || props.loggedIn === true) {
    return <Redirect to="/" />;
  }
  return (
    <Container>
      <Card>
        <Card.Header>Autenticación</Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group>
            <label htmlFor="email">Correo:</label>
              <input
                type="email"
                name="email"
                id="email"
                className={
                  "form-control" +
                  (authError || unknownError ? " is-invalid" : "")
                }
                placeholder="Ingrese su correo"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group>
              <label htmlFor="password">Contraseña:</label>
              <input
                type="password"
                name="password"
                id="password"
                className={
                  "form-control" +
                  (authError || unknownError ? " is-invalid" : "")
                }
                placeholder="Ingrese su contraseña"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </Form.Group>
            {authError ? (
              <div className="alert alert-danger">
                Credenciales no son válidas, pruebe de nuevo
              </div>
            ) : null}
            {unknownError ? (
              <div className="alert alert-danger">
                There was an error submitting your details.
              </div>
            ) : null}
            <Row>
                <Col className="text-right">
                  {!loading && 
                    <Button variant="primary" type="submit" >
                            Autenticar
                    </Button>
                  }
                  {loading && <Cargando activo={loading} /> }
                </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default Login;
