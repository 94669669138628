import React, { useEffect, useState } from "react";
import "./App.css";
import "react-pro-sidebar/dist/css/styles.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import apiClient from "./services/api";
import Login from "./components/seguridad/Login";
import MainBarNav from "./components/layout/MainBarNav";
import Sidebar from "./components/layout/Sidebar";
import Inicio from "./components/comunes/Inicio";
import AcccionAdmin from "./components/seguridad/AccionAdmin";
import RoleAdmin from "./components/seguridad/RolAdmin";
import PaisAdmin from "components/catalogos/PaisAdmin";
import { AuthContext } from "context/AuthContext";
import AuthService from "services/AuthService";
import { isAllowed, isAuthenticated } from "hooks/Auth";
import UsuarioAdmin from "components/seguridad/UsuarioAdmin";
import OperadorAdmin from "components/catalogos/OperadorAdmin";
import CertMasterAdmin from "components/transacciones/CertMasterAdmin";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import Cargando from "components/comunes/Cargando";
import CertTransaccionAdmin from "components/transacciones/CertTransaccionAdmin";
import PaisService from "services/PaisService";
import { Acciones } from "utiles/Constantes";
import OperadorService from "services/OperadorService";
import EliminarPF1 from "components/anterior/EliminarPF1";

function App() {
  const [loading, setLoading] = useState(false);
  const [rtl, setRtl] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [image, setImage] = useState(true);
  const [toggled, setToggled] = useState(false);
  const [context, setContext] = useState({ user: undefined });
  const [toHome, setToHome] = React.useState(false);
  
  const handleToggleSidebar = (value) => {
    // if (!isMobile)
    //   setCollapsed(!collapsed);
    setToggled(value);
  };
  let loggedIn = isAuthenticated(context.user);

  useEffect(() => {
    cargarUsuario();
  }, []);

  const cargarUsuario = async () => {
    setLoading(true);
    let user = await AuthService.traerUsuario();
    let paises;
    let operadores;
    if(!!user) {
      
      let verTodos = isAllowed(user, Acciones.Catalogos.Operadores.VerTodos);
      if( !verTodos)
        operadores = await OperadorService.traerLista();
      else
        paises = await PaisService.traerLista();
    } 
    setContext({ user, paises, operadores });
    setLoading(false);
  };
  const logout = () => {
    apiClient.post("/logout").then((response) => {
      if (response.status === 204) {
        setContext({ user: undefined });
        setToHome(true);
      }
    });
  };
  if (toHome === true) {
    window.location = "/";
  }
  
  registerLocale('es', es)
  setDefaultLocale('es')
  return <>
    <Cargando activo={loading} mensaje="Cargando perfil de usuario..." />
    <Router>
      <AuthContext.Provider value={context}>
        <MainBarNav
          collapsed={collapsed}
          logout={logout}
          loggedIn={loggedIn}
          handleToggleSidebar={handleToggleSidebar}
        />
        <div className={`app ${rtl ? "rtl" : ""} ${toggled ? "toggled" : ""}`}>
          {loggedIn &&
            <Sidebar
              image={image}
              collapsed={collapsed}
              rtl={rtl}
              toggled={toggled}
              handleToggleSidebar={handleToggleSidebar}
            />
          }
          <main>
            <div style={{ margin: 10 }}>
              <Switch>
                <Route
                  exact
                  path="/"
                  render={(props) => <Inicio {...props} loggedIn={loggedIn} />}
                />
                <Route
                  exact
                  path="/inicio"
                  render={(props) => <Inicio {...props} loggedIn={loggedIn} />}
                />
                <Route
                  path="/catalogos/paisadmin"
                  render={(props) => (
                    <PaisAdmin {...props} loggedIn={loggedIn} />
                  )}
                />
                <Route
                  path="/catalogos/operadoradmin"
                  render={(props) => (
                    <OperadorAdmin {...props}/>
                  )}
                />
                <Route
                  path="/transacciones/certmasteradmin"
                  render={(props) => (
                    <CertMasterAdmin {...props}/>
                  )}
                />
                 <Route
                  path="/transacciones/certtransaccionadmin"
                  render={(props) => (
                    <CertTransaccionAdmin {...props}/>
                  )}
                />
                <Route
                  path="/transacciones/EliminarPF1"
                  render={(props) => (
                    <EliminarPF1 {...props}/>
                  )}
                />
                
                <Route
                  path="/seguridad/roladmin"
                  render={(props) => (
                    <RoleAdmin {...props} loggedIn={loggedIn} />
                  )}
                />
                <Route
                  path="/seguridad/usuarioAdmin"
                  render={(props) => (
                    <UsuarioAdmin {...props} loggedIn={loggedIn} />
                  )}
                />
                <Route
                  path="/seguridad/accionadmin"
                  render={(props) => (
                    <AcccionAdmin {...props} loggedIn={loggedIn} />
                  )}
                />
                <Route
                  path="/login"
                  render={(props) => (
                    <Login
                      {...props}
                      login={cargarUsuario}
                      loggedIn={loggedIn}
                    />
                  )}
                />
              </Switch>
            </div>
          </main>
        </div>
      </AuthContext.Provider>
    </Router>
  </>;
}

export default App;
