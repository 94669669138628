import React, { useContext, useEffect, useState } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import ReactDataGrid from "react-data-grid";
import { Data } from "react-data-grid-addons";
import Cargando from "../comunes/Cargando";
import { handleError } from "services/api";
import { useContentDimensions, useIsMobile } from "hooks/DeviceUtils";
import { FaEdit, FaEye, FaTrash } from "react-icons/fa";
import { isAllowed, useCurrentAllowed } from 'hooks/Auth'
import Encabezado from "components/comunes/Encabezado";
import CustomToolbar from 'components/comunes/CustomToolbar'
import { DateFormatter, DateTimeFormatter } from "utiles/FormatHelper";
import axios from "axios";
import usePaises from "hooks/usePaises";
import CertMasterForm from "./CertMasterForm";
import useOperadores from "hooks/useOperadores";
import CertMasterService from "services/CertMasterService";
import { Acciones, EstadoCertMaster, NormaCertMaster, traerEstadosCertificadoMaster, traerImagenEstadoCertMaster } from "utiles/Constantes";
import { AuthContext } from "context/AuthContext";

const defaultColumnProperties = {
  filterable: true,
  resizable: true,
  sortable: true,
};


const selectors = Data.Selectors;

const handleFilterChange = (filter) => (filters) => {
  const newFilters = { ...filters };
  if (filter.filterTerm) {
    newFilters[filter.column.key] = filter;
  } else {
    delete newFilters[filter.column.key];
  }
  return newFilters;
};

function getRows(rows, filters) {
  return selectors.getRows({ rows, filters });
}
const sortRows = (initialRows, sortColumn, sortDirection) => (rows) => {
  const comparer = (a, b) => {
    if (sortDirection === "ASC") {
      return a[sortColumn] > b[sortColumn] ? 1 : -1;
    } else if (sortDirection === "DESC") {
      return a[sortColumn] < b[sortColumn] ? 1 : -1;
    }
  };
  return sortDirection === "NONE" ? initialRows : [...rows].sort(comparer);
};


export default function CertMasterAdmin() {
  const [mostrarEdicion, setMostrarEdicion] = useState(false);
  const [mostrarMenuEdicion, setMostrarMenuEdicion] = useState(false);
  const [norma, setNorma] = useState("");
  const [consulta, setConsulta] = useState(false);
  const [datos, setDatos] = useState([]);
  const [edicionId, setEdicionId] = useState(0);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({});
  const { paises, paisId, setPaisId, loadingPais: loadingPaises } = usePaises();
  const { operadores, operadorId, setOperadorId, loadingOperador } = useOperadores(paisId);
  const { height, width } = useContentDimensions()
  const isMobile = useIsMobile();
  const acciones = useCurrentAllowed();
  const estados = traerEstadosCertificadoMaster();
  const { user } = useContext(AuthContext);
  const verTodos = isAllowed(user, Acciones.Catalogos.Operadores.VerTodos);



  const getColorEstado = (valor) => {
    if (valor === EstadoCertMaster.EnElaboracion)
      return 'Black';
    if (valor === EstadoCertMaster.Emitido)
      return 'Darkblue';
    if (valor === EstadoCertMaster.Anulado)
      return 'Red';

  }
  const columns = [
    { key: "id", name: "", width: 80, frozen: true, formatter: () => "" },
    {
      key: "norma",
      name: "Norma",
      width: 75,
    },
    {
      key: "estado",
      name: "Estado",
      width: 120,
      formatter: ({ value }) => {

        if (estados[value] !== undefined)
          return <div>
            <img alt={value} src={traerImagenEstadoCertMaster(value)} />
            {" " + estados[value]}
          </div>;
        return value;
      }
    },
    {
      key: "numero",
      name: "Nº",
      width: 40,
      sortDescendingFirst: true,
    },
    {
      key: "numcertificado",
      name: "N° Certificado",
      width: 300,
    },
    {
      key: "certext",
      name: "C / Ext",
      width: 50,
    },
    {
      key: "fechaemision",
      name: "Emisión",
      width: 100,
      formatter: DateFormatter,
    },
    {
      key: "fechavaliddesde",
      name: "Válido Desde",
      width: 120,
      formatter: DateFormatter,
    },
    {
      key: "fechavalidhasta",
      name: "Válido Hasta",
      width: 120,
      formatter: DateFormatter,
    },
    {
      key: "created_at",
      name: "Creación",
      width: 100,
      formatter: DateTimeFormatter,
    },
    {
      key: "updated_at",
      name: "Actualización",
      width: 100,
      formatter: DateTimeFormatter,
    },

  ].map((c) => ({ ...c, ...defaultColumnProperties }));

  async function cargarDatos(cancelToken) {

    if (operadorId === null) return;

    setLoading(true);
    try {
      let result = [];
      if (operadorId > 0)
        result = await CertMasterService.traerListaPorOperadorId(operadorId, cancelToken)
      else
        result = await CertMasterService.traerListaPorPaisId(paisId);

      if (result !== undefined) {
        setDatos(result);
      }
    }
    catch (err) {
      console.error(err);
    }

    setLoading(false);
  };


  useEffect(() => {

    let cancelToken = axios.CancelToken.source();
    cargarDatos(cancelToken);
    return () => {
      cancelToken.cancel("Carga de Certificados Máster cancelada por el usuario.");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paisId, operadorId]);


  const abrirEdicion = (norma, id = 0, modoConsulta=false) => {
    setEdicionId(id);
    setNorma(norma);
    setMostrarEdicion(true);
    setConsulta(modoConsulta);
  }

  const ocultarEdicion = (exito) => {
    if (exito === true) cargarDatos();
    setMostrarEdicion(false);
  };

  const getCellActions = (column, row) => {

    if (column.key === 'id' && row?.id !== 0) {
      let actions = []
      if (acciones && (!acciones.Edita 
        || row.estado === EstadoCertMaster.Anulado))
        actions.push({
          icon: <FaEye style={{ cursor: "pointer" }} />,
          callback: () => {
            abrirEdicion('', row.id, true);
          },
        });
      else if (acciones && acciones.Edita)
        actions.push({
          icon: <FaEdit style={{ cursor: "pointer" }} />,
          callback: () => {
            abrirEdicion('', row.id);
          },
        });
      if (acciones && acciones.Elimina && row.estado === EstadoCertMaster.EnElaboracion)
        actions.push({
          icon: (
            <FaTrash style={{ cursor: "pointer" }} />
          ),
          callback: () => {
            if (
              window.confirm(`Está seguro de eliminar el Certificado Master '${row.numcertificado}'`)
            ) {
              setLoading(true)
              CertMasterService.eliminar(row)
                .then(() => cargarDatos())
                .catch(handleError)
                .finally(() => setLoading(false));
            }
          },
        });

      return actions;
    }

  };

  const filteredRows = getRows(datos, filters);

  if (acciones === undefined)
    return <Cargando activo={true} />

  let titulo = ''
  if(operadores && operadores.length > 0 && operadorId > 0) {
    let operador = operadores.find(x => x.id === operadorId)
    titulo = `${operador.nombre} (${operador.codigo})`
  }
  return (
    <>
      <Encabezado {...acciones} />
      <Modal centered show={mostrarMenuEdicion} onHide={() => setMostrarMenuEdicion(false)}>
        <Modal.Header closeButton>
          <h4>{acciones.Icon} Agregar Certificado Máster</h4>
        </Modal.Header>
        <Modal.Body>
          <div style={{ textAlign: "center" }}>
            <Button variant="primary" onClick={(e) => {
              e.preventDefault();
              abrirEdicion(NormaCertMaster.ComunidadEconomiaEuropea);
              setMostrarMenuEdicion(false);
            }}>CEE</Button> {" "}
            <Button variant="primary" onClick={(e) => {
              e.preventDefault();
              abrirEdicion(NormaCertMaster.EstadosUnidosAmerica);
              setMostrarMenuEdicion(false);
            }} >USA</Button> {" "}
            <Button variant="primary" onClick={(e) => {
              e.preventDefault();
              abrirEdicion(NormaCertMaster.Nacional);
              setMostrarMenuEdicion(false);
            }} >NAL</Button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal size="xl" show={mostrarEdicion} onHide={ocultarEdicion}>
        <Modal.Header closeButton>
          {edicionId === 0 &&
            <h4>{acciones.Icon} Agregar Certificado Máster</h4>
          }
          {edicionId > 0 &&
            <h4>{acciones.Icon} Editar Certificado Máster</h4>
          }
        </Modal.Header>
        <Modal.Body>
          <CertMasterForm consulta={consulta} operadorId={operadorId} paisId={paisId} edicionId={edicionId} norma={norma} cerrar={ocultarEdicion} />
        </Modal.Body>
      </Modal>
      {(!!titulo) && <h5><label>{titulo}</label></h5>}

      <Cargando activo={loading} mensaje='Cargando Certificados...' />
      <Cargando activo={loadingPaises} mensaje='Cargando Paises...' />
      <Cargando activo={loadingOperador} mensaje='Cargando Operadores...' />
      <ReactDataGrid

        columns={columns}
        rowGetter={(i) => filteredRows[i]}
        rowsCount={filteredRows.length}
        enableCellSelect={(acciones && acciones.Edita)}
        toolbar={
          <CustomToolbar
            enableFilter={true}
            enableRefresh={true}
            onAddRow={() => {
              if (operadorId > 0)
                setMostrarMenuEdicion(true);
              else
                alert('Debe selecionar un Operador.');
            }}
            onReloadClick={() => cargarDatos()}
            isMobile={isMobile}
            acciones={acciones}
          >
            <Form inline>
              {verTodos &&
              <Form.Group>
                <Form.Label htmlFor="PaisSelector" srOnly>
                  Pais:
                </Form.Label>
                <Form.Control size={isMobile ? 'sm' : ''} as="select" variant="outline-dark" placeholder="País" id="PaisSelector" onChange={(e) => {
                  setPaisId(e.target.value);
                  setDatos([]);
                  setOperadorId(0);
                }} >
                  {paises.map(x => <option key={x.id} value={x.id}>{x.nombre}</option>)}
                </Form.Control>
              </Form.Group>}
              <Form.Group>
                <Form.Label htmlFor="OperadorSelector" srOnly>
                  Operador:
                </Form.Label>
                <Form.Control size={isMobile ? 'sm' : ''} as="select" variant="outline-dark" placeholder="Operador" id="OperadorSelector" onChange={(e) => setOperadorId(parseInt(e.target.value))} >
                  {(operadores && operadores.length > 0) && operadores.map(x => <option key={x.id} value={x.id}>{x.codigo}</option>)}
                </Form.Control>
              </Form.Group>
            </Form>
          </CustomToolbar>
        }
        onAddFilter={(filter) => setFilters(handleFilterChange(filter))}
        onClearFilters={() => setFilters({})}
        minWidth={width}
        minHeight={height - 35}
        getCellActions={getCellActions}
        onGridSort={(sortColumn, sortDirection) =>
          setDatos(sortRows(datos, sortColumn, sortDirection))
        }
      />
    </>
  );
}


