import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { FcWorkflow } from "react-icons/fc";
import axios from "axios";
import Cargando from "components/comunes/Cargando";
import CertTransaccionService from "services/CertTransaccionService";
import { dateToLocalString } from "utiles/FormatHelper";
import { traerEstadosCertTransaccion } from "utiles/Constantes";
import { FaSync } from "react-icons/fa";

export default function CertTransaccionEstados({
    certId,
    mostrar,
    cerrar
}) {
    const [lista, setLista] = useState([]);
    const [loading, setLoading] = useState(false);
    const estados = traerEstadosCertTransaccion();

    useEffect(() => {
        let cancelToken = axios.CancelToken.source();
        if (mostrar && certId > 0 && lista.length === 0) {
            setLoading(true);
            CertTransaccionService.traerHistEstados(certId, cancelToken)
                .then((result) => {
                    setLista(result);
                    setLoading(false);
                })
                .catch((err) => {
                    setLoading(false);
                });
        };
        return () => cancelToken.cancel();
    }, [certId, lista.length, mostrar]);

    if (!mostrar)
        return <></>;

    return <>
        <Modal size="lg" show={mostrar} onHide={cerrar}>
            <Modal.Header>
                <h4> <FcWorkflow />Histórico de Estados</h4>
            </Modal.Header>
            <Modal.Body>
                <Table size="xs" striped bordered condensed={true} responsive>
                    <thead>
                        <tr>
                            <th>Estado</th>
                            <th>Cambiado por: Correo</th>
                            <th>Cambiado por: Nombre</th>
                            <th>Fecha / Hora</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            lista.map((item) => {
                                return (
                                    <tr key={item.Id}>
                                        <td>{estados[item.estado]}</td>
                                        <td> {item.user.email}</td>
                                        <td> {item.user.name}</td>
                                        <td> {dateToLocalString(item.created_at)}</td>
                                    </tr>)
                            })
                        }
                        {loading &&
                            <tr key={0}>
                                <td colspan="4">Cargando estados...</td>
                            </tr>
                        }
                    </tbody>
                </Table>

                <hr />
                {!loading &&
                    <div className="float-left">
                        <Button variant="primary" onClick={() => setLista([])}>
                            <FaSync /> Recargar
                        </Button>
                    </div>}
                <div className="float-right">
                    <Button variant="secondary" onClick={cerrar}>Cerrar</Button>
                </div>
            </Modal.Body>
        </Modal>
    </>;
}