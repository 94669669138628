import React, { useRef, useState } from "react";
import { Alert, Button, Col, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { FaSave, FaUndo } from "react-icons/fa";
import { getErrorMessage } from "services/api";
import UsuarioService from "services/UsuarioService";

export default function CambiarClave({ usuarioId, cerrar }) {
  const [procesando, setProcesando] = useState(false);
  const { register, watch, errors, handleSubmit, reset } = useForm();
  const [message, setMessage] = useState("");
  const password = useRef({});
  password.current = watch("password", "");

  const onGuardar = (data) => {
    setProcesando(true);
    setMessage("");

    delete data.password_repeat;
    UsuarioService.cambiarClave(usuarioId, data.password)
      .then((exito) => {
        if (exito === true) {
          cerrar(true);
          reset({ });
        } else setProcesando(false);
      })
      .catch((err) => {
        setProcesando(false);
        setMessage(getErrorMessage(err));
      });
  };
  return (
    <>
      <Form onSubmit={handleSubmit(onGuardar)}>
        <Form.Group>
          <Form.Row>
            <Col xs={12} md={6}>
              <Form.Label>Contraseña:</Form.Label>
              <Form.Control
                name="password"
                type="password"
                ref={register({
                  required: "Debe ingresar la contraseña",
                  minLength: {
                    value: 8,
                    message: "Muy corto, debe tener al menos 8 caracteres.",
                  },
                })}
                isInvalid={!!errors.password}
              />
              {errors.password && (
                <small className="text-danger form-text">
                  {errors.password?.message}
                </small>
              )}
            </Col>
            <Col xs={12} md={6}>
              <Form.Label>Confirmar contraseña:</Form.Label>
              <Form.Control
                name="password_repeat"
                type="password"
                ref={register({
                  validate: (value) =>
                    value === password.current ||
                    "Las contraseñas deben coincidir.",
                })}
                isInvalid={!!errors.password_repeat}
              />
              {errors.password_repeat && (
                <small className="text-danger form-text">
                  {errors.password_repeat?.message}
                </small>
              )}
            </Col>
          </Form.Row>
        </Form.Group>
        {message && <Alert variant="danger">{message}</Alert>}
        {!procesando && (
          <div className="float-right">
         
                <Button variant="primary" type="submit">
                  <FaSave /> Guardar
                </Button>{" "}
                <Button variant="secondary" onClick={() => cerrar()}>
                  <FaUndo /> Cancelar
                </Button>
          </div>
        )}
      </Form>
    </>
  );
}
