import { date } from "pizzip/js/defaults";
import apiClient, { handleError } from "./api";
import BaseService from "./BaseService";

export default class CertTransaccionService extends BaseService {
  static getUrlBase = () => "/api/CertTransaccion";

  static async agregar(data) {
    let newCert = await super.agregar(data);

    if (!!newCert) this.formatearFechas(newCert);

    return newCert;
  }
  static async modificar(data) {
    if (!!data) this.formatearFechas(data);
    let cert = await super.modificar(data);
    return cert;
  }
  static async traer(id, cancelToken = null) {
    let certificado = await super.traer(id, cancelToken);

    if (!!certificado) {
      this.formatearFechas(certificado);
    }

    return certificado;
  }
  static async traerListaPorPaisId(paisId, cancelToken) {
    var lista = [];
    await apiClient
      .get(this.getUrlBase() + `/traerListaPorPaisId/${paisId}`, {
        cancelToken: cancelToken?.token,
      })
      .then((response) => {
        lista = response.data;
      })
      .catch(handleError);

    return lista;
  }
  static async traerHistEstados(id, cancelToken) {
    var lista = [];
    await apiClient
      .get(this.getUrlBase() + `/traerHistEstados/${id}`, {
        cancelToken: cancelToken?.token,
      })
      .then((response) => {
        lista = response.data;
      })
      .catch(handleError);

    return lista;
  }
  static async traerListaPorOperadorId(operadorId, cancelToken) {
    var lista = [];
    await apiClient
      .get(this.getUrlBase() + `/traerListaPorOperadorId/${operadorId}`, {
        cancelToken: cancelToken?.token,
      })
      .then((response) => {
        lista = response.data;
      })
      .catch(handleError);

    return lista;
  }
  static async traerListaPRPorOperadorId(operadorId, cancelToken) {
    var lista = [];
    await apiClient
      .get(this.getUrlBase() + `/traerListaPRPorOperadorId/${operadorId}`, {
        cancelToken: cancelToken?.token,
      })
      .then((response) => {
        lista = response.data;
      })
      .catch(handleError);

    return lista;
  }
  static async traerListaPendienteRev(cancelToken) {
    var lista = [];
    await apiClient
      .get(this.getUrlBase() + '/traerListaPendienteRev/0', {
        cancelToken: cancelToken?.token,
      })
      .then((response) => {
        lista = response.data;
      })
      .catch(handleError);

    return lista;
  }
  static async enviar(certTransaccionId) {
    var resultado = false;
    await apiClient
      .get(this.getUrlBase() + `/enviar/${certTransaccionId}`)
      .then((response) => {
        resultado = response.status === 200;
      })
      .catch(handleError);

    return resultado;
  }
  
  static async corregirBorrador(certTransaccionId, correcion) {
    var resultado = false;
    await apiClient
      .post(this.getUrlBase() + `/corregirBorrador/${certTransaccionId}`, { correcion })
      .then((response) => {
        resultado = response.status === 200;
      })
      .catch(handleError);

    return resultado;
  }
  static async regresarEE(certTransaccionId) {
    var resultado = false;
    await apiClient
      .get(this.getUrlBase() + `/regresarEE/${certTransaccionId}`)
      .then((response) => {
        resultado = response.status === 200;
      })
      .catch(handleError);

    return resultado;
  }
  static async borrador(certTransaccionId) {
    var resultado = false;
    await apiClient
      .get(this.getUrlBase() + `/borrador/${certTransaccionId}`)
      .then((response) => {
        resultado = response.status === 200;
      })
      .catch(handleError);

    return resultado;
  }
  static async confirmar(certTransaccionId) {
    var resultado = false;
    await apiClient
      .get(this.getUrlBase() + `/confirmar/${certTransaccionId}`)
      .then((response) => {
        resultado = response.status === 200;
      })
      .catch(handleError);

    return resultado;
  }
  static async generarNumcertificado(certTransaccion) {
    try {
      let response = await apiClient.post(this.getUrlBase() + `/generarNumcertificado/${certTransaccion.id}`, certTransaccion)

      if (response.status === 200) {
        this.formatearFechas(response.data);
        return response.data;
      }
      else if (response.data && response.data.error)
        alert(response.data.error);
    }
    catch (err) {
      console.log(err.response);
      handleError(err);
    }
  }
  static async emitir(certTransaccionId, archivo_id) {
    var resultado = false;
    await apiClient
      .post(this.getUrlBase() + `/emitir/${certTransaccionId}`, { archivo_id })
      .then((response) => {
        resultado = response.status === 200;
      })
      .catch(handleError);

    return resultado;
  }
  static async anular(certTransaccionId, razonnulacion) {
    var resultado = false;
    await apiClient
      .post(this.getUrlBase() + `/anular/${certTransaccionId}`, { razonnulacion })
      .then((response) => {
        resultado = response.status === 200;
      })
      .catch(handleError);

    return resultado;
  }
  static async traerCertTransaccion(id, cancelToken = null) {
    var certTransaccion = await CertTransaccionService.traer(id, cancelToken);
    this.formatearFechas(certTransaccion);
    return certTransaccion;
  }
  static formatearFechas(certTransaccion) {
    if (certTransaccion !== null && certTransaccion !== undefined) {
      if (certTransaccion.fechaemision) {
        let fecha = certTransaccion.fechaemision.toString();
        if (fecha.length === 10) {
          let partes = fecha.split('-');
          certTransaccion.fechaemision = new Date(partes[0], partes[1] - 1, partes[2]);
        }
        else
          certTransaccion.fechaemision = new Date(fecha);
      }
      console.log({ fechaemision: certTransaccion.fechaemision });
    }
  }
}