import apiClient from "./api";

const url = "/api";

class AuthService {
  static async traerUsuario() {
    var user = undefined;
    await apiClient
      .get(url + "/profile")
      .then((userLogged) => {
        if (userLogged.status === 200) {
          user = {
            id: userLogged.data.id,
            name: userLogged.data.name,
            email: userLogged.data.email,
            roles:  userLogged.data.roles,
            rights: userLogged.data.rights
          };
        }
      })
      .catch((error)=>{
        console.error(error)
      });
    return user;
  }
}

export default AuthService;
