import React from "react";
import { Controller } from "react-hook-form";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DatePicker = ({control, name, readOnly, required=false, isInvalid=false}) => {
 return <Controller
            name={name}
            control={control}
            rules={{required}}
            render={({ onChange, value }) => (
                <ReactDatePicker
                    className={"form-control form-control-sm"+ (isInvalid?' is-invalid':'')}
                    selected={value}
                    onChange={onChange}
                    readOnly={readOnly}
                    dateFormat="dd/MM/yyyy"
                />
            )}
        />
};

export default DatePicker;