import apiClient, { handleError } from "./api";

const url = "/api/rol";

class RolService {
  static async traerLista() {
    var lista = [];
    await apiClient
      .get(url)
      .then((response) => {
        lista = response.data.data;
      })
      .catch(handleError);

    return lista;
  }
  static async traerPorUserId(userId) {
    var lista = [];
    await apiClient
      .get(url + `/PorUserId/${userId}`)
      .then((response) => {
        lista = response.data;
      })
      .catch(handleError);
      
    return lista;
  }
  static async agregar(rol) {
    delete rol.id;
    return await apiClient
      .post(url, rol)
      .then(() => {
        return true;
      })
      .catch(handleError);
  }

  static async traer(id, cancelToken = null) {
    var rol = null;
    await apiClient
      .get(`${url}/${id}`, { cancelToken: cancelToken?.token })
      .then((response) => {
        rol = response.data;
      })
      .catch(handleError);
    return rol;
  }

  static async modificar(rol) {
    return await apiClient
      .put(`${url}/${rol.id}`, rol)
      .then(() => {
        return true;
      })
      .catch(handleError);
  }

  static async eliminar(rol) {
    await apiClient
      .delete(`${url}/${rol.id}`)
      .then(() => {
        return true;
      })
      .catch(handleError);
  }
}

export default RolService;
