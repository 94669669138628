import React, { useContext, useEffect, useState } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import ReactDataGrid from "react-data-grid";
import { Data } from "react-data-grid-addons";
import Cargando from "../comunes/Cargando";
import { getErrorMessage, handleError } from "services/api";
import { useContentDimensions, useIsMobile } from "hooks/DeviceUtils";
import { FaEdit, FaEye, FaTrash } from "react-icons/fa";
import { isAllowed, useCurrentAllowed } from 'hooks/Auth'
import Encabezado from "components/comunes/Encabezado";
import CustomToolbar from 'components/comunes/CustomToolbar'
import { DateTimeFormatter } from "utiles/FormatHelper";
import axios from "axios";
import usePaises from "hooks/usePaises";
import useOperadores from "hooks/useOperadores";
import CertMasterService from "services/CertMasterService";
import { Acciones, EstadoCertTransaccion, FormatoCertTransaccion, traerColorEstadoCertTransaccion, traerEstadosCertTransaccion, traerImagenEstadoCertTransaccion } from "utiles/Constantes";
import CertTransaccionService from "services/CertTransaccionService";
import CertTransaccionForm from "./CertTransaccionForm";
import { AuthContext } from "context/AuthContext";
import { GrFormView, GrView } from "react-icons/gr";

const defaultColumnProperties = {
  filterable: true,
  resizable: true,
  sortable: true,
};


const selectors = Data.Selectors;

const handleFilterChange = (filter) => (filters) => {
  const newFilters = { ...filters };
  if (filter.filterTerm) {
    newFilters[filter.column.key] = filter;
  } else {
    delete newFilters[filter.column.key];
  }
  return newFilters;
};

const sortRows = (initialRows, sortColumn, sortDirection) => (rows) => {
  const comparer = (a, b) => {
    if (sortDirection === "ASC") {
      return a[sortColumn] > b[sortColumn] ? 1 : -1;
    } else if (sortDirection === "DESC") {
      return a[sortColumn] < b[sortColumn] ? 1 : -1;
    }
  };
  return sortDirection === "NONE" ? initialRows : [...rows].sort(comparer);
};


export default function CertTransaccionAdmin() {
  const [mostrarEdicion, setMostrarEdicion] = useState(false);
  const [mostrarMenuEdicion, setMostrarMenuEdicion] = useState(false);
  const [formato, setFormato] = useState("");
  const [consulta, setConsulta] = useState(false);
  const [datos, setDatos] = useState([]);
  const [edicionId, setEdicionId] = useState(0);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({});
  const [estadosSel, setEstadosSel] = useState(0); 
  const [estadosPR, setEstadosPR] = useState([EstadoCertTransaccion.EnElaboracion]);
  const estados = traerEstadosCertTransaccion();
  const { paises, paisId, setPaisId, loadingPais: loadingPaises } = usePaises();
  const { operadores, operadorId, setOperadorId, loadingOperador } = useOperadores(paisId);
  const { height, width } = useContentDimensions()
  const isMobile = useIsMobile();
  const acciones = useCurrentAllowed();
  const { user } = useContext(AuthContext);
  const verTodos = isAllowed(user, Acciones.Catalogos.Operadores.VerTodos);

  const columns = [
    { key: "id", name: "", width: 80, frozen: true, formatter: () => "" },
    {
      key: "estado",
      name: "Estado",
      width: 140,
      formatter: ({ value }) => {

        if (estados[value] !== undefined)
          return <div>
            <img alt={value} src={traerImagenEstadoCertTransaccion(value)} />
            {" " + estados[value]}
          </div>;
        return value;
      }
    },
    {
      key: "formato",
      name: "Formato",
      width: 70,
    },
    {
      key: "numcertificado",
      name: "N° Certificado",
      width: 100,
    },
    {
      key: "nombrevendedor",
      name: "Vendedor",
      width: 150,
    },
    {
      key: "nombreproductor",
      name: "Productor",
      width: 150,
      sortDescendingFirst: true,
    },
    {
      key: "denominacionproducto",
      name: "Producto",
      width: 150,
    },
    {
      key: "numfactura",
      name: "Nº Factura",
      width: 100,
    },
    {
      key: "lote",
      name: "Lote",
      width: 100,
    },
    {
      key: "pesobruto",
      name: "Peso bruto, Kg",
      width: 120,
    },
    {
      key: "pesoneto",
      name: "Peso neto, Kg",
      width: 120,
    },
    {
      key: "created_at",
      name: "Creación",
      width: 100,
      formatter: DateTimeFormatter,
    },
    {
      key: "updated_at",
      name: "Actualización",
      width: 100,
      formatter: DateTimeFormatter,
    },

  ].map((c) => ({ ...c, ...defaultColumnProperties }));

  async function cargarDatos(cancelToken) {

    if (operadorId === null) return;

    setLoading(true);
    try {
      let result = [];
      if (operadorId > 0) {
        if(estadosSel == 0)
          result = await CertTransaccionService.traerListaPendienteRev(cancelToken);
        else
          result = await CertTransaccionService.traerListaPorOperadorId(operadorId, cancelToken)
      }
      else
        result = []; //await CertTransaccionService.traerListaPorPaisId(paisId);

      if (result !== undefined) {
        setDatos(result);
      }
    }
    catch (err) {
      console.error(err);
    }

    setLoading(false);
  };
   
  useEffect(() => {

    let cancelToken = axios.CancelToken.source();
    cargarDatos(cancelToken);

    return () => {
      cancelToken.cancel("Carga de Certificados de Transacción cancelada por el usuario.");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paisId, operadorId, estadosSel]);


  const abrirEdicion = (norma, id = 0, modoConsulta=false) => {
    setEdicionId(id);
    setFormato(norma);
    setMostrarEdicion(true);
    setConsulta(modoConsulta);
  }

  const ocultarEdicion = (exito) => {
    if (exito === true)
      cargarDatos();
    else if (exito !== false && !window.confirm('¿Está seguro que desea cerrar?'))
      return
    setMostrarEdicion(false);
  };

  const getCellActions = (column, row) => {

    if (column.key === 'id' && row?.id !== 0) {
      let actions = []
      
      if (acciones && (!acciones.Edita || row.estado === EstadoCertTransaccion.Emitido || row.estado === EstadoCertTransaccion.Anulado))
        actions.push({
          icon: <FaEye style={{ cursor: "pointer" }} />,
          callback: () => {
            abrirEdicion('', row.id, true);
          },
        });
      else if (acciones && acciones.Edita)
        actions.push({
          icon: <FaEdit style={{ cursor: "pointer" }} />,
          callback: () => {
            abrirEdicion('', row.id);
          },
        });
      if (acciones && acciones.Elimina && row.estado === EstadoCertTransaccion.EnElaboracion)
        actions.push({
          icon: (
            <FaTrash style={{ cursor: "pointer" }} />
          ),
          callback: async () => {
            if (
              window.confirm(`¿Está seguro de eliminar el Certificado?`)
            ) {
              try {
                setLoading(true)
                if (await CertTransaccionService.eliminar(row))
                  cargarDatos();
              }
              catch (err) { 
                window.alert(getErrorMessage(err));
              }
              finally{
                setLoading(false)
              };
            }
          },
        });

      return actions;
    }

  };

  const filteredRows = getRows(datos, filters);

  function getRows(rows, filters) {
  
    return selectors.getRows({ rows, filters });
  }
  if (acciones === undefined)
    return <Cargando activo={true} />

  let titulo = ''
  if (operadores && operadores.length > 0 && operadorId > 0) {
    let operador = operadores.find(x => x.id === operadorId)
    if(operador)
      titulo = `${operador?.nombre} (${operador?.codigo})`
  }
  return (
    <>
      <Encabezado {...acciones} />
      <Modal centered show={mostrarMenuEdicion} onHide={() => setMostrarMenuEdicion(false)}>
        <Modal.Header closeButton>
          <h4>{acciones.Icon} Agregar Certificado de Transacción</h4>
        </Modal.Header>
        <Modal.Body>
          <div style={{ textAlign: "center" }}>
            <Button variant="primary" onClick={(e) => {
              e.preventDefault();
              abrirEdicion(FormatoCertTransaccion.COI);
              setMostrarMenuEdicion(false);
            }}>COI</Button> {" "}
            <Button variant="primary" onClick={(e) => {
              e.preventDefault();
              abrirEdicion(FormatoCertTransaccion.FD2);
              setMostrarMenuEdicion(false);
            }} >FD2</Button> {" "}
            <Button variant="primary" onClick={(e) => {
              e.preventDefault();
              abrirEdicion(FormatoCertTransaccion.FD3);
              setMostrarMenuEdicion(false);
            }} >FD3</Button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal size="xl" show={mostrarEdicion} onHide={ocultarEdicion}>
        <Modal.Header closeButton>
          {edicionId === 0 &&
            <h4>{acciones.Icon} Agregar Certificado de Transacción</h4>
          }
          {edicionId > 0 &&
            <h4>{acciones.Icon} Editar Certificado de Transacción</h4>
          }
        </Modal.Header>
        <Modal.Body>
          <CertTransaccionForm consulta={consulta} operadorId={operadorId} paisId={paisId} edicionId={edicionId} formato={formato} cerrar={ocultarEdicion} />
        </Modal.Body>
      </Modal>
      {(!!titulo) && <h5><label>{titulo}</label></h5>}

      <Cargando activo={loading} mensaje='Cargando Certificados...' />
      <Cargando activo={loadingPaises} mensaje='Cargando Paises...' />
      <Cargando activo={loadingOperador} mensaje='Cargando Operadores...' />
      <ReactDataGrid

        columns={columns}
        rowGetter={(i) => filteredRows[i]}
        rowsCount={filteredRows.length}
        enableCellSelect={(acciones && acciones.Edita)}
        toolbar={
          <CustomToolbar
            enableFilter={true}
            enableRefresh={true}
            onAddRow={() => {
              if (operadorId > 0)
                setMostrarMenuEdicion(true);
              else
                alert('Debe selecionar un Operador.');
            }}
            onReloadClick={() => cargarDatos()}
            isMobile={isMobile}
            acciones={acciones}
          >
            <Form inline>
              <Form.Group>
                <Form.Label htmlFor="EstadoSelector" srOnly>
                  Pais:
                </Form.Label>
                <Form.Control size={isMobile ? 'sm' : ''} as="select" variant="outline-dark" id="EstadoSelector" onChange={(e) => {
                  setEstadosSel(e.target.value);
                }} >
                   <option key={0} value={0}>Bandeja de Entrada</option>
                   <option key={1} value={1}>Todos</option>
                </Form.Control>
              </Form.Group>
              {verTodos && (estadosSel != 0) &&
                <Form.Group>
                  <Form.Label htmlFor="PaisSelector" srOnly>
                    Pais:
                  </Form.Label>
                  <Form.Control size={isMobile ? 'sm' : ''} as="select" variant="outline-dark" placeholder="País" id="PaisSelector" onChange={(e) => {
                    setPaisId(e.target.value);
                    setDatos([]);
                    setOperadorId(0);
                  }} >
                    {paises.map(x => <option key={x.id} value={x.id}>{x.nombre}</option>)}
                  </Form.Control>
                </Form.Group>}
              { (estadosSel != 0) &&
              <Form.Group>
                <Form.Label htmlFor="OperadorSelector" srOnly>
                  Operador:
                </Form.Label>
                <Form.Control size={isMobile ? 'sm' : ''} as="select" variant="outline-dark" placeholder="Operador" id="OperadorSelector" onChange={(e) => setOperadorId(parseInt(e.target.value))} >
                  {(operadores && operadores.length > 0) && operadores.map(x => <option key={x.id} value={x.id}>{x.codigo}</option>)}
                </Form.Control>
              </Form.Group>}
            </Form>
          </CustomToolbar>
        }
        onAddFilter={(filter) => setFilters(handleFilterChange(filter))}
        onClearFilters={() => setFilters({})}
        minWidth={width}
        minHeight={height - 35}
        getCellActions={getCellActions}
        onGridSort={(sortColumn, sortDirection) =>
          setDatos(sortRows(datos, sortColumn, sortDirection))
        }
      />
    </>
  );
}


