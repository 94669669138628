import React, { useEffect, useState } from "react";
import { Modal, Form} from "react-bootstrap";
import ReactDataGrid from "react-data-grid";
import { Data } from "react-data-grid-addons";
import Cargando from "../comunes/Cargando";
import UsuarioService from "services/UsuarioService";
import { handleError } from "services/api";
import { useContentDimensions, useIsMobile } from "hooks/DeviceUtils";
import { FaEdit, FaTrash } from "react-icons/fa";
import { CgPassword } from "react-icons/cg";
import { useCurrentAllowed } from 'hooks/Auth'
import Encabezado from "components/comunes/Encabezado";
import CustomToolbar from 'components/comunes/CustomToolbar'
import RolService from "services/RolService";
import UsuarioForm from './UsuarioForm'
import { DateTimeFormatter } from "utiles/FormatHelper";
import axios from "axios";
import CambiarClave from "./CambiarClave";

const defaultColumnProperties = {
  filterable: true,
  resizable: true,
  sortable: true,
};

const defaultValue = {
  id: 0,
  name: "Inicializando..."
};

const columns = [
  { key: "id", name: "", width: 120, frozen: true, formatter: () => "" },
  {
    key: "code",
    name: "Siglas",
    width: 80,
  },
  {
    key: "name",
    name: "Nombre",
    width: 200,
    sortDescendingFirst: true,
  },
  {
    key: "info",
    name: "Descripción",
    width: 150,
  },
  {
    key: "email",
    name: "Correo",
    width: 200,
  },
  {
    key: "created_at",
    name: "Fecha Creación",
    width: 170,
    formatter:  DateTimeFormatter,
  },
  {
    key: "updated_at",
    name: "Fecha Actualización",
    width: 170,
    formatter:  DateTimeFormatter,
  },

].map((c) => ({ ...c, ...defaultColumnProperties }));

const selectors = Data.Selectors;

const handleFilterChange = (filter) => (filters) => {
  const newFilters = { ...filters };
  if (filter.filterTerm) {
    newFilters[filter.column.key] = filter;
  } else {
    delete newFilters[filter.column.key];
  }
  return newFilters;
};

function getRows(rows, filters) {
  return selectors.getRows({ rows, filters });
}
const sortRows = (initialRows, sortColumn, sortDirection) => (rows) => {
  const comparer = (a, b) => {
    if (sortDirection === "ASC") {
      return a[sortColumn] > b[sortColumn] ? 1 : -1;
    } else if (sortDirection === "DESC") {
      return a[sortColumn] < b[sortColumn] ? 1 : -1;
    }
  };
  return sortDirection === "NONE" ? initialRows : [...rows].sort(comparer);
};

function UsuarioAdmin() {
  const [mostrarEdicion, setMostrarEdicion] = useState(false);
  const [mostrarCambiar, setMostrarCambiar] = useState(false);
  const [usuarios, setUsuarios] = useState([defaultValue]);
  const [usuarioId, setUsuarioId] = useState(0);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({});
  const [roles, setRoles] = useState([]);
  const [rolId, setRolId] = useState(null);
  const { height, width } = useContentDimensions()
  const isMobile = useIsMobile();
  const acciones = useCurrentAllowed();

  function cargarDatos (id = rolId, cancelToken) {
   
    if(id === null) return;

    setLoading(true);
    UsuarioService.traerListaPorRolId(id, cancelToken)
      .then((result) => {
        if (result !== undefined) 
        {
          setUsuarios(result);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };
  useEffect(() => {
    setLoading(true);
    RolService.traerLista()
      .then((result) => {
        if (result !== undefined) {
          setRoles(result);
          if(result.length > 0)
            setRolId(result[0].id);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    
    let cancelToken = axios.CancelToken.source();
    cargarDatos(rolId, cancelToken);
    return () => {
      cancelToken.cancel("Carga de Usuario cancelada por el usuario.");
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rolId]);
  const onGridRowsUpdated = ({ fromRow, toRow, updated }) => {
    const rows = usuarios.slice();
    for (let i = fromRow; i <= toRow; i++) {
      rows[i] = { ...rows[i], ...updated };
      setLoading(true);
      UsuarioService.modificar(rows[i])
        .then(() => {
          setUsuarios(rows);
          setLoading(false);
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
        });
    }
  };

  const abrirEdicion = (id = 0) => {
    setUsuarioId(id);
    setMostrarEdicion(true);
  }

  const ocultarEdicion = (exito) => {
    if (!!exito) cargarDatos();
    setMostrarEdicion(false);
  };

  const getCellActions = (column, row) => {
    
    if (column.key === 'id' && row?.id !== 0) {
      let actions = []
      if (acciones && acciones.Edita)
        actions.push({
          icon: <FaEdit style={{ cursor: "pointer" }} />,
          callback: () => {
            abrirEdicion(row.id);
          },
        });
      if (acciones && acciones.Elimina)
        actions.push({
          icon: (
            <FaTrash style={{ cursor: "pointer" }} />
          ),
          callback: () => {
            if (
              window.confirm(`Está seguro de eliminar el Usuario '${row.name}'`)
            )
              UsuarioService.eliminar(row)
                .then(() => cargarDatos())
                .catch(handleError);
          },
        });
      //if(acciones && acciones.CambiaClave)
        actions.push( {
          icon: <CgPassword style={{ cursor: "pointer" }} />,
          callback: () => {
            setUsuarioId(row.id);
            setMostrarCambiar(true);
          },
        })
      return actions;
    }
    
  };

  const filteredRows = getRows(usuarios, filters);
  
  if(acciones === undefined)
    return <Cargando activo={true} />
    

  return (
    <>
     <Encabezado {...acciones} />
      <Modal show={mostrarEdicion} onHide={ocultarEdicion}>
        <Modal.Header closeButton>
          {usuarioId === 0 &&
           <h4>{acciones.Icon} Agregar Usuario</h4>
           }
           {usuarioId > 0 &&
           <h4>{acciones.Icon} Editar Usuario</h4>
           }
        </Modal.Header>
        <Modal.Body>
          <UsuarioForm rolId={rolId} usuarioId={usuarioId} cerrar={ocultarEdicion} />
        </Modal.Body>
      </Modal>
      <Modal show={mostrarCambiar} onHide={()=> setMostrarCambiar(false)}>
        <Modal.Header closeButton>
           <h4><CgPassword /> Cambiar Contraseña</h4>
        </Modal.Header>
        <Modal.Body>
          <CambiarClave usuarioId={usuarioId} cerrar={()=> setMostrarCambiar(false)} />
        </Modal.Body>
      </Modal>
      <ReactDataGrid
        columns={columns}
        rowGetter={(i) => filteredRows[i]}
        rowsCount={filteredRows.length}
        enableCellSelect={ (acciones && acciones.Edita)}
        toolbar={
          <CustomToolbar 
            enableFilter={true}
            enableRefresh={true}
            onAddRow={()=> abrirEdicion()}
            onReloadClick={()=> cargarDatos()}
            isMobile={isMobile}
            acciones={acciones}
          >           
          <Cargando activo={loading} /> 
            <Form inline>
              <Form.Label htmlFor="RolSelector" srOnly>
                Rol
              </Form.Label>
              <Form.Control as="select" variant="outline-dark" id="RolSelector" onChange={(e) => setRolId(e.target.value) } >
                {roles.map(x=><option key={x.id} value={x.id}>{x.nombre}</option>)}
              </Form.Control>
            </Form>          
          </CustomToolbar>
        }
        onGridRowsUpdated={onGridRowsUpdated}
        onAddFilter={(filter) => setFilters(handleFilterChange(filter))}
        onClearFilters={() => setFilters({})}
        minWidth={width}
        minHeight={height - 20}
        getCellActions={getCellActions}
        onGridSort={(sortColumn, sortDirection) =>
          setUsuarios(sortRows(usuarios, sortColumn, sortDirection))
        }
      />
    </>
  );
}

export default UsuarioAdmin;
