import { dateToLocalString } from "utiles/FormatHelper";
import apiClient, { handleError } from "./api";
import BaseService from "./BaseService";

export default class CertMasterService extends BaseService {
  static getUrlBase = () => "/api/certmaster";

  static async agregar(data) {
    let newCert = await super.agregar(data);

    if (!!newCert) this.formatearFechas(newCert);

    return newCert;
  }
  static async traerListaPorPaisId(paisId, cancelToken) {
    var lista = [];
    await apiClient
      .get(this.getUrlBase() + `/traerListaPorPaisId/${paisId}`, {
        cancelToken: cancelToken?.token,
      })
      .then((response) => {
        lista = response.data;
      })
      .catch(handleError);

    return lista;
  }
  static async traerListaPorOperadorId(operadorId, cancelToken) {
    var lista = [];
    await apiClient
      .get(this.getUrlBase() + `/traerListaPorOperadorId/${operadorId}`, {
        cancelToken: cancelToken?.token,
      })
      .then((response) => {
        lista = response.data;
      })
      .catch(handleError);

    return lista;
  }
  static async traerListaVigentePorOperadorId(operadorId, norma, cancelToken) {
    var lista = [];
    await apiClient
      .post(this.getUrlBase() + `/traerListaVigentePorOperadorId/${operadorId}`, { norma }, {
        cancelToken: cancelToken?.token,
      })
      .then((response) => {
        lista = response.data;
      })
      .catch(handleError);

    return lista;
  }
  static async emitir(certMasterId) {
    var resultado = false;
    await apiClient
      .get(this.getUrlBase() + `/emitir/${certMasterId}`)
      .then((response) => {
        resultado = response.status === 200;
      })
      .catch(handleError);

    return resultado;
  }
  static async anular(certMasterId, razonnulacion) {
    var resultado = false;
    await apiClient
      .post(this.getUrlBase() + `/anular/${certMasterId}`, { razonnulacion })
      .then((response) => {
        resultado = response.status === 200;
      })
      .catch(handleError);

    return resultado;
  }
  static async traerCertMaster(id, cancelToken = null) {
    var certMaster = await CertMasterService.traer(id, cancelToken);
    this.formatearFechas(certMaster);
    return certMaster;
  }
  static formatearFechas(certMaster) {
    if (certMaster !== null && certMaster !== undefined) {
      if (certMaster.fechacontrol)
        certMaster.fechacontrol = new Date(certMaster.fechacontrol);
      if (certMaster.fechacertificacion)
        certMaster.fechacertificacion = new Date(certMaster.fechacertificacion);
      if (certMaster.fechaemision)
        certMaster.fechaemision = new Date(certMaster.fechaemision);
      if (certMaster.fechavaliddesde)
        certMaster.fechavaliddesde = new Date(certMaster.fechavaliddesde);
      if (certMaster.fechavalidhasta)
        certMaster.fechavalidhasta = new Date(certMaster.fechavalidhasta);
      if (certMaster.usafechaefectiva)
        certMaster.usafechaefectiva = new Date(certMaster.usafechaefectiva);
      if (certMaster.usafechaaniversario)
        certMaster.usafechaaniversario = new Date(certMaster.usafechaaniversario);
    }
  }
}