import React, { useEffect, useState } from "react";
import axios from "axios";
import Cargando from "../comunes/Cargando";
import Button from "react-bootstrap/Button";
import { useForm } from "react-hook-form";
import { Alert, Col, Form } from "react-bootstrap";
import { getErrorMessage, handleError } from "services/api";
import { FaFileWord, FaSave, FaTimes, FaUndo } from "react-icons/fa";
import ErrorMessage from "components/comunes/ErrorMessage";
import ArchivoService from "services/ArchivoService";

const toBase64 = (file) => new Promise<string | ArrayBuffer>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

export default function ArchivoForm({
    edicionId = 0,
    cerrar,
    consulta = false,
    referencia,
    campo
}) {
    const [procesando, setProcesando] = useState(false);
    const [id, setId] = useState(edicionId);
    const [mensaje, setMensaje] = useState("");
    const [Archivo, setArchivo] = useState(null);



    const guardar = async (e) => {
        try {
            setProcesando(true);
            setMensaje("Guardando...");
            let archivoNuevo = null;
            
            Archivo.referencia = referencia;
            Archivo.campo = campo;
            archivoNuevo = await ArchivoService.agregar(Archivo);
            if (!!archivoNuevo) {
                setId(archivoNuevo.id);
                setMensaje("Archivo guardado...");
                cerrar(archivoNuevo);
            }
            else {
                setMensaje("Ha ocurrido un error inesperado, recargue la ventana del navegador y vuelva a intentarlo.");
            }
        }
        catch (err) {
           setMensaje(getErrorMessage(err));
           handleError(err);
        }
        finally {
            setProcesando(false);
        }
    };

    const onFileChange = async(e) => {
        if (e.target.files.length === 0) {
            setArchivo(null);
            return;
        }
        let file = e.target.files[0];

        let archivo = {
            nombre: file.name,
            tipo: file.type,
            tamanio: file.size
        };
        setArchivo(archivo);
        let error = validarTamanio(archivo);

        setMensaje(error);
        if (!error) {
            setMensaje('Leyendo archivo...');

            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                archivo.contenido = reader.result;
                setMensaje('Archivo leído con éxito.');
            };
            reader.onerror = error => {
                setMensaje('Ocurrió un error al leer el archivo');
                console.error(error);
            }

        }
    }

    const validarTamanio = (archivo = null) => {
        const tamanioMax = 5;
        let result = ''
        if (archivo === null)
            archivo = Archivo;
        if (archivo?.tamanio > (1048576*tamanioMax)) 
            result = 'Tamaño máximo permitido es de ' + tamanioMax + 'MB';

        return result;
    }
    const traerTamanio = () => {
        if (Archivo)
            return (Archivo.tamanio / (1024 * 1024)).toFixed(2) + " MB";
    }
    return (
        <>
            <Form>
                <Form.Row>
                    <Col xs={12} md={12}>
                        <Form.Group controlId="formato">
                            <Form.Label>Archivo:</Form.Label>
                            <Form.Control
                                name="archivo"
                                type="file"
                                size="sm"
                                onChange={onFileChange}
                            >

                            </Form.Control> 
                        </Form.Group>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col xs={12} md={12}>
                        <Form.Group controlId="nombrevendedor">
                            <Form.Label>Nombre:</Form.Label>

                            <Form.Control
                                name="nombre"
                                type="text"
                                readOnly={true}
                                size="sm"
                                value={Archivo?.nombre}
                            />
                        </Form.Group>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col xs={12} md={12}>
                        <Form.Group controlId="nombrevendedor">
                            <Form.Label>Tamaño:</Form.Label>

                            <Form.Control
                                name="tamanio"
                                maxLength={500}
                                type="text"
                                isInvalid={!!validarTamanio()}
                                readOnly={true}
                                size="sm"
                                value={traerTamanio()}
                            />
                        </Form.Group>
                    </Col>
                </Form.Row>

                <Cargando activo={procesando} mensaje={mensaje} />
                {mensaje && <Alert variant="info">{mensaje}</Alert>}
                {!(procesando) && (
                    <>
                        <div className="float-right">
                            <Button disabled={!Archivo?.contenido} id="btnGuardar" variant="primary" type="button" onClick={(e)=>guardar()} >
                                <FaSave id="svgGuardar" /> Guardar
                            </Button>{" "}
                            <Button variant="secondary" onClick={() => cerrar(false)}>
                                <FaUndo /> Cancelar
                            </Button>
                        </div>
                    </>
                )}
            </Form>
        </>
    );
}
