export const DateTimeFormatter = ({ value }) => {
    return dateToLocalString(value);
  };
export const DateFormatter = ({ value }) => {
    return dateToLocalDateString(value);
  };
  export function dateToLocalString(value) {
    if(isDate(value))  {
        let date = new Date(value);
        return date.toLocaleString();
    }
    else 
        return value;
  }
  export function dateToLocalDateString(value) {
    if(isDate(value))  {
        let date = new Date(value);
        return date.toLocaleDateString();
    }
    else 
        return value;
  }
  export function dateToCode(value){ 
    var d = new Date(value),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear()%100;

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [day, month, year].join('');

}
export function padLeft(value, length) {
    return (value.toString().length < length) ? padLeft("0" + value, length) : 
    value;
}
  export function isDate(value) {
    switch (typeof value) {
        case 'number':
            return true;
        case 'string':
            return !isNaN(Date.parse(value));
        case 'object':
            if (value instanceof Date) 
                return !isNaN(value.getTime());
            return false;
        default:
            return false;
    }
}
