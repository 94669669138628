import apiClient, { handleError } from "./api";
import BaseService from "./BaseService";

class UsuarioService extends BaseService {
  static getUrlBase = () => "/api/usuario";

  static async traerListaPorRolId(rolId, cancelToken) {
    var lista = [];
    await apiClient
      .get(this.getUrlBase() + "/traerlistaporrolid/" + rolId, {
        cancelToken: cancelToken?.token,
      })
      .then((response) => {
        lista = response.data;
      })
      .catch(handleError);

    return lista;
  }

  static async cambiarClave(id, password) {
    return await apiClient
      .post(this.getUrlBase() + `/cambiarclave/${id}`, { password })
      .then((response) => {
        return (!!response && response?.status === 200)
      })
      .catch(handleError);
  }
  static async existeCorreo(id, email) {
    return await apiClient
      .post(this.getUrlBase() + `/existeCorreo/${id}`, { email })
      .then((response) => {
        return (response?.data)
      })
      .catch(handleError);
  }
  static async traerListaPorRolCod(codigo, cancelToken = null) {
    return await apiClient
      .get(this.getUrlBase() + `/traerListaPorRol/${codigo}`,  {
        cancelToken: cancelToken?.token,
      })
      .then((response) => {
        return (response?.data)
      })
      .catch(handleError);
  }
  static async traerListaPorRolTexto(codigo, texto, cancelToken = null) {
    return await apiClient
      .post(this.getUrlBase() + `/traerListaPorRolTexto/${codigo}`, {texto}, {
        cancelToken: cancelToken?.token,
      })
      .then((response) => {
        return (response?.data)
      })
      .catch(handleError);
  }
}

export default UsuarioService;
