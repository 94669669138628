import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import ReactDataGrid from "react-data-grid";
import { Data, Editors } from "react-data-grid-addons";
import Cargando from "../comunes/Cargando";
import RolService from "services/RolService";
import { handleError } from "services/api";
import RolForm from "./RolForm";
import { useContentDimensions, useIsMobile } from "hooks/DeviceUtils";
import { FaEdit, FaTrash } from "react-icons/fa";
import { useCurrentAllowed } from 'hooks/Auth';
import Encabezado from "components/comunes/Encabezado";
import CustomToolbar from "components/comunes/CustomToolbar";
import { DateTimeFormatter } from "utiles/FormatHelper";

const defaultColumnProperties = {
  filterable: true,
  resizable: true,
  sortable: true,
};

const defaultValue = {
  id: 0,
  nombre: "Inicializando...",
  activo: false,
};
const { DropDownEditor } = Editors;
const ActivoOptions = [
  { id: "Activo", value: "1" },
  { id: "Inactivo", value: "0" },
];

const columns = [
  { key: "id", name: "", width: 80, frozen: true, formatter: () => "" },
  {
    key: "nombre",
    name: "Nombre",
    editable: true,
    width: 180,
    sortDescendingFirst: true,
  },
  {
    key: "activo",
    name: "Activo",
    editable: true,
    width: 60,
    editor: <DropDownEditor options={ActivoOptions} />,
  },
  {
    key: "created_at",
    name: "Fecha Creación",
    width: 160,
    formatter:  DateTimeFormatter,
  },
  {
    key: "updated_at",
    name: "Fecha Actualización",
    width: 160,    
    formatter:  DateTimeFormatter,
  }

].map((c) => ({ ...c, ...defaultColumnProperties }));

const selectors = Data.Selectors;

const handleFilterChange = (filter) => (filters) => {
  const newFilters = { ...filters };
  if (filter.filterTerm) {
    newFilters[filter.column.key] = filter;
  } else {
    delete newFilters[filter.column.key];
  }
  return newFilters;
};

function getRows(rows, filters) {
  return selectors.getRows({ rows, filters });
}
const sortRows = (initialRows, sortColumn, sortDirection) => (rows) => {
  const comparer = (a, b) => {
    if (sortDirection === "ASC") {
      return a[sortColumn] > b[sortColumn] ? 1 : -1;
    } else if (sortDirection === "DESC") {
      return a[sortColumn] < b[sortColumn] ? 1 : -1;
    }
  };
  return sortDirection === "NONE" ? initialRows : [...rows].sort(comparer);
};

function RolAdmin() {
  const [mostrarEdicion, setMostrarEdicion] = useState(false);
  const [roles, setRoles] = useState([defaultValue]);
  const [rolId, setRolId] = useState(0);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({});
  const { height, width } = useContentDimensions()
  const isMobile = useIsMobile();
  const acciones = useCurrentAllowed();

  const CargarDatos = () => {
    setLoading(true);
    RolService.traerLista()
      .then((result) => {
        if (result !== undefined) {
          setRoles(result);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    CargarDatos();
  }, []);

  const onGridRowsUpdated = ({ fromRow, toRow, updated }) => {
    const rows = roles.slice();
    for (let i = fromRow; i <= toRow; i++) {
      rows[i] = { ...rows[i], ...updated };
      setLoading(true);
      RolService.modificar(rows[i])
        .then(() => {
          setRoles(rows);
          setLoading(false);
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
        });
    }
  };

  const abrirEdicion = (id = 0) => {
    setRolId(id);
    setMostrarEdicion(true);
  }

  const ocultarEdicion = (exito) => {
    if (exito === true) CargarDatos();
    setMostrarEdicion(false);
  };

  const getCellActions = (column, row) => {

    if (column.key === 'id' && row?.id !== 0) {
      let actions = []
      if (acciones && acciones.Edita)
        actions.push({
          icon: <FaEdit style={{ cursor: "pointer" }} />,
          callback: () => {
            abrirEdicion(row.id);
          },
        });
      if (acciones && acciones.Elimina)
        actions.push({
          icon: (
            <FaTrash style={{ cursor: "pointer" }} />
          ),
          callback: () => {
            if (
              window.confirm(`Está seguro de eliminar el Rol '${row.nombre}'`)
            )
              RolService.eliminar(row)
                .then(() => CargarDatos())
                .catch(handleError);
          },
        });
      return actions;
    }

  };

  const filteredRows = getRows(roles, filters);

  if (acciones === undefined)
    return <Cargando activo={true} />


  return (
    <>
      <Encabezado {...acciones} />
      <Modal show={mostrarEdicion} onHide={ocultarEdicion}>
        <Modal.Header closeButton>
          {rolId === 0 &&
            <h3>{acciones.Icon} Agregar Rol</h3>
          }
          {rolId > 0 &&
            <h3>{acciones.Icon} Editar Rol</h3>
          }
        </Modal.Header>
        <Modal.Body>
          <RolForm rolId={rolId} cerrar={ocultarEdicion} />
        </Modal.Body>
      </Modal>

      <ReactDataGrid
        columns={columns}
        rowGetter={(i) => filteredRows[i]}
        rowsCount={filteredRows.length}
        enableCellSelect={(acciones && acciones.Edita)}
        toolbar={
          <CustomToolbar
            enableFilter={true}
            enableRefresh={true}
            onReloadClick={()=> CargarDatos()}
            onAddRow={() => abrirEdicion()}
            isMobile={isMobile}
            acciones={acciones}
          >
            <Cargando activo={loading} />  
          </CustomToolbar>
        }
        onGridRowsUpdated={onGridRowsUpdated}
        onAddFilter={(filter) => setFilters(handleFilterChange(filter))}
        onClearFilters={() => setFilters({})}
        minWidth={width}
        minHeight={height - 20}
        getCellActions={getCellActions}
        onGridSort={(sortColumn, sortDirection) =>
          setRoles(sortRows(roles, sortColumn, sortDirection))
        }
      />
    </>
  );
}

export default RolAdmin;
