import { useEffect, useState } from 'react'
import UsuarioService from 'services/UsuarioService';
import {Roles} from 'utiles/Constantes'

export default function useCoordinadores() {
    const [coordinadores, setCoordinadores] = useState([]);
    const [coordinadorId, setCoordinadorId] = useState(null);
    const [loadingCoordinadores, setLoadingCoordinadores] = useState(true);

    useEffect(() => {
        setLoadingCoordinadores(true);
        UsuarioService.traerListaPorRolCod(Roles.Coordinador)
            .then((result) => {
                if (result !== undefined) {
                    setCoordinadores(result);
                    if (result.length > 0)
                        setCoordinadorId(result[0].id);
                    setLoadingCoordinadores(false);
                }
            })
            .catch((err) => {
                console.error(err);
                setLoadingCoordinadores(false);
            });
    }, []);

    return { coordinadores, coordinadorId, setCoordinadorId, loadingCoordinadores }
}