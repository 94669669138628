import React, { useEffect, useRef } from 'react'
import { Button, Form, InputGroup } from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';

function  Busqueda({ textoInicial, onBuscar }) {
    const inputRef = useRef(null); 
   
    const handleBuscar = (ev) => {
        ev.preventDefault();
        onBuscar(inputRef.current.value);
       // inputRef.current.value = ''
    }
    const handleKeyDown = (ev) => {
        if(ev.key === "Enter") {
            handleBuscar(ev);
        }
    }
    useEffect(() => {
        setTimeout(()=>inputRef.current.focus(),2);
    }, [inputRef]);
    return (
        <InputGroup className="mb-3">
            <Form.Control
                type="search" 
                autoFocus
                onKeyDown={handleKeyDown} 
                defaultValue={textoInicial}
                placeholder="Buscar..." 
                ref={ inputRef}  
                size="sm" />
            <InputGroup.Append>
                <Button variant="outline-primary" size="sm" onClick={handleBuscar}><FaSearch /></Button>
                
            </InputGroup.Append>
        </InputGroup>
            
    );
}

export default Busqueda;