import apiClient, { getErrorMessage, handleError } from "./api";
import BaseService from "./BaseService";

export default class EliminarPF1Service extends BaseService {
  static getUrlBase = () => "/api/EliminarPF1";

  static async traerLista(id, cancelToken = null) {
    var lista = [];
    await apiClient
      .get(this.getUrlBase()+ `/${id}`, { cancelToken: cancelToken?.token })
      .then((response) => {
        lista = response.data;
      })
      .catch(handleError);

    return lista;
  }   
  static async eliminar(id) {
    try {
      let response = await apiClient.delete(this.getUrlBase() + `/eliminar/${id}`);

      console.log('delete: ', response);
      if (response.status === 204)
        return true;
      else if (response.data && response.data.error)
        alert(response.data.error);
    }
    catch (err) {
      handleError(err);
      alert(getErrorMessage(err));
    }
    return false;
  }

}