import PaisAdmin from 'components/catalogos/PaisAdmin';
import RolAdmin from 'components/seguridad/RolAdmin';
import OperadorAdmin from 'components/catalogos/OperadorAdmin';
import {
  FaCertificate,
    FaFlag,
  FaPeopleCarry,
    FaShieldAlt,
    FaTable,
    FaTrash,
    FaUser,
    FaUsers
} from 'react-icons/fa'; 
import { MdLibraryBooks } from 'react-icons/md'; 
import {GrTransaction} from 'react-icons/gr';
import CertMasterAdmin from 'components/transacciones/CertMasterAdmin';
import CertTransaccionAdmin from 'components/transacciones/CertTransaccionAdmin';
import red from 'images/red.png'
import yellow from 'images/yellow.png'
import green from 'images/green.png'
import gray from 'images/gray.png'
import EliminarPF1 from 'components/anterior/EliminarPF1';

export const Roles = {
  Admin : "ADMIN",
  Coordinador: "COOR",
  Operador: "OPER"
}
export const Acciones = {
  Seguridad: {
    Icon: <FaShieldAlt />,
    Url: "/seguridad",
    Codigo: "SEG",
    Nombre: "Seguridad",
    Suffix: "",
    Roles: {
      Icon:  <FaUsers />,
      Url: "/seguridad/roladmin",
      Componente: <RolAdmin />,
      Codigo: "SEG_ROLES",
      Nombre: "Gestión de Roles",
      Agregar: "SEG_ROLES_AGR",
      Editar: "SEG_ROLES_EDI",
      Eliminar: "SEG_ROLES_ELI",
    },
    Usuarios: {
      Icon: <FaUser />,
      Url: "/seguridad/usuarioadmin",
      Componente: <RolAdmin />,
      Codigo: "SEG_USUARIOS",
      Nombre: "Gestión de Usuarios",
      Agregar: "SEG_USUARIOS_AGR",
      Editar: "SEG_USUARIOS_EDI",
      Eliminar: "SEG_USUARIOS_ELI",
    },
  },
  Catalogos: {
    Icon: <FaTable />,
    Url: "/catalogos",
    Codigo: "CAT",
    Nombre: "Catalogos",
    Suffix: "",
    Paises: {
      Icon: <FaFlag />,
      Url: "/catalogos/paisadmin",
      Componente: <PaisAdmin />,
      Codigo: "CAT_PAISES",
      Nombre: "Gestión de Paises",
      Agregar: "CAT_PAISES_AGR",
      Editar: "CAT_PAISES_EDI",
      Eliminar: "CAT_PAISES_ELI",
    },
    Operadores: {
      Icon: <FaPeopleCarry />,
      Url: "/catalogos/operadoradmin",
      Componente: <OperadorAdmin />,
      Codigo: "CAT_OPER",
      Nombre: "Gestión de Operadores",
      VerTodos: "CAT_OPER_VERTODO",
      Agregar: "CAT_OPER_AGR",
      Editar: "CAT_OPER_EDI",
      Eliminar: "CAT_OPER_ELI",
    },
  },
  Transacciones:{
    Icon: <MdLibraryBooks />,
    Url: "/transacciones",
    Codigo: "TRA",
    Nombre: "Transacciones",
    Suffix: "Nuevo",
    CertMaster: {
      Icon: <FaCertificate />,
      Url: "/transacciones/certmasteradmin",
      Componente: <CertMasterAdmin />,
      Codigo: "TRA_CERTMASTER",
      Nombre: "Certificados Master",
      Agregar: "TRA_CERTMASTERS_AGR",
      Editar: "TRA_CERTMASTER_EDI",
      Eliminar: "TRA_CERTMASTER_ELI",
    },
    EliminarPF1: {
      Icon: <FaTrash />,
      Url: "/transacciones/EliminarPF1",
      Componente: <EliminarPF1 />,
      Codigo: "TRA_ELIMINARPF1",
      Nombre: "Eliminar PF1"
    },
    CertTransaccion: {
      Icon: <GrTransaction />,
      Url: "/transacciones/certtransaccionadmin",
      Componente: <CertTransaccionAdmin />,
      Codigo: "TRA_CERTTRANSACCION",
      Nombre: "Cert. de Transacción",
      Agregar: "TRA_CERTTRANSACCION_AGR",
      Editar: "TRA_CERTTRANSACCION_EDI",
      Eliminar: "TRA_CERTTRANSACCION_ELI",
      Enviar: "TRA_CERTTRANSACCION_ENV",
      GenerarBorrador: "TRA_CERTTRANSACCION_BO",
      Confirmar: "TRA_CERTTRANSACCION_CONF", 
      Generar: "TRA_CERTTRANSACCION_GEN",
      Emitir: "TRA_CERTTRANSACCION_EMI",
      Anular: "TRA_CERTTRANSACCION_ANU"//se puede anular según el estado
    },
  }
};

export const Secciones = [
  {
    Seccion: Acciones.Seguridad,
    Items: [
      {
        Item: Acciones.Seguridad.Roles,
        SubItem: [],
      },
      {
        Item: Acciones.Seguridad.Usuarios,
        SubItem: [],
      },
    ],
  },
  {
    Seccion: Acciones.Catalogos,
    Items: [
      {
        Item: Acciones.Catalogos.Paises,
        SubItem: [],
      },
      {
        Item: Acciones.Catalogos.Operadores,
        SubItem: [],
      },
    ],
  },
  {
    Seccion: Acciones.Transacciones,
    Items: [
      {
        Item: Acciones.Transacciones.CertMaster,
        SubItem: [],
      },
      {
        Item: Acciones.Transacciones.EliminarPF1,
        SubItem: [],
      },
      {
        Item: Acciones.Transacciones.CertTransaccion,
        SubItem: [],
      }
    ],
  }
];


export const NormaCertMaster = {
  ComunidadEconomiaEuropea : 'CEE',
  EstadosUnidosAmerica : 'USA',
  Nacional : 'NAL'
}

export const traerNormaCertMaster = () => {
  let normas = [];
  normas[NormaCertMaster.ComunidadEconomiaEuropea] =  'Comunidad Enconómica Europea';
  normas[NormaCertMaster.EstadosUnidosAmerica] =  'Estados Unidos de Américaa';
  normas[NormaCertMaster.Nacional] =  'Nacional';
  return normas;
}

export const EstadoCertMaster = {
  EnElaboracion : 'EE',
  Emitido : 'EM',
  Anulado : 'AN'
}
export const traerEstadosCertificadoMaster = () => {
  let lista = [];
  lista[EstadoCertMaster.EnElaboracion] =  'En Elaboracion';
  lista[EstadoCertMaster.Emitido] =  'Emitido';
  lista[EstadoCertMaster.Anulado] =  'Anulado';
  return lista;
}

export const traerImagenEstadoCertMaster = (estado) =>{
  if(estado === EstadoCertMaster.EnElaboracion)
    return red; 
  if(estado === EstadoCertMaster.Emitido)
    return green;
  if(estado === EstadoCertMaster.Anulado)
    return gray;
}
export const EstadoCertTransaccion = {
  EnElaboracion : 'EE', // rojo
  Enviado : 'EN', // amarillo
  EnBorador: 'BO', // rojo
  Confirmado: 'CO', // amarillo
  Emitido : 'EM', // verde
  Anulado : 'AN' // gris
}
export const traerEstadosCertTransaccion = () => {
  let lista = [];
  lista[EstadoCertTransaccion.EnElaboracion] =  'En Elaboración';
  lista[EstadoCertTransaccion.Enviado] =  'Enviado';
  lista[EstadoCertTransaccion.EnBorador] =  'En Borador';
  lista[EstadoCertTransaccion.Confirmado] =  'Confirmado';
  lista[EstadoCertTransaccion.Emitido] =  'Emitido';
  lista[EstadoCertTransaccion.Anulado] =  'Anulado';
  return lista;
}
export const traerColorEstadoCertTransaccion = (estado) =>{
  if(estado === EstadoCertTransaccion.EnElaboracion 
    || estado === EstadoCertTransaccion.EnBorador)
    return 'Red';
  if(estado === EstadoCertTransaccion.Enviado
    || estado === EstadoCertTransaccion.EnElaboracion)
    return 'Yellow';
  if(estado === EstadoCertTransaccion.Emitido)
    return 'Green';
  if(estado === EstadoCertTransaccion.Anulado)
    return 'Gray';
}

export const traerImagenEstadoCertTransaccion = (estado) =>{
  if(estado === EstadoCertTransaccion.EnElaboracion 
    || estado === EstadoCertTransaccion.EnBorador)
    return red;
  if(estado === EstadoCertTransaccion.Enviado
    || estado === EstadoCertTransaccion.Confirmado)
    return yellow;
  if(estado === EstadoCertTransaccion.Emitido)
    return green;
  if(estado === EstadoCertTransaccion.Anulado)
    return gray;
}


export const FormatoCertTransaccion = {
  COI : 'COI',
  FD2 : 'FD2',
  FD3 : 'FD3'
}
export const traerFormatoCertTransaccion = () => {
  let lista = [];
  lista[FormatoCertTransaccion.COI] =  'COI';
  lista[FormatoCertTransaccion.FD2] =  'FD2';
  lista[FormatoCertTransaccion.FD3] =  'FD3';
  return lista;
}

export const MedioTransporte = {
  Buque : 'BU',
  Avion : 'AV' 
}
export const traerMediosTransporte = () => {
  let lista = [];
  lista[MedioTransporte.Buque] =  'Buque';
  lista[MedioTransporte.Avion] =  'Avión';
  return lista;
}