import React, { Component } from "react";
import PaisService from "services/PaisService";
import "bootstrap/dist/css/bootstrap.min.css";
import { Table, Button, Container, Modal, FormGroup } from "react-bootstrap";
import Cargando from "../comunes/Cargando";
import { FaEdit, FaPlusCircle, FaTrash } from "react-icons/fa";

class PaisAdmin extends Component {
  state = {
    paises: [],
    modalInsertar: false,
    modalEditar: false,
    current: {
      id: "",
      nombre: "",
      codigo: "",
      iso2d: ""
    },
  };
  handleChange = (e) => {
    e.persist();
    this.setState({
      current: {
        ...this.state.current,
        [e.target.name]: e.target.value,
      },
    });
  };

  mostrarInsertar = () => {
    this.setState({
      current: {
        id: "",
        nombre: "",
        codigo: "",
        iso2d: ""
      },
      modalInsertar: true,
    });
  };

  ocultarInsertar = () => {
    this.setState({ modalInsertar: false });
  };

  mostrarEditar = (pais) => {
    this.setState({
      current: {
        id: pais.id,
        code: pais.code,
        nombre: pais.nombre,
        codigo: pais.codigo,
        iso2d: pais.iso2d
      },
      modalEditar: true,
      cargando: false,
    });
  };

  ocultarEditar = () => {
    this.setState({ modalEditar: false });
  };

  insertar = async () => {
    //invoca al api para agregar el nuevo libro
    await PaisService.agregar(this.state.current);
    this.refresh();
    this.ocultarInsertar();
  };

  editar = async () => {
    //invoca al api para agregar el guardar libro
    await PaisService.modificar(this.state.current);
    this.refresh();
    this.ocultarEditar();
  };

  eliminar = async (pais) => {
    var opcion = window.confirm(
      "¿Realmente desea eliminar el Pais: '" +
        pais.codigo +
        " | " +
        pais.nombre +
        "'?"
    );
    if (opcion) {
      await PaisService.eliminar(pais);
      this.refresh();
    }
  };

  refresh = () => {
    this.setState({ cargando: true });
    PaisService.traerLista()
      .then((lista) => {
        console.log(lista);
        this.setState({ paises: lista, cargando: false });
      })
      .catch((error) => {
        this.setState({ cargando: false });
        console.error(error);
      });
  };
  componentDidMount() {
    this.refresh();
  }
  render() {
    if (this.props.loggedIn) {
      const paisesRows = this.state.paises.map((pais) => (
        <tr key={pais.id}>
           <td>
            <Button variant="link" onClick={() => this.mostrarEditar(pais)}>
              <FaEdit />
            </Button>
            {"   "}
            <Button variant="link" onClick={() => this.eliminar(pais)}>
              <FaTrash />
            </Button>
          </td>
          <td>{pais.code}</td>
          <td>{pais.codigo}</td>
          <td>{pais.iso2d}</td>
          <td>{pais.nombre}</td>         
        </tr>
      ));
      return (
        <>
          <Container>
            {/* <Button variant="success" onClick={() => this.mostrarInsertar()}>
              <FaPlusCircle /> Agregar
            </Button> */}
            <Table hover responsive>
              <thead>
                <tr>
                  <th></th>
                  <th>Code</th>
                  <th>ISO</th>
                  <th>ISO 2D</th>
                  <th>Nombre</th>
                </tr>
              </thead>
              <tbody>{paisesRows}</tbody>
            </Table>
            <Cargando activo={this.state.cargando} />
          </Container>

          <Modal
            show={this.state.modalInsertar}
            onHide={() => this.ocultarInsertar()}
          >
            <Modal.Header closeButton>
              <div>
                <h3>Agregar Pais</h3>
              </div>
            </Modal.Header>
            <Modal.Body>
              <FormGroup>
                <label>Code:</label>
                <input
                  className="form-control"
                  name="code"
                  type="text"
                  onChange={this.handleChange}
                  required
                />
              </FormGroup>
              <FormGroup>
                <label>Código ISO:</label>
                <input
                  className="form-control"
                  name="codigo"
                  type="text"
                  onChange={this.handleChange}
                  required
                />
              </FormGroup>
              <FormGroup>
                <label>Código ISO 2D:</label>
                <input
                  className="form-control"
                  name="iso2d"
                  type="text"
                  maxLength={2}
                  onChange={this.handleChange}
                  required
                />
              </FormGroup>
              <FormGroup>
                <label>Nombre:</label>
                <input
                  className="form-control"
                  name="nombre"
                  type="text"
                  onChange={this.handleChange}
                  required
                />
              </FormGroup>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={() => this.insertar()}>
                Agregar
              </Button>
              <Button
                variant="secondary"
                onClick={() => this.ocultarInsertar()}
              >
                Cancelar
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal
            show={this.state.modalEditar}
            onHide={() => this.ocultarEditar()}
          >
            <Modal.Header closeButton>
              <div>
                <h3>Editar Pais</h3>
              </div>
            </Modal.Header>
            <Modal.Body>
              <FormGroup>
                <label>Code:</label>
                <input
                  className="form-control"
                  name="code"
                  type="text"
                  onChange={this.handleChange}
                  value={this.state.current.code}
                  required
                />
              </FormGroup>
              <FormGroup>
                <label>Código ISO:</label>
                <input
                  className="form-control"
                  name="codigo"
                  type="text"
                  onChange={this.handleChange}
                  value={this.state.current.codigo}
                  required
                />
              </FormGroup>
              <FormGroup>
                <label>Código ISO 2D:</label>
                <input
                  className="form-control"
                  name="iso2d"
                  type="text"
                  maxLength={2}
                  onChange={this.handleChange}
                  value={this.state.current.iso2d}
                  required
                />
              </FormGroup>
              <FormGroup>
                <label>Nombre:</label>
                <input
                  className="form-control"
                  name="nombre"
                  type="text"
                  onChange={this.handleChange}
                  value={this.state.current.nombre}
                  required
                />
              </FormGroup>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={() => this.editar()}>
                Guardar
              </Button>
              <Button variant="secondary" onClick={() => this.ocultarEditar()}>
                Cancelar
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      );
    }
    return (
      <div className="alert alert-warning">Usted no ha iniciado Sesión.</div>
    );
  }
}

export default PaisAdmin;
