import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import Cargando from "../comunes/Cargando";
import Button from "react-bootstrap/Button";
import { useForm } from "react-hook-form";
import { Alert, Col, Form, Modal, Tab, Tabs } from "react-bootstrap";
import { getErrorMessage, handleError } from "services/api";
import { FaFileWord, FaSave, FaTimes, FaUndo, FaEdit, FaSync } from "react-icons/fa";
import { TiDelete } from "react-icons/ti";
import CertMasterService from "services/CertMasterService";
import ErrorMessage from "components/comunes/ErrorMessage";
import DatePicker from "components/comunes/DatePicker";

import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";
import { saveAs } from 'file-saver';
import { dateToLocalDateString } from "utiles/FormatHelper";
import OperadorForm from 'components/catalogos/OperadorForm';
import OperadorService from 'services/OperadorService';
import { Acciones, EstadoCertMaster, NormaCertMaster, traerEstadosCertificadoMaster } from "utiles/Constantes";
import { AuthContext } from "context/AuthContext";
import { isAllowed } from "hooks/Auth";
import ArchivoSelector from "components/comunes/ArchivoSelector";

export default function CertMasterForm({
    edicionId = 0,
    cerrar,
    consulta = false,
    paisId = 0,
    operadorId = 0,
    norma
}) {
    const [loading, setLoading] = useState(false);
    const [procesando, setProcesando] = useState(false);
    const [id, setId] = useState(edicionId);
    const [Norma, setNorma] = useState(norma);
    const [ext, setExt] = useState(false);
    const [anulado, setAnulado] = useState(false);
    const [estado, setEstado] = useState(EstadoCertMaster.EnElaboracion);
    const { register, errors, handleSubmit, reset, setError, control, getValues, setValue, trigger } = useForm();
    const [mensaje, setMensaje] = useState("");
    const [readOnly, setReadOnly] = useState(consulta);
    const [mostrarEdicionOperador, setMostrarEdicionOperador] = useState(false);
    const {user} = useContext(AuthContext);
    const estados = traerEstadosCertificadoMaster();
    //validacion de check productos
    const camposProductos = ['prodvegetales', 'prodanimales', 'prodagrarios'];
    const errorProductos = camposProductos.some(prod => errors[prod]);
    const isValidProductos = () => camposProductos.some(prod => getValues(prod));

    //validacion de check productos  (Nacional)
    const camposProductosNal = ['prodvegetales', 'prodanimales', 'nalapicultor'];
    const errorProductosNAL = camposProductosNal.some(prod => errors[prod]);
    const isValidProductosNal = () => camposProductosNal.some(prod => getValues(prod));

    //validacion de check producción
    const camposProduccion = ['prodecologica', 'prodenconversion', 'prodnoecologica'];
    const errorProduccion = camposProduccion.some(prod => errors[prod]);
    const isValidProduccion = () => camposProduccion.some(prod => getValues(prod));

    //validacion de check USA productor | procesador 
    const camposProductorProcesador = ['usaproductor', 'usaprocesador'];
    const errorProductorProcesador = camposProductorProcesador.some(prod => errors[prod]);
    const isValidProductorProcesador = () => camposProductorProcesador.some(prod => getValues(prod));

    //validacion de check USA productor | procesador 
    const camposCultivoElavorados = ['usacultivo', 'usaprodelaborados'];
    const errorCultivoElavorados = camposCultivoElavorados.some(prod => errors[prod]);
    const isValidCultivoElavorados = () => camposCultivoElavorados.some(prod => getValues(prod));

    useEffect(() => {
        let cancelToken = axios.CancelToken.source();
        if (id === 0) 
            reset({ pais_id: paisId, norma: norma, distribucion: true });
        else {
            setMensaje('Cargando Certificado Máster');
            setLoading(true);

            CertMasterService.traerCertMaster(edicionId, cancelToken)
                .then((result) => {
                    if (result !== undefined) {
                        if (result !== undefined) {
                            reset(result);
                            setNorma(result.norma);
                            setEstado(result.estado);
                            setExt(result.certext === 'EXT');
                            switch (result.estado) {
                                case EstadoCertMaster.Anulado:
                                    setReadOnly(true);
                                    setAnulado(true);
                                    break;
                                case EstadoCertMaster.Emitido:
                                    setReadOnly(!isAllowed(user, Acciones.Transacciones.CertMaster.Editar));
                                    break;
                                default:
                            }
                        } else {
                            setMensaje(`Certificado con Id '${edicionId}' no ha sido encontrado`);
                            setReadOnly(true);
                        }
                    }
                    
                    setMensaje('');
                    setLoading(false);
                })
                .catch((err) => {
                    if (!axios.isCancel(err)) {
                        handleError(err);
                        setLoading(false);
                        setReadOnly(true);
                        setMensaje(getErrorMessage(err));
                    }
                });
        }
        return () => {
            cancelToken.cancel("Carga de Operador cancelada por el usuario.");
        };
    }, [reset, edicionId, paisId]);


    const guardar = async (data) => {
        try {
            setProcesando(true);
            setMensaje("Guardando...");
            let newCert = null;
            if (id !== 0) {
                data.id = id;
                newCert = await CertMasterService.modificar(data);
            } else {
                data.pais_id = paisId;
                data.operador_id = operadorId;
                newCert = await CertMasterService.agregar(data);
                if (!!newCert) setId(newCert.id);
            }

            setMensaje("");
            return newCert;
        }
        catch (err) {
            setMensaje(getErrorMessage(err));
            if (err?.response?.data) {
                for (let field in err.response.data) {
                    setError(field, { message: err.response.data[field] });
                }
            }
        }
        finally {
            setProcesando(false);
        }
    };
    const generarDocumento = async (certMaster) => {
        try {
            if (!certMaster) return;

            setProcesando(true);
            setMensaje("Generando Certificado Máster...");
            let plantilla  = process.env.PUBLIC_URL + `/FA0_${certMaster.norma}_${certMaster.certext}.docx`;
            
            PizZipUtils.getBinaryContent(plantilla, async function (
                error,
                content
            ) {
                if (error) {
                    throw error;
                }

                var zip = new PizZip(content);
                var doc = new Docxtemplater().loadZip(zip);

                // valores calculados
                if (certMaster.fechacontrol)
                    certMaster.fechacontrol = dateToLocalDateString(certMaster.fechacontrol);
                if (certMaster.fechacertificacion)
                    certMaster.fechacertificacion = dateToLocalDateString(certMaster.fechacertificacion);
                if (certMaster.fechaemision)
                    certMaster.fechaemision = dateToLocalDateString(certMaster.fechaemision);
                if (certMaster.fechavaliddesde)
                    certMaster.fechavaliddesde = dateToLocalDateString(certMaster.fechavaliddesde);
                if (certMaster.fechavalidhasta)
                    certMaster.fechavalidhasta = dateToLocalDateString(certMaster.fechavalidhasta);
                if (certMaster.usafechaefectiva)
                    certMaster.usafechaefectiva = dateToLocalDateString(certMaster.usafechaefectiva);
                if (certMaster.usafechaaniversario)
                    certMaster.usafechaaniversario = dateToLocalDateString(certMaster.usafechaaniversario);
                certMaster.tieneContacto2 = (!!certMaster.contacto2);
                certMaster.tieneCorreo2 = (!!certMaster.correo2);

                doc.setData(certMaster);
                try {
                    // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
                    doc.render();
                } catch (error) {
                    setProcesando(false);
                    setMensaje("");
                    // The error thrown here contains additional information when logged with JSON.stringify (it contains a properties object containing all suberrors).
                    function replaceErrors(key, value) {
                        if (value instanceof Error) {
                            return Object.getOwnPropertyNames(value).reduce(function (
                                error,
                                key
                            ) {
                                error[key] = value[key];
                                return error;
                            },
                                {});
                        }
                        return value;
                    }
                    console.log(JSON.stringify({ error: error }, replaceErrors));

                    if (error.properties && error.properties.errors instanceof Array) {
                        const errorMessages = error.properties.errors
                            .map(function (error) {
                                return error.properties.explanation;
                            })
                            .join("\n");
                        console.log("errorMessages", errorMessages);
                        // errorMessages is a humanly readable message looking like this :
                        // 'The tag beginning with "foobar" is unopened'
                    }

                    throw error;
                }
                var out = doc.getZip().generate({
                    type: "blob",
                    mimeType:
                        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                }); //Output the document using Data-URI

                //sólo se marca como emitido si está en elaboración
                if (certMaster.estado === EstadoCertMaster.EnElaboracion)
                    await CertMasterService.emitir(certMaster.id);

                saveAs(out, certMaster.numcertificado + ".docx");

                setMensaje("Documento generado con éxito.");

                setProcesando(false);

                //Cierra pantalla de edición
                cerrar(true);
            });
        }
        catch (err) {
            setMensaje(getErrorMessage(err));
            if (err?.response?.data) {
                for (let field in err.response.data) {
                    setError(field, { message: err.response.data[field] });
                }
            }

            setProcesando(false);
        }
    };
    const onSubmit = async (data, e) => {
        try {
            switch (e.target.id) {
                case 'btnGuardar':
                case 'svgGuardar':
                    if (!! await guardar(data)) {
                        cerrar(true);
                    }
                    break;
                case 'btnGenerar':
                case 'svgGenerar': 
                    let certMaster;
                    if (estado === EstadoCertMaster.EnElaboracion){
                        if(!window.confirm('¿Está seguro que desea generar el Certificado Máster?\nUna vez emitido ya no se podrán realizar cambios'))  return;
                        
                        //si el usuario puede hacer cambios entonces se guarda primero
                        certMaster = await guardar(data);
                    }
                    else{
                        try{
                            setMensaje('Iniciando generación de Certificado Máster');
                            setProcesando(true);
                            certMaster = await CertMasterService.traerCertMaster(id)
                        }
                        finally{
                            setMensaje('');
                            setProcesando(true);
                        }
                    }
                    // si se logró obtener el ceritificado máster se genera
                    if (!!certMaster) {
                        generarDocumento(certMaster);
                    }
                    break;
                default:
            }
        }
        catch (error) {
            console.error(error);
        }
    };

    const sincronizarrDatosOperador = async () => {
        try {
            if(!window.confirm('¿Está seguro que desea actualizar el Certificado Máster con los datos más recientes del operador?')) 
                return;
            setLoading(true);
            setMensaje('Actualizando datos del Operador...');
            let operador = await OperadorService.traer(operadorId);
            setValue('codoperador', operador.codigo);
            setValue('nombreoperador', operador.nombre);
            setValue('direccion', operador.direccion_legal);
            setValue('ciudad', operador.ciudad);
            setValue('departamento', operador.departamento);
            setValue('correo', operador.correo);
            setValue('correo2', operador.correo2);
            setValue('contacto', operador.contacto);
            setValue('contacto2', operador.contacto2);
            setValue('web', operador.web);
            setMensaje('Datos del Operador actualizados con éxito.');
        }
        catch (err) {
            setMensaje(JSON.stringify(err));
        }
        finally {
            setLoading(false);
        }

    };

    const ocultarEdicionOperador = (exito) => {
        if (exito === true) sincronizarrDatosOperador();
        setMostrarEdicionOperador(false);
    };

    const anular = async (e) => {
        try {
            e.preventDefault();
            setProcesando(true);
            let razon = window.prompt('Razón de la anulación:');
            if (razon) {
                setMensaje('Anulando...');
                setValue('estado', EstadoCertMaster.Anulado);
                setValue('razonnulacion', razon);

                //intenta guardar
                if (await CertMasterService.anular(id, razon)) {
                    cerrar(true);
                }
                else {
                    //si no logra guardar, deshace los cambios
                    setValue('estado', EstadoCertMaster.EnElaboracion);
                    setValue('razonnulacion', '');
                    setMensaje('No se pudo anular,  consulte con el adminsitrador.');
                }
            }
        }
        catch (err) {
            setMensaje(getErrorMessage(err));
        }
        finally {
            setProcesando(false);
        }
    }
    const puedeEditar = isAllowed(user, Acciones.Transacciones.CertMaster.Editar);
    return (
        <>
            <Form>
                <Tabs defaultActiveKey="CertificadoMater" id="tabEdicionCM">
                    <Tab eventKey="CertificadoMater" title="Datos Generales">
                        <Form.Row>
                            <Col xs={12} md={1}>
                                <Form.Group controlId="norma">
                                    <Form.Label>Norma:</Form.Label>
                                    <Form.Control
                                        name="norma"
                                        type="text"
                                        ref={register()}
                                        isInvalid={!!errors.norma}
                                        readOnly={true}
                                        size="sm"
                                    />
                                    <ErrorMessage error={errors.norma} />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={2}>
                                <Form.Group controlId="certext">
                                    <Form.Label>CERT / EXT:</Form.Label>

                                    <Form.Control
                                        name="certext"
                                        as="select"
                                        ref={register({ required: "Selecione si es CERTIFICADO O EXTENSIÓN", maxLength: 6 })}
                                        isInvalid={!!errors.certext}
                                        onChange={(e) => setExt(e.target.value === 'EXT')}
                                        disabled={readOnly || id > 0}
                                        size="sm"
                                    >
                                        <option key="CERT" value="CERT">Certificado</option>
                                        <option key="EXT" value="EXT">Extensión</option>
                                    </Form.Control>
                                    <ErrorMessage error={errors.certext} />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={7}>
                                {id > 0 &&
                                    <Form.Group controlId="numcertificado">
                                        <Form.Label>Número de Certificado Máster:</Form.Label>
                                        <Form.Control
                                            name="numcertificado"
                                            type="text"
                                            ref={register()}
                                            disabled={true}
                                            size="sm"
                                        />
                                    </Form.Group>}
                            </Col>
                            <Col xs={12} md={2}>
                                <Form.Group controlId="estado">
                                    <Form.Label>Estado:</Form.Label>
                                    <Form.Control
                                        name="estado"
                                        as="select"
                                        ref={register()}
                                        disabled={true}
                                        size="sm"
                                        style={{
                                            color: estado === EstadoCertMaster.Anulado? 'Red': 'black'
                                        }}
                                    >
                                        {Object.keys(estados).map((e) => <option key={e} value={e}>{estados[e]}</option>)}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        </Form.Row>
                        {(ext) &&
                            <Form.Row>
                                <Col xs={12} md={6}>
                                    <Form.Group controlId="codext">
                                        <Form.Label>EXT: Código de Certificado sujeto a Extensión:</Form.Label>
                                        <Form.Control
                                            name="codext"
                                            maxLength={255}
                                            type="text"
                                            ref={register({ required: "Ingrese el Código del certificado sujeto a Extensión", maxLength: 255 })}
                                            isInvalid={!!errors.codext}
                                            readOnly={readOnly}
                                            size="sm"
                                        />
                                        <ErrorMessage error={errors.codext} />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Form.Group controlId="nombrecertificadoraext">
                                        <Form.Label>EXT: Emitido por:</Form.Label>

                                        <Form.Control
                                            name="nombrecertificadoraext"
                                            maxLength={255}
                                            type="text"
                                            ref={register({ maxLength: 255 })}
                                            isInvalid={!!errors.nombrecertificadoraext}
                                            readOnly={readOnly}
                                            size="sm"
                                        />
                                        <ErrorMessage error={errors.nombrecertificadoraext} />
                                    </Form.Group>
                                </Col>
                            </Form.Row>
                        }
                        {Norma === NormaCertMaster.ComunidadEconomiaEuropea &&
                            <>
                                <Form.Row>
                                    <Col xs={12} md={2}>
                                        <Form.Group controlId="produccion">
                                            <Form.Check
                                                name="produccion"
                                                type="switch"
                                                label="Producción"
                                                ref={register()}
                                                isInvalid={!!errors.produccion}
                                                disabled={readOnly}
                                                size="sm"
                                            />
                                            <ErrorMessage error={errors.produccion} />
                                        </Form.Group>
                                        <Form.Group controlId="preparacion">
                                            <Form.Check
                                                name="preparacion"
                                                type="switch"
                                                label="Preparación"
                                                ref={register()}
                                                isInvalid={!!errors.preparacion}
                                                disabled={readOnly}
                                                size="sm"
                                            />
                                            <ErrorMessage error={errors.preparacion} />
                                        </Form.Group>
                                        <Form.Group controlId="distribucion">
                                            <Form.Check
                                                name="distribucion"
                                                type="switch"
                                                label="Distribución"
                                                ref={register()}
                                                isInvalid={!!errors.distribucion}
                                                disabled
                                                size="sm"
                                            />
                                            <ErrorMessage error={errors.distribucion} />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        {errorProductos && <small className="text-danger form-text">Debe seleccionar al menos uno.</small>}
                                        <Form.Group controlId="prodvegetales">
                                            <Form.Check
                                                name="prodvegetales"
                                                type="switch"
                                                label="Vegetales y productos vegetales no transformados"
                                                ref={register({ validate: isValidProductos })}
                                                isInvalid={errorProductos}
                                                disabled={readOnly}
                                                onChange={() => trigger(camposProductos)}
                                                size="sm"
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="prodanimales">
                                            <Form.Check
                                                name="prodanimales"
                                                type="switch"
                                                label="Animales y productos animales no transformados"
                                                ref={register({ validate: isValidProductos })}
                                                isInvalid={errorProductos}
                                                disabled={readOnly}
                                                onChange={() => trigger(camposProductos)}
                                                size="sm"
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="prodagrarios">
                                            <Form.Check
                                                name="prodagrarios"
                                                type="switch"
                                                label="Productos agrarios transformados, destinados a ser utilizados para la alimentación humana"
                                                ref={register({ validate: isValidProductos })}
                                                isInvalid={errorProductos}
                                                disabled={readOnly}
                                                onChange={() => trigger(camposProductos)}
                                                size="sm"
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={4}>
                                        {errorProduccion && <small className="text-danger form-text">Debe seleccionar al menos uno.</small>}
                                        <Form.Group controlId="prodecologica">
                                            <Form.Check
                                                name="prodecologica"
                                                type="switch"
                                                label="Producción ecológica"
                                                ref={register({ validate: isValidProduccion })}
                                                isInvalid={errorProduccion}
                                                disabled={readOnly}
                                                onChange={() => trigger(camposProduccion)}
                                                size="sm"
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="prodenconversion">
                                            <Form.Check
                                                name="prodenconversion"
                                                type="switch"
                                                label="Productos en conversión"
                                                ref={register({ validate: isValidProduccion })}
                                                isInvalid={errorProduccion}
                                                disabled={readOnly}
                                                onChange={() => trigger(camposProduccion)}
                                                size="sm"
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="prodnoecologica">
                                            <Form.Check
                                                name="prodnoecologica"
                                                type="switch"
                                                label="Producción no ecológica"
                                                ref={register({ validate: isValidProduccion })}
                                                isInvalid={errorProduccion}
                                                disabled={readOnly}
                                                onChange={() => trigger(camposProduccion)}
                                                size="sm"
                                            />
                                        </Form.Group>
                                    </Col>
                                </Form.Row>
                            </>
                        }
                        {Norma === NormaCertMaster.EstadosUnidosAmerica &&
                            <>
                                <Form.Row>
                                    <Col xs={12} md={3}>
                                        {errorProductorProcesador && <small className="text-danger form-text">Debe seleccionar al menos uno.</small>}
                                        <Form.Group controlId="usaproductor">
                                            <Form.Check
                                                name="usaproductor"
                                                type="switch"
                                                label="Productor"
                                                ref={register({ validate: isValidProductorProcesador })}
                                                isInvalid={errorProductorProcesador}
                                                disabled={readOnly}
                                                onChange={() => trigger(camposProductorProcesador)}
                                                size="sm"
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="usaprocesador">
                                            <Form.Check
                                                name="usaprocesador"
                                                type="switch"
                                                label="Elaborador/Procesador"
                                                ref={register({ validate: isValidProductorProcesador })}
                                                isInvalid={errorProductorProcesador}
                                                disabled={readOnly}
                                                onChange={() => trigger(camposProductorProcesador)}
                                                size="sm"
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={4}>
                                        {errorCultivoElavorados && <small className="text-danger form-text">Debe seleccionar al menos uno.</small>}
                                        <Form.Group controlId="usacultivo">
                                            <Form.Check
                                                name="usacultivo"
                                                type="switch"
                                                label="Cultivo (Vegetales y productos vegetales)"
                                                ref={register({ validate: isValidCultivoElavorados })}
                                                isInvalid={errorCultivoElavorados}
                                                disabled={readOnly}
                                                onChange={() => trigger(camposCultivoElavorados)}
                                                size="sm"
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="usaprodelaborados">
                                            <Form.Check
                                                name="usaprodelaborados"
                                                type="switch"
                                                label="Productos elaborados/procesados"
                                                ref={register({ validate: isValidCultivoElavorados })}
                                                isInvalid={errorCultivoElavorados}
                                                disabled={readOnly}
                                                onChange={() => trigger(camposCultivoElavorados)}
                                                size="sm"
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={5}>
                                        <Form.Group controlId="usacor">
                                            <Form.Check
                                                name="usacor"
                                                type="switch"
                                                label="This Certificate covers the following Product(s) and Services(s) which are certified in accordance with the terms of the U.S.-Canada Organic Equivalency Arrangement"
                                                ref={register({ validate: isValidCultivoElavorados })}
                                                disabled={readOnly}
                                                size="sm"
                                            />
                                        </Form.Group>

                                    </Col>
                                </Form.Row>
                                <Form.Row>
                                    <Col xs={12} md={2}>
                                        <Form.Group controlId="fechacertificacion">
                                            <Form.Label>Certificación:</Form.Label>
                                            <DatePicker
                                                name="fechacertificacion"
                                                control={control}
                                                readOnly={readOnly}
                                                required='Ingrese la fecha de Certificación.'
                                                isInvalid={!!errors.fechacertificacion}
                                            />
                                            <ErrorMessage error={errors.fechacertificacion} />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={2}>
                                        <Form.Group controlId="usafechaefectiva">
                                            <Form.Label>Efectiva:</Form.Label>
                                            <DatePicker
                                                name="usafechaefectiva"
                                                control={control}
                                                readOnly={readOnly}
                                                required='Ingrese la fecha de Efectiva.'
                                                isInvalid={!!errors.usafechaefectiva}
                                            />
                                            <ErrorMessage error={errors.usafechaefectiva} />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={2}>
                                        <Form.Group controlId="fechaemision">
                                            <Form.Label>Emisión:</Form.Label>
                                            <DatePicker
                                                name="fechaemision"
                                                control={control}
                                                readOnly={readOnly}
                                                required='Ingrese la fecha de Emisión.'
                                                isInvalid={!!errors.fechaemision}
                                            />
                                            <ErrorMessage error={errors.fechaemision} />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={2}>
                                        <Form.Group controlId="usafechaaniversario">
                                            <Form.Label>Aniversario:</Form.Label>
                                            <DatePicker
                                                name="usafechaaniversario"
                                                control={control}
                                                readOnly={readOnly}
                                                required='Ingrese la fecha de Aniversario.'
                                                isInvalid={!!errors.usafechaaniversario}
                                            />
                                            <ErrorMessage error={errors.usafechaaniversario} />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={2}>
                                        <Form.Group controlId="fechavaliddesde">
                                            <Form.Label>Valida Desde:</Form.Label>
                                            <DatePicker
                                                name="fechavaliddesde"
                                                control={control}
                                                readOnly={readOnly}
                                                required='Ingrese la fecha de inicio de validez.'
                                                isInvalid={!!errors.fechavaliddesde}
                                            />
                                            <ErrorMessage error={errors.fechavaliddesde} />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={2}>
                                        <Form.Group controlId="fechavalidhasta">
                                            <Form.Label>Valida Hasta:</Form.Label>
                                            <DatePicker
                                                name="fechavalidhasta"
                                                control={control}
                                                readOnly={readOnly}
                                                required='Ingrese la fecha de fin de validez.'
                                                isInvalid={!!errors.fechavalidhasta}
                                            />
                                            <ErrorMessage error={errors.fechavalidhasta} />
                                        </Form.Group>
                                    </Col>
                                </Form.Row>
                            </>
                        }
                        {Norma === NormaCertMaster.Nacional &&
                            <>
                                <Form.Row>
                                    <Col xs={12} md={3}>
                                        <Form.Group controlId="produccion">
                                            <Form.Check
                                                name="produccion"
                                                type="switch"
                                                label="Producción"
                                                ref={register()}
                                                isInvalid={!!errors.produccion}
                                                disabled={readOnly}
                                                size="sm"
                                            />
                                            <ErrorMessage error={errors.produccion} />
                                        </Form.Group>
                                        <Form.Group controlId="preparacion">
                                            <Form.Check
                                                name="preparacion"
                                                type="switch"
                                                label="Preparación"
                                                ref={register()}
                                                isInvalid={!!errors.preparacion}
                                                disabled={readOnly}
                                                size="sm"
                                            />
                                            <ErrorMessage error={errors.preparacion} />
                                        </Form.Group>
                                        <Form.Group controlId="distribucion">
                                            <Form.Check
                                                name="distribucion"
                                                type="switch"
                                                label="Comercialización"
                                                ref={register()}
                                                isInvalid={!!errors.distribucion}
                                                disabled
                                                size="sm"
                                            />
                                            <ErrorMessage error={errors.distribucion} />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={5}>
                                        {errorProductos && <small className="text-danger form-text">Debe seleccionar al menos uno.</small>}
                                        <Form.Group controlId="prodvegetales">
                                            <Form.Check
                                                name="prodvegetales"
                                                type="switch"
                                                label="Vegetales y productos vegetales no transformados"
                                                ref={register({ validate: isValidProductosNal })}
                                                isInvalid={errorProductosNAL}
                                                disabled={readOnly}
                                                onChange={() => trigger(camposProductosNal)}
                                                size="sm"
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="prodanimales">
                                            <Form.Check
                                                name="prodanimales"
                                                type="switch"
                                                label="Animales y productos animales no transformados"
                                                ref={register({ validate: isValidProductosNal })}
                                                isInvalid={errorProductosNAL}
                                                disabled={readOnly}
                                                onChange={() => trigger(camposProductosNal)}
                                                size="sm"
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="nalapicultor">
                                            <Form.Check
                                                name="nalapicultor"
                                                type="switch"
                                                label="Apicultor"
                                                ref={register({ validate: isValidProductosNal })}
                                                isInvalid={errorProductosNAL}
                                                disabled={readOnly}
                                                onChange={() => trigger(camposProductosNal)}
                                                size="sm"
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={4}>
                                        {errorProduccion && <small className="text-danger form-text">Debe seleccionar al menos uno.</small>}
                                        <Form.Group controlId="prodecologica">
                                            <Form.Check
                                                name="prodecologica"
                                                type="switch"
                                                label="Producción ecológica"
                                                ref={register({ validate: isValidProduccion })}
                                                isInvalid={errorProduccion}
                                                disabled={readOnly}
                                                onChange={() => trigger(camposProduccion)}
                                                size="sm"
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="prodenconversion">
                                            <Form.Check
                                                name="prodenconversion"
                                                type="switch"
                                                label="Productos en conversión"
                                                ref={register({ validate: isValidProduccion })}
                                                isInvalid={errorProduccion}
                                                disabled={readOnly}
                                                onChange={() => trigger(camposProduccion)}
                                                size="sm"
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="prodnoecologica">
                                            <Form.Check
                                                name="prodnoecologica"
                                                type="switch"
                                                label="Producción no ecológica"
                                                ref={register({ validate: isValidProduccion })}
                                                isInvalid={errorProduccion}
                                                disabled={readOnly}
                                                onChange={() => trigger(camposProduccion)}
                                                size="sm"
                                            />
                                        </Form.Group>
                                    </Col>
                                </Form.Row>
                                
                            </>
                        }
                        {(Norma === NormaCertMaster.ComunidadEconomiaEuropea || Norma === NormaCertMaster.Nacional) &&
                        <Form.Row>
                                <Col xs={12} md={2}>
                                    <Form.Group controlId="fechacontrol">
                                        <Form.Label>Inspección:</Form.Label>
                                        <DatePicker
                                            name="fechacontrol"
                                            control={control}
                                            readOnly={readOnly}
                                            required='Ingrese la fecha de Inspección.'
                                            isInvalid={!!errors.fechacontrol}
                                        />
                                        <ErrorMessage error={errors.fechacontrol} />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={2}>
                                    <Form.Group controlId="fechacertificacion">
                                        <Form.Label>Certificación:</Form.Label>
                                        <DatePicker
                                            name="fechacertificacion"
                                            control={control}
                                            readOnly={readOnly}
                                            required='Ingrese la fecha de Certificación.'
                                            isInvalid={!!errors.fechacertificacion}
                                        />
                                        <ErrorMessage error={errors.fechacertificacion} />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={2}>
                                    <Form.Group controlId="fechaemision">
                                        <Form.Label>Emisión:</Form.Label>
                                        <DatePicker
                                            name="fechaemision"
                                            control={control}
                                            readOnly={readOnly}
                                            required='Ingrese la fecha de Emisión.'
                                            isInvalid={!!errors.fechaemision}
                                        />
                                        <ErrorMessage error={errors.fechaemision} />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={2}></Col>
                                <Col xs={12} md={2}>
                                    <Form.Group controlId="fechavaliddesde">
                                        <Form.Label>Valida Desde:</Form.Label>
                                        <DatePicker
                                            name="fechavaliddesde"
                                            control={control}
                                            readOnly={readOnly}
                                            required='Ingrese la fecha de inicio de validez.'
                                            isInvalid={!!errors.fechavaliddesde}
                                        />
                                        <ErrorMessage error={errors.fechavaliddesde} />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={2}>
                                    <Form.Group controlId="fechavalidhasta">
                                        <Form.Label>Valida Hasta:</Form.Label>
                                        <DatePicker
                                            name="fechavalidhasta"
                                            control={control}
                                            readOnly={readOnly}
                                            required='Ingrese la fecha de fin de validez.'
                                            isInvalid={!!errors.fechavalidhasta}
                                        />
                                        <ErrorMessage error={errors.fechavalidhasta} />
                                    </Form.Group>
                                </Col>
                            </Form.Row>}
                        <Form.Row>
                            <Col xs={12} md={6}>
                                <Form.Group controlId="declaradopor">
                                    <Form.Label>Declarado por:</Form.Label>
                                    <Form.Control
                                        name="declaradopor"
                                        maxLength={255}
                                        type="text"
                                        ref={register({ required: "Ingrese el Declarado por", maxLength: 255 })}
                                        isInvalid={!!errors.declaradopor}
                                        readOnly={readOnly}
                                        size="sm"
                                    />
                                    <ErrorMessage error={errors.declaradopor} />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Group controlId="declaradoporcargo">
                                    <Form.Label>Cargo:</Form.Label>

                                    <Form.Control
                                        name="declaradoporcargo"
                                        maxLength={255}
                                        type="text"
                                        ref={register({ required: "Ingrese el Cargo del que declaró.", maxLength: 255 })}
                                        isInvalid={!!errors.declaradoporcargo}
                                        readOnly={readOnly}
                                        size="sm"
                                    />
                                    <ErrorMessage error={errors.declaradoporcargo} />
                                </Form.Group>
                            </Col>
                        </Form.Row>
                        
                        {estado === EstadoCertMaster.Emitido
                        && <Form.Row>
                        <Col xs={12} md={4}>
                            <Form.Group controlId="certificado_id">
                                <Form.Label>Certificado firmado:</Form.Label>
                                <ArchivoSelector
                                    control={control}
                                    name="certificado_id"
                                    readOnly={!puedeEditar}
                                />
                            </Form.Group>
                        </Col>
                        </Form.Row>}
                        {anulado &&
                            <Form.Row>
                                <Col xs={12} md={12}>
                                    <Form.Group controlId="razonnulacion">
                                        <Form.Label>Razón de la Anulación:</Form.Label>
                                        <Form.Control
                                            name="razonnulacion"
                                            as="textarea"
                                            maxLength={1000}
                                            rows={2}
                                            ref={register({ required: 'Ingrese la razón de la anulación', maxLength: 1000 })}
                                            isInvalid={!!errors.razonnulacion}
                                            readOnly={readOnly}
                                        />
                                        <ErrorMessage error={errors.razonnulacion} />
                                    </Form.Group>

                                </Col>
                            </Form.Row>}
                    </Tab>
                    <Tab eventKey="Operador" title="Operador">
                        {!readOnly &&
                            <Form.Row>
                                <Col xs={12} md={12}>
                                    <div className="float-right" >
                                        <Button size="sm" id="btnEditarOperador" variant="outline-dark" type="button" onClick={() => setMostrarEdicionOperador(true)} >
                                            <FaEdit style={{ cursor: "pointer" }} /> Editar
                                        </Button>
                                        <Modal size="lg" show={mostrarEdicionOperador} onHide={ocultarEdicionOperador}>
                                            <Modal.Header closeButton>
                                                <h4> <FaEdit /> Editar Operador</h4>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <OperadorForm paisId={paisId} operadorId={operadorId} cerrar={ocultarEdicionOperador} />
                                            </Modal.Body>
                                        </Modal>
                                        {" "}
                                        <Button size="sm" id="btnSincOperador" variant="outline-dark" type="button" onClick={sincronizarrDatosOperador} >
                                            <FaSync style={{ cursor: "pointer" }} /> Sincronizar
                                        </Button>
                                    </div>
                                </Col>
                            </Form.Row>}
                        <Form.Row>
                            <Col xs={12} md={1}>
                                <Form.Group controlId="codoperador">
                                    <Form.Label>Código:</Form.Label>
                                    <Form.Control
                                        name="codoperador"
                                        type="text"
                                        ref={register()}
                                        readOnly={true}
                                        size="sm"
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={11}>
                                <Form.Group controlId="nombreoperador">
                                    <Form.Label>Nombre:</Form.Label>
                                    <Form.Control
                                        name="nombreoperador"
                                        type="text"
                                        ref={register()}
                                        readOnly={true}
                                        size="sm"
                                    />
                                </Form.Group>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col xs={12} md={3}>
                                <Form.Group controlId="contacto">
                                    <Form.Label>Contacto:</Form.Label>
                                    <Form.Control
                                        name="contacto"
                                        type="text"
                                        ref={register()}
                                        readOnly={true}
                                        size="sm"
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={3}>
                                <Form.Group controlId="contacto2">
                                    <Form.Label>Contacto 2:</Form.Label>
                                    <Form.Control
                                        name="contacto2"
                                        type="text"
                                        ref={register()}
                                        readOnly={true}
                                        size="sm"
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Group controlId="web">
                                    <Form.Label>Web:</Form.Label>
                                    <Form.Control
                                        name="web"
                                        type="text"
                                        ref={register()}
                                        readOnly={true}
                                        size="sm"
                                    />
                                </Form.Group>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col xs={12} md={6}>
                                <Form.Group controlId="correo">
                                    <Form.Label>Correo:</Form.Label>
                                    <Form.Control
                                        name="correo"
                                        type="text"
                                        ref={register()}
                                        readOnly={true}
                                        size="sm"
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Group controlId="correo2">
                                    <Form.Label>Correo 2:</Form.Label>
                                    <Form.Control
                                        name="correo2"
                                        type="text"
                                        ref={register()}
                                        readOnly={true}
                                        size="sm"
                                    />
                                </Form.Group>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col xs={12} md={6}>
                                <Form.Group controlId="ciudad">
                                    <Form.Label>Ciudad:</Form.Label>
                                    <Form.Control
                                        name="ciudad"
                                        type="text"
                                        ref={register()}
                                        readOnly={true}
                                        size="sm"
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Group controlId="departamento">
                                    <Form.Label>Departamento:</Form.Label>
                                    <Form.Control
                                        name="departamento"
                                        type="text"
                                        ref={register()}
                                        readOnly={true}
                                        size="sm"
                                    />
                                </Form.Group>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col xs={12} md={12}>
                                <Form.Group controlId="direccion">
                                    <Form.Label>Dirección:</Form.Label>
                                    <Form.Control
                                        name="direccion"
                                        as="textarea"
                                        rows={2}
                                        ref={register()}
                                        readOnly={true}
                                    />
                                </Form.Group>
                            </Col>
                        </Form.Row>
                    </Tab>
                </Tabs>
                <Cargando activo={procesando || loading} mensaje={mensaje} />
                {mensaje && <Alert variant="info">{mensaje}</Alert>}
                {!(procesando || loading) && (
                    <>
                        <hr />
                        <div className="float-left">
                            {!anulado 
                                && isAllowed(user, Acciones.Transacciones.CertMaster.Editar) &&
                                <Button id="btnGenerar" variant="primary" type="button" onClick={handleSubmit(onSubmit)} >
                                    <FaFileWord id='svgGenerar' /> Generar
                                </Button>
                            }
                            {" "}
                            {!anulado 
                                && getValues('estado') === EstadoCertMaster.Emitido 
                                && isAllowed(user, Acciones.Transacciones.CertMaster.Editar) &&
                                <Button id="btnAnular"
                                    variant="danger"
                                    type="button"
                                    onClick={(e) => anular(e)}>
                                    <TiDelete id='svgAnular' /> Anular
                                </Button>
                            }
                        </div>
                        <div className="float-right">
                            { puedeEditar && !anulado
                            && <>
                                <Button id="btnGuardar" variant="primary" type="button" onClick={handleSubmit(onSubmit)} >
                                    <FaSave id="svgGuardar" /> Guardar
                                </Button>{" "}
                                <Button variant="secondary" onClick={() => cerrar()}>
                                    <FaUndo /> Cancelar
                                </Button>
                            </>}

                            {(!puedeEditar || anulado) &&  
                                    <Button variant="secondary" onClick={() => cerrar()}>
                                        <FaTimes /> Cerrar
                                    </Button> 
                            }
                        </div>
                    </>
                )}
            </Form>
        </>
    );
}
