import { dateToLocalString } from "utiles/FormatHelper";
import apiClient, { getErrorMessage, handleError } from "./api";



class BaseService {
  static getUrlBase = () => "/api/obj";

  static async traerLista(cancelToken = null) {
    var lista = [];
    await apiClient
      .get(this.getUrlBase(), { cancelToken: cancelToken?.token })
      .then((response) => {
        lista = response.data;
      })
      .catch(handleError);

    return lista;
  }

  static async agregar(obj) {
    delete obj.id;
    return await apiClient
      .post(this.getUrlBase(), obj)
      .then((response) => {
        if (response.status === 201) {
          this.formatearFechas(response.data);
          return response.data;
        }
        else if (response.data && response.data.error)
        alert(response.data.error);
      })
      .catch(handleError);
  }

  static async traer(id, cancelToken = null) {
    var obj = null;
    await apiClient
      .get(this.getUrlBase() + `/${id}`, { cancelToken: cancelToken?.token })
      .then((response) => {
        if (response.data !== undefined) {
          obj = response.data;
          this.formatearFechas(obj);
        }
      })
      .catch(handleError);
    return obj;
  }
  static formatearFechas(obj) {
    if (obj.created_at) obj.created_at = dateToLocalString(obj.created_at)
    if (obj.updated_at) obj.updated_at = dateToLocalString(obj.updated_at)
  }
  static async modificar(obj) {
    return await apiClient
      .put(this.getUrlBase() + `/${obj.id}`, obj)
      .then((response) => {
        if (response.status === 200) {
          this.formatearFechas(response.data);
          return response.data;
        }
        else if (response.data && response.data.error)
          alert(response.data.error);
      })
      .catch(handleError);
  }

  static async eliminar(obj) {
    try {
      let response = await apiClient.delete(this.getUrlBase() + `/${obj.id}`);

      console.log('delete: ', response);
      if (response.status === 204)
        return true;
      else if (response.data && response.data.error)
        alert(response.data.error);
    }
    catch (err) {
      handleError(err);
      alert(getErrorMessage(err));
    }
    return false;
  }
}

export default BaseService;
