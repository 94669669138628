import { AuthContext } from 'context/AuthContext';
import { isAuthenticated } from 'hooks/Auth';
import React, { useContext } from 'react'

export default function Inicio(){
  const {user} = useContext(AuthContext)
  
  if(!isAuthenticated(user))
    return <h4>¡Bienvenido!</h4>;
    
  return <h4>¡Bienvenido, {user?.name?.toUpperCase()}!</h4>;
}