import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import Cargando from "../comunes/Cargando";
import Button from "react-bootstrap/Button";
import { useForm } from "react-hook-form";
import { Alert, Col, Form } from "react-bootstrap";
import { getErrorMessage, handleError } from "services/api";
import { FaSave, FaTimes, FaUndo } from "react-icons/fa";
import UsuarioService from "services/UsuarioService";

export default function UsuarioForm({
  usuarioId = 0,
  cerrar,
  consulta = false,
  rolId = 0,
}) {
  const [loading, setLoading] = useState(false);
  const [procesando, setProcesando] = useState(false);
  const { register, watch, errors, handleSubmit, reset, setError } = useForm();
  const [message, setMessage] = useState("");
  const [readOnly, setReadOnly] = useState(consulta);
  const password = useRef({});
  password.current = watch("password", "");

  useEffect(() => {
    let cancelToken = axios.CancelToken.source();
    if (usuarioId === 0) reset({ name: "", email: "" });
    else {
      setLoading(true);

      UsuarioService.traer(usuarioId, cancelToken)
        .then((result) => {
          if (result !== undefined) {
            setLoading(false);
            if (result !== undefined) {
              reset(result);
            } else {
              setMessage(`Usuario con Id '${usuarioId}' no ha sido encontrado`);
              setReadOnly(true);
            }
          }
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            handleError(err);
            setLoading(false);
            setReadOnly(true);
            setMessage(getErrorMessage(err));
          }
        });
    }
    return () => {
      cancelToken.cancel("Carga de Usuario cancelada por el usuario.");
    };
  }, [reset, usuarioId]);

  const onGuardar = (data) => {
    setProcesando(true);
    setMessage("");
    const responseHandler = (user) => {
      if (!!user) {
        cerrar(user);
        reset({ name: "", correo: "" });
      } else setProcesando(false);
    };
    const localErrorHandler = (err) => {
      setProcesando(false);
      setMessage(getErrorMessage(err));
      if (err?.response?.data) {
        for (let field in err.response.data) {
          setError(field, { message: err.response.data[field] });
        }
      }
    };
    if (usuarioId !== 0) {
      data.id = usuarioId;
      UsuarioService.modificar(data)
        .then(responseHandler)
        .catch(localErrorHandler);
    } else {
      delete data.password_repeat;
      if (rolId > 0) data.rolId = rolId;

      UsuarioService.agregar(data)
        .then(responseHandler)
        .catch(localErrorHandler);
    }
  };
  if (loading)
    return (
      <>
        {<Alert variant="info">Cargando...</Alert>}
        <Cargando activo={loading} />
      </>
    );

  return (
    <>
      <Form onSubmit={handleSubmit(onGuardar)}>
        <Form.Group controlId="formName">
          <Form.Row>
            <Col xs={9}>
              <Form.Label>Nombre:</Form.Label>
              <Form.Control
                name="name"
                type="text"
                ref={register({ required: "Ingrese el nombre" })}
                isInvalid={!!errors.name}
                readOnly={readOnly}
              />
              {errors.name && (
                <small className="text-danger form-text">
                  {errors.name?.message}
                </small>
              )}
            </Col>
            <Col xs={3}>
              <Form.Label>Siglas:</Form.Label>
              <Form.Control
                name="code"
                type="text"
                maxLength={3}
                ref={register({ required: "Ingrese las siglas" })}
                isInvalid={!!errors.code}
                readOnly={readOnly}
              />
              {errors.code && (
                <small className="text-danger form-text">
                  {errors.code?.message}
                </small>
              )}
            </Col>
          </Form.Row>
        </Form.Group>
        <Form.Group controlId="formEmail">
          <Form.Label>Correo:</Form.Label>
          <Form.Control
            name="email"
            type="text"
            ref={register({
              required: "Ingrese el correo",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Dirección de correo no es inválida.",
              },
              validate: async (value) => {
                let exist = await UsuarioService.existeCorreo(usuarioId, value);
                if (exist) return "Correo ya está asignado a otro usuario.";
                else return true;
              },
            })}
            isInvalid={!!errors.email}
            readOnly={readOnly}
          />
          {errors.email && (
            <small className="text-danger form-text">
              {errors.email?.message}
            </small>
          )}
        </Form.Group>
        <Form.Group controlId="forminfo">
          <Form.Label>Descripción:</Form.Label>
          <Form.Control
            name="info"
            as="textarea"
            rows={3}
            ref={register()}
            isInvalid={!!errors.info}
            readOnly={readOnly}
          />
          {errors.info && (
            <small className="text-danger form-text">
              {errors.info?.message}
            </small>
          )}
        </Form.Group>
        {usuarioId === 0 && (
          <Form.Group>
            <Form.Row>
              <Col xs={12} md={6}>
                <Form.Label>Contraseña:</Form.Label>
                <Form.Control
                  name="password"
                  type="password"
                  ref={register({
                    required: "Debe ingresar la contraseña",
                    minLength: {
                      value: 8,
                      message: "Muy corto, debe tener al menos 8 caracteres.",
                    },
                  })}
                  isInvalid={!!errors.password}
                  readOnly={readOnly}
                />
                {errors.password && (
                  <small className="text-danger form-text">
                    {errors.password?.message}
                  </small>
                )}
              </Col>
              <Col xs={12} md={6}>
                <Form.Label>Confirmar contraseña:</Form.Label>
                <Form.Control
                  name="password_repeat"
                  type="password"
                  ref={register({
                    validate: (value) =>
                      value === password.current ||
                      "Las contraseñas deben coincidir.",
                  })}
                  isInvalid={!!errors.password_repeat}
                  readOnly={readOnly}
                />
                {errors.password_repeat && (
                  <small className="text-danger form-text">
                    {errors.password_repeat?.message}
                  </small>
                )}
              </Col>
            </Form.Row>
          </Form.Group>
        )}
        {usuarioId > 0 && (
          <Form.Group controlId="formBitacora">
            <Form.Row>
              <Col xs={12} md={6}>
                <Form.Label>Creación:</Form.Label>
                <Form.Control
                  name="created_at"
                  type="text"
                  readOnly={true}
                  ref={register({})}
                />
              </Col>
              <Col xs={12} md={6}>
                <Form.Label>Última actualización:</Form.Label>
                <Form.Control
                  name="updated_at"
                  type="text"
                  readOnly={true}
                  ref={register()}
                />
              </Col>
            </Form.Row>
          </Form.Group>
        )}
        <Cargando activo={procesando} />
        {message && <Alert variant="danger">{message}</Alert>}
        {!procesando && (
          <div className="float-right">
            {!readOnly && (
              <>
                <Button variant="primary" type="submit">
                  <FaSave /> Guardar
                </Button>{" "}
                <Button variant="secondary" onClick={() => cerrar()}>
                  <FaUndo /> Cancelar
                </Button>
              </>
            )}

            {readOnly && (
              <>
                <Button variant="secondary" onClick={() => cerrar()}>
                  <FaTimes /> Cancelar
                </Button>
              </>
            )}
          </div>
        )}
      </Form>
    </>
  );
}
