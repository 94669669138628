import React, { useContext, useEffect, useState } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import ReactDataGrid from "react-data-grid";
import { Data } from "react-data-grid-addons";
import Cargando from "../comunes/Cargando";
import { handleError } from "services/api";
import { useContentDimensions, useIsMobile } from "hooks/DeviceUtils";
import { FaTrash } from "react-icons/fa";
import { isAllowed, useCurrentAllowed } from 'hooks/Auth'
import Encabezado from "components/comunes/Encabezado";
import CustomToolbar from 'components/comunes/CustomToolbar'
import { DateFormatter, DateTimeFormatter } from "utiles/FormatHelper";
import axios from "axios";
import usePaises from "hooks/usePaises"; 
import useOperadores from "hooks/useOperadores";
import CertMasterService from "services/CertMasterService";
import { Acciones } from "utiles/Constantes";
import { AuthContext } from "context/AuthContext";
import EliminarPF1Service from "services/EliminarPF1Service";

const defaultColumnProperties = {
  filterable: true,
  resizable: true,
  sortable: true,
};


const selectors = Data.Selectors;

const handleFilterChange = (filter) => (filters) => {
  const newFilters = { ...filters };
  if (filter.filterTerm) {
    newFilters[filter.column.key] = filter;
  } else {
    delete newFilters[filter.column.key];
  }
  return newFilters;
};

function getRows(rows, filters) {
  return selectors.getRows({ rows, filters });
}
const sortRows = (initialRows, sortColumn, sortDirection) => (rows) => {
  const comparer = (a, b) => {
    if (sortDirection === "ASC") {
      return a[sortColumn] > b[sortColumn] ? 1 : -1;
    } else if (sortDirection === "DESC") {
      return a[sortColumn] < b[sortColumn] ? 1 : -1;
    }
  };
  return sortDirection === "NONE" ? initialRows : [...rows].sort(comparer);
};


export default function EliminarPF1() {  
  const [datos, setDatos] = useState([]); 
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({});
  const { paises, paisId, setPaisId, loadingPais: loadingPaises } = usePaises();
  const { operadores, operadorId, setOperadorId, loadingOperador } = useOperadores(paisId);
  const { height, width } = useContentDimensions()
  const isMobile = useIsMobile();
  const acciones = useCurrentAllowed(); 
  const { user } = useContext(AuthContext);
  const verTodos = isAllowed(user, Acciones.Catalogos.Operadores.VerTodos);



  const columns = [
    { key: "id", name: "", width: 80, frozen: true, formatter: () => "" },
    {
      key: "producto",
      name: "Producto",
      width: 400,
    },
    {
      key: "n_automatico",
      name: "N°(Automático)",
      width: 100,
      sortDescendingFirst: true,
    },
    {
      key: "tipo",
      name: "Tipo",
      width: 100,
    },
    {
      key: "lote",
      name: "Lote",
      width: 160,
    },
    {
      key: "factura",
      name: "Factura",
      width: 160,
    },
    {
      key: "fecha_registro",
      name: "Fecha de Registro",
      width: 160,
      formatter: DateTimeFormatter
    }

  ].map((c) => ({ ...c, ...defaultColumnProperties }));


  async function cargarDatos(cancelToken) {

    if (operadorId === null) return;

    setLoading(true);
    try {
      let result = [];
      if (operadorId > 0)
        result = await EliminarPF1Service.traerLista(operadorId, cancelToken)
      
      if (result !== undefined) {
        setDatos(result);
      }
      
    }
    catch (err) {
      console.error(err);
    }

    setLoading(false);
  };


  useEffect(() => {

    let cancelToken = axios.CancelToken.source();
    cargarDatos(cancelToken);
    return () => {
      cancelToken.cancel("Carga cancelada por el usuario.");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paisId, operadorId]);

  const getCellActions = (column, row) => {

    if (column.key === 'id' && row?.id !== 0) {
      let actions = []

      actions.push({
        icon: (
          <FaTrash style={{ cursor: "pointer" }} />
        ),
        callback: () => {
          if (
            window.confirm(`Está seguro de eliminar el registo de PF1 N°: '${row.n_automatico}' Tipo: ${row.tipo} Factura: ${row.factura} Lote: ${row.lote}`)
          ) {
            setLoading(true)
            EliminarPF1Service.eliminar(row.id)
              .then(() => cargarDatos())
              .catch(handleError)
              .finally(() => setLoading(false));
          }
        },
      });

      return actions;
    }

  };

  const filteredRows = getRows(datos, filters);

  // if (acciones === undefined)
  //   return <Cargando activo={true} />

  let titulo = ''
  if (operadores && operadores.length > 0 && operadorId > 0) {
    let operador = operadores.find(x => x.id === operadorId)
    titulo = `${operador.nombre} (${operador.codigo})`
  }
  return (
    <>
      <Encabezado {...acciones} />
      
      {(!!titulo) && <h5><label>{titulo}</label></h5>}

      <Cargando activo={loading} mensaje='Cargando PF1...' />
      <Cargando activo={loadingPaises} mensaje='Cargando Paises...' />
      <Cargando activo={loadingOperador} mensaje='Cargando Operadores...' />
      <ReactDataGrid

        columns={columns}
        rowGetter={(i) => filteredRows[i]}
        rowsCount={filteredRows.length}
        enableCellSelect={(acciones && acciones?.Edita)}
        toolbar={
          <CustomToolbar
            enableFilter={true}
            enableRefresh={true}
            onAddRow={() => {
              if (operadorId === 0)
                alert('Debe selecionar un Operador.');
            }}
            onReloadClick={() => cargarDatos()}
            isMobile={isMobile}
            acciones={acciones}
          >
            <Form inline>
              {verTodos &&
                <Form.Group>
                  <Form.Label htmlFor="PaisSelector" srOnly>
                    Pais:
                  </Form.Label>
                  <Form.Control size={isMobile ? 'sm' : ''} as="select" variant="outline-dark" placeholder="País" id="PaisSelector" onChange={(e) => {
                    setPaisId(e.target.value);
                    setDatos([]);
                    setOperadorId(0);
                  }} >
                    {paises.map(x => <option key={x.id} value={x.id}>{x.nombre}</option>)}
                  </Form.Control>
                </Form.Group>}
              <Form.Group>
                <Form.Label htmlFor="OperadorSelector" srOnly>
                  Operador:
                </Form.Label>
                <Form.Control size={isMobile ? 'sm' : ''} as="select" variant="outline-dark" placeholder="Operador" id="OperadorSelector" onChange={(e) => setOperadorId(parseInt(e.target.value))} >
                  {(operadores && operadores.length > 0) && operadores.map(x => <option key={x.id} value={x.id}>{x.codigo}</option>)}
                </Form.Control>
              </Form.Group>
            </Form>
          </CustomToolbar>
        }
        onAddFilter={(filter) => setFilters(handleFilterChange(filter))}
        onClearFilters={() => setFilters({})}
        minWidth={width}
        minHeight={height - 35}
        getCellActions={getCellActions}
        onGridSort={(sortColumn, sortDirection) =>
          setDatos(sortRows(datos, sortColumn, sortDirection))
        }
      />
    </>
  );
}


