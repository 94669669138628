import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { Button, Form, FormControl, InputGroup, Modal } from "react-bootstrap";
import ArchivoService from "services/ArchivoService";
import { handleError } from "services/api";
import { FaDownload, FaFileUpload, FaPlus } from "react-icons/fa";
import ArchivoForm from "components/catalogos/ArchivoForm";
import { saveAs } from 'file-saver';
import axios from "axios";

const ArchivoSelector = ({ control, name, readOnly, required = false, isInvalid = false, archivo = null }) => {
    const [Archivo, setArchivo] = useState(archivo);
    const [agregar, setAgregar] = useState(false);
    const [text, setText] = useState('');
    const [procesando, setProcesando] = useState(false);

    return <Controller
        name={name}
        control={control}
        rules={{ required }}
        render={({ onChange, value }) => {
           
            let cancelToken = axios.CancelToken.source();
            if (value > 0 && (Archivo === undefined || Archivo === null) && !procesando) {
                //si no es nuevo y no se ha pasado archivo, hay que cargarlo
                setText('Cargando...');
                setProcesando(true);
                ArchivoService.traer(value, cancelToken)
                    .then((result) => {
                        if (result !== undefined) {
                            setArchivo(result);
                            setText(result.nombre);
                            setProcesando(false);
                        }
                        else
                            setText('');
                    })
                    .catch((err) =>{ 
                        handleError(err);
                        setText('');
                        setProcesando(false);
                    });
            }
            const cerrarAgregar = (archivo) => {
                if(archivo) {
                    setArchivo(archivo);
                    onChange(archivo.id);
                    setText(archivo.nombre);
                }
                //else if(window.confirm('¿Está seguro de no agregar el archivo?'))
                setAgregar(false);
            };
            const descargar = async () => {
                try{
                    if(value === 0) return;
                    setText('Descargando: ' + Archivo.nombre + '...');
                    setProcesando(true);
                    let contenido = await ArchivoService.traerContenido(value);
                    console.log(contenido);
                    if(contenido){
                        let blob = new Blob([contenido], {type: Archivo.tipo});
                        console.log(blob);
                        saveAs(blob, Archivo.nombre);
                    }

                }
                catch(err){
                     handleError(err);
                }
                finally{
                    setText(Archivo?.nombre);
                    setProcesando(false);
                }
            }
            return <>
               <Modal show={agregar} onHide={() => cerrarAgregar()}>
                    <Modal.Header closeButton>
                        <h4><FaFileUpload /> Agregar Archivo</h4>
                    </Modal.Header>
                    <Modal.Body>
                        <ArchivoForm 
                            referencia="certtransaccion" 
                            campo="factura_id" 
                            edicionId={value} 
                            cerrar={cerrarAgregar}/>
                    </Modal.Body>
                </Modal>
                <InputGroup className="mb-3">
                    <Form.Control
                        type="text"
                        readOnly={true}
                        value={text}
                        isInvalid={isInvalid}
                        size="sm" />
                    <InputGroup.Append>
                        <Button disabled={(procesando || readOnly)} variant="outline-primary" size="sm" onClick={() => setAgregar(true)}><FaFileUpload /></Button>
                        { (value > 0 && !!Archivo) && <Button disabled={procesando} variant="outline-primary" size="sm" onClick={descargar}><FaDownload /></Button>}
                    </InputGroup.Append>
                </InputGroup>
            </>;
        }}
    />
};

export default ArchivoSelector;