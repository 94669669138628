import apiClient, { handleError } from "./api";
import BaseService from "./BaseService";

export default class OperadorService extends BaseService {
  static getUrlBase = () => "/api/operador";

  

  static async traerListaPorPaisId(rolId, cancelToken) {
    var lista = [];
    await apiClient
      .get(this.getUrlBase() + `/traerListaPorPaisId/${rolId}`, {
        cancelToken: cancelToken?.token,
      })
      .then((response) => {
        lista = response.data;
      })
      .catch(handleError);

    return lista;
  }
  static async existeCodigo(paisId, operadorId, codigo) {
    return await apiClient
      .post(this.getUrlBase() + `/existeCodigo/${operadorId}`, { codigo, paisId })
      .then((response) => {
        return (response?.data)
      })
      .catch(handleError);
  }
  
}