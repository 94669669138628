import React, { useEffect, useState } from "react";
import axios from "axios";
import Cargando from "../comunes/Cargando";
import Button from "react-bootstrap/Button";
import { useFieldArray, useForm } from "react-hook-form";
import { Alert, Card, Col, Form, Tab, Table, Tabs } from "react-bootstrap";
import { getErrorMessage, handleError } from "services/api";
import { FaSave, FaTimes, FaTrash, FaUndo } from "react-icons/fa";
import { HiUserAdd } from "react-icons/hi";
import UsuarioService from "services/UsuarioService";
import OperadorService from "services/OperadorService";
import usePaises from "hooks/usePaises";
import useCoordinadores from "hooks/useCoordinadores";
import ErrorMessage from "components/comunes/ErrorMessage";
import { Roles } from "utiles/Constantes";
import UsuarioSelector from "components/seguridad/UsuarioSelector";


export default function OperadorForm({
    operadorId = 0,
    cerrar,
    consulta = false,
    paisId = 0,
}) {
    const [loadingOperador, setLoadingOperador] = useState(false);
    const [procesando, setProcesando] = useState(false);
    const { register, errors, handleSubmit, reset, setError, control } = useForm();
    const [mensaje, setMensaje] = useState("");
    const [readOnly, setReadOnly] = useState(consulta);
    const { paises, loadingPaises } = usePaises();
    const { coordinadores, loadingCoordinadores } = useCoordinadores();
    const detUsers = useFieldArray({ control, name: "users" });
    const [selUsuario, setSelUsuario] = useState(false);

    useEffect(() => {
        let cancelToken = axios.CancelToken.source();
        if (operadorId === 0) reset({ pais_id: paisId });
        else {

            setMensaje('Cargando Operador...');
            setLoadingOperador(true);

            OperadorService.traer(operadorId, cancelToken)
                .then((result) => {
                    setLoadingOperador(false);

                    if (result !== undefined) {
                        reset(result);
                        setMensaje('');
                    } else {
                        setMensaje(`Operador con Id '${operadorId}' no ha sido encontrado`);
                        setReadOnly(true);
                    }
                })
                .catch((err) => {
                    if (!axios.isCancel(err)) {
                        handleError(err);
                        setReadOnly(true);
                        setMensaje(getErrorMessage(err));
                    }
                    setLoadingOperador(false);
                });
        }
        return () => {
            cancelToken.cancel("Carga de Operador cancelada por el usuario.");
        };
    }, [reset, operadorId, paisId]);

    const onGuardar = (data) => {
        setProcesando(true);
        setMensaje("Guardando Operador...");
        const responseHandler = (exito) => {
            if (exito) {
                cerrar(true);
                reset({ name: "", correo: "" });
            } else setProcesando(false);
        };
        const localErrorHandler = (err) => {
            setProcesando(false);
            setMensaje(getErrorMessage(err));
            if (err?.response?.data) {
                for (let field in err.response.data) {
                    setError(field, { message: err.response.data[field] });
                }
            }
        };
        // console.log('data', data);
        if (operadorId !== 0) {
            data.id = operadorId;
            OperadorService.modificar(data)
                .then(responseHandler)
                .catch(localErrorHandler);
        } else {

            OperadorService.agregar(data)
                .then(responseHandler)
                .catch(localErrorHandler);
        }
    };

    const onMostrarSelUsuario = () => {
        setSelUsuario(true);
    };

    const traerListaUsuarios = async (criterio, cancelToken) => {
        try {
            let lista = await UsuarioService.traerListaPorRolTexto(Roles.Operador, criterio);
            return lista.filter((user) => {
                return !detUsers.fields.some(x => x.id === user.id);
            });
        }
        catch (err) {
            console.error(err);
            handleError(err);
        }
    };
    const onEliminarUsuario = (user, index) => {
        if (window.confirm(`¿Está seguro de quitar al usuario '${user.name}'<${user.email}>?`))
            detUsers.remove(index);
    };
    const onSelectUsuario = (user) => {

        detUsers.append(user);
        return `Usuario ${user.name} asociado al operador.`;
    }
    if (loadingOperador)
        return <Cargando activo={true} mensaje='Cargando Operador...' />
    let cargando = (procesando || loadingOperador || loadingCoordinadores);

    return (
        <>
            <Cargando activo={cargando} mensaje={mensaje} />
            <Form onSubmit={handleSubmit(onGuardar)}>
                <Tabs defaultActiveKey="Datos" id="tabOperador">
                    <Tab eventKey="Datos" title="Datos del Operador">


                        <Form.Row>
                            <Col xs={12} md={9}>
                                <Form.Group controlId="formCoordinador">
                                    <Form.Label>Coordinador:</Form.Label>
                                    <Form.Control
                                        name="coordinador_id"
                                        as="select"
                                        ref={register({
                                            required: "Seleccione el coordinador",
                                        })}
                                        isInvalid={!!errors.coordinador_id}
                                        readOnly={readOnly}
                                    >
                                        {coordinadores.map(x => <option key={x.id} value={x.id}>{x.name}</option>)}
                                    </Form.Control>
                                    <ErrorMessage error={errors.coordinador_id} />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={3}>
                                <Form.Group controlId="pais_id">
                                    <Form.Label>País:</Form.Label>
                                    <Form.Control
                                        name="pais_id"
                                        as="select"
                                        ref={register()}
                                        disabled={readOnly || operadorId !== 0}

                                    >
                                        {paises.map(x => <option key={x.id} value={x.id}>{x.nombre}</option>)}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col xs={12} md={9}>
                                <Form.Group controlId="nombre">
                                    <Form.Label>Nombre:</Form.Label>
                                    <Form.Control
                                        name="nombre"
                                        maxLength={255}
                                        type="text"
                                        ref={register({ required: "Ingrese el nombre", maxLength: 1000 })}
                                        isInvalid={!!errors.nombre}
                                        readOnly={readOnly}
                                    />
                                    <ErrorMessage error={errors.nombre} />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={3}>
                                <Form.Group controlId="codigo">
                                    <Form.Label >Código:</Form.Label>
                                    <Form.Control
                                        name="codigo"
                                        type="text"
                                        maxLength={3}
                                        ref={register({
                                            required: "Ingrese el código",
                                            validate: async (value) => {
                                                let existe = await OperadorService.existeCodigo(paisId, operadorId, value);
                                                if (existe) return "Código ya está asignado a otro Operador.";
                                                else return true;
                                            },
                                        })}
                                        isInvalid={!!errors.codigo}
                                        readOnly={readOnly}
                                    />
                                    <ErrorMessage error={errors.codigo} />
                                </Form.Group>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col xs={12} md={3}>
                                <Form.Group controlId="Tipo">
                                    <Form.Label >Tipo:</Form.Label>
                                    <Form.Control
                                        name="tipo"
                                        as="select"
                                        ref={register()}
                                    >
                                        <option value="CO">Colectivo</option>
                                        <option value="IN">Individual</option>
                                    </Form.Control>
                                    <ErrorMessage error={errors.tipo} />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={3}>
                                <Form.Group controlId="ciudad">
                                    <Form.Label>Ciudad:</Form.Label>
                                    <Form.Control
                                        name="ciudad"
                                        type="text"
                                        maxLength={255}
                                        ref={register({ required: "Ingrese la ciudad", maxLength: 255 })}
                                        isInvalid={!!errors.ciudad}
                                        readOnly={readOnly}
                                    />
                                    <ErrorMessage error={errors.ciudad} />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={3}>
                                <Form.Group controlId="departamento">
                                    <Form.Label>Departamento:</Form.Label>
                                    <Form.Control
                                        name="departamento"
                                        type="text"
                                        maxLength={255}
                                        ref={register({ required: "Ingrese el Departamento", maxLength: 255 })}
                                        isInvalid={!!errors.departamento}
                                        readOnly={readOnly}
                                    />
                                    <ErrorMessage error={errors.departamento} />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={3}>
                                <Form.Group controlId="ruc">
                                    <Form.Label>Ruc:</Form.Label>
                                    <Form.Control
                                        name="ruc"
                                        type="text"
                                        maxLength={255}
                                        ref={register({ required: "Ingrese el Ruc", maxLength: 255 })}
                                        isInvalid={!!errors.ruc}
                                        readOnly={readOnly}
                                    />
                                    <ErrorMessage error={errors.ruc} />
                                </Form.Group>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col xs={12} md={3}>
                                <Form.Group controlId="Contacto">
                                    <Form.Label >Contacto:</Form.Label>
                                    <Form.Control
                                        name="contacto"
                                        type="text"
                                        maxLength={50}
                                        ref={register({ required: "Ingrese el Contacto", maxLength: 50 })}
                                        isInvalid={!!errors.contacto}
                                        readOnly={readOnly}
                                    />
                                    <ErrorMessage error={errors.contacto} />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={3}>
                                <Form.Group controlId="contacto2">
                                    <Form.Label>Contacto 2:</Form.Label>
                                    <Form.Control
                                        name="contacto2"
                                        type="text"
                                        maxLength={50}
                                        ref={register({ maxLength: 50 })}
                                        isInvalid={!!errors.contacto2}
                                        readOnly={readOnly}
                                    />
                                    <ErrorMessage error={errors.contacto2} />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Group controlId="web">
                                    <Form.Label>Web:</Form.Label>
                                    <Form.Control
                                        name="web"
                                        type="text"
                                        maxLength={255}
                                        ref={register({ required: "Ingrese el Web", maxLength: 255 })}
                                        isInvalid={!!errors.web}
                                        readOnly={readOnly}
                                    />
                                    <ErrorMessage error={errors.web} />
                                </Form.Group>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col xs={12} md={6}>
                                <Form.Group controlId="correo">
                                    <Form.Label>Correo:</Form.Label>
                                    <Form.Control
                                        name="correo"
                                        maxLength={150}
                                        type="text"
                                        ref={register({ required: "Ingrese el correo.", maxLength: 150 })}
                                        isInvalid={!!errors.correo}
                                        readOnly={readOnly}
                                    />
                                    <ErrorMessage error={errors.correo} />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Group controlId="correo2">
                                    <Form.Label>Correo 2:</Form.Label>
                                    <Form.Control
                                        name="correo2"
                                        maxLength={150}
                                        type="text"
                                        ref={register({ maxLength: 150 })}
                                        isInvalid={!!errors.correo2}
                                        readOnly={readOnly}
                                    />
                                    <ErrorMessage error={errors.correo2} />
                                </Form.Group>
                            </Col>

                        </Form.Row>
                        <Form.Group controlId="formBasicCheckbox">
                            <Form.Check
                                name="solo_comercializador"
                                type="checkbox"
                                label="Operador es sólo comercializador"
                                ref={register()}
                                readOnly={readOnly}
                            />
                        </Form.Group>

                        <Tabs defaultActiveKey="direccionLegal" id="tabDirecciones">
                            <Tab eventKey="direccionLegal" title="Direccion Legal">
                                <Form.Group controlId="direccion_legal">
                                    <Form.Label srOnly>Dirección Legal:</Form.Label>
                                    <Form.Control
                                        name="direccion_legal"
                                        as="textarea"
                                        maxLength={1000}
                                        rows={2}
                                        ref={register({ required: "Ingrese la dirección legal", maxLength: 1000 })}
                                        isInvalid={!!errors.direccion_legal}
                                        readOnly={readOnly}
                                    />
                                    <ErrorMessage error={errors.direccion_legal} />
                                </Form.Group>
                            </Tab>
                            <Tab eventKey="direccionProduccion1" title="Dir. Producción 1">
                                <Form.Group controlId="direccion_produccion1">
                                    <Form.Label srOnly>Dirección de Producción 1:</Form.Label>
                                    <Form.Control
                                        name="direccion_produccion1"
                                        as="textarea"
                                        maxLength={1000}
                                        rows={2}
                                        ref={register({ required: "Ingrese la dirección legal", maxLength: 1000 })}
                                        isInvalid={!!errors.direccion_produccion1}
                                        readOnly={readOnly}
                                    />
                                </Form.Group>
                            </Tab>
                            <Tab eventKey="direccionProduccion2" title="Dir. Producción 2">
                                <Form.Group controlId="direccion_produccion2">
                                    <Form.Label srOnly>Dirección de Producción 2:</Form.Label>
                                    <Form.Control
                                        name="direccion_produccion2"
                                        as="textarea"
                                        maxLength={1000}
                                        rows={2}
                                        ref={register({ maxLength: 1000 })}
                                        isInvalid={!!errors.direccion_produccion2}
                                        readOnly={readOnly}
                                    />
                                    <ErrorMessage error={errors.direccion_produccion2} />
                                </Form.Group>
                            </Tab>
                            <Tab eventKey="direccionProduccion3" title="Dir. Producción 3">
                                <Form.Group controlId="direccion_produccion3">
                                    <Form.Label srOnly>Dirección de Producción 2:</Form.Label>
                                    <Form.Control
                                        name="direccion_produccion3"
                                        as="textarea"
                                        maxLength={1000}
                                        rows={2}
                                        ref={register({ maxLength: 1000 })}
                                        isInvalid={!!errors.direccion_produccion3}
                                        readOnly={readOnly}
                                    />
                                    <ErrorMessage error={errors.direccion_produccion3} />
                                </Form.Group>
                            </Tab>
                            <Tab eventKey="direccionProduccio4" title="Dir. Producción 4">
                                <Form.Group controlId="direccion_produccion4">
                                    <Form.Label srOnly>Dirección de Producción 4:</Form.Label>
                                    <Form.Control
                                        name="direccion_produccion4"
                                        as="textarea"
                                        maxLength={1000}
                                        rows={2}
                                        ref={register({ maxLength: 1000 })}
                                        isInvalid={!!errors.direccion_produccion4}
                                        readOnly={readOnly}
                                    />
                                    <ErrorMessage error={errors.direccion_produccion4} />
                                </Form.Group>
                            </Tab>

                        </Tabs>

                    </Tab>
                    <Tab eventKey="Usuarios" title="Usuarios Asociados">
                        {!readOnly && <>
                            <Button size='sm' onClick={onMostrarSelUsuario} ><HiUserAdd /> {" Agregar"}</Button>
                            <UsuarioSelector
                                activo={selUsuario}
                                cerrar={() => setSelUsuario(false)}
                                traerLista={traerListaUsuarios}
                                onSelect={onSelectUsuario}
                            />
                        </>}
                        <Table size="sm" striped bordered condensed responsive>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Nombre</th>
                                    <th>Siglas</th>
                                    <th>Correo</th>
                                    <th>Descripción</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    detUsers.fields.map((user, index) => {
                                        return <tr key={user.Id}>
                                            <td>
                                                <FaTrash
                                                    style={{ cursor: "pointer" }}
                                                    onClick={(e) => onEliminarUsuario(user, index)}
                                                />
                                                <input name={`users[${index}].user_id`} ref={register()} type="hidden" value={user.id} />
                                            </td>
                                            <td>{user.name}</td>
                                            <td> {user.code}</td>
                                            <td> {user.email}</td>
                                            <td> {user.info}</td>
                                        </tr>
                                    })
                                }
                                {detUsers.fields.length === 0 &&
                                    <tr>
                                        <td colSpan={5} style={{ padding: 15 }}>
                                            <strong>Presione el botón agregar para asociar usuarios.</strong>
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </Table>
                        <br />
                    </Tab>
                </Tabs>

                {mensaje && <Alert variant="info">{mensaje}</Alert>}
                {!cargando && <>
                    <hr />
                    <div className="float-right">
                        {!readOnly && (
                            <>
                                <Button variant="primary" type="submit">
                                    <FaSave /> Guardar
                                </Button>{" "}
                                <Button variant="secondary" onClick={() => cerrar()}>
                                    <FaUndo /> Cancelar
                                </Button>
                            </>
                        )}

                        {readOnly && (
                            <>
                                <Button variant="secondary" onClick={() => cerrar()}>
                                    <FaTimes /> Cancelar
                                </Button>
                            </>
                        )}
                    </div>
                </>}
            </Form>
        </>
    );
}
