import apiClient, { handleError } from "./api";
import BaseService from "./BaseService";

export default class ArchivoService extends BaseService {
  static getUrlBase = () => "/api/archivo";


  static async traerContenido(archivoId) {
    var resultado = null;
    await apiClient
      .get(this.getUrlBase() + `/traerContenido/${archivoId}`, { responseType: 'arraybuffer' })
      .then((response) => {

        if (response.status === 200)
          resultado = response.data; 
      })
      .catch(handleError);

    return resultado;
  }

}